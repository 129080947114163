import React from "react";
import VideoPlayer from "../../components/media/VideoPlayer/VideoPlayer";
import SkeletonView from "../../data-analysis/ui/skeleton/SkeletonView";
import {withTranslation} from "react-i18next";


const VideoPlayerWithTrackingOverlay = ({
    onTimeUpdate,
    mediaUrl,
    hasAllowSubjectMediaAudioPermission,
    onMediaLoadError,
    skeleton
                                                }) => {
    const enhancedVideoPlayerProps = {
        videoData: {
            url: mediaUrl,
        },
        options: {
            allowMuteChange: hasAllowSubjectMediaAudioPermission
        },
        onError: onMediaLoadError,
        expandedView: false,
    };
    return (
        <div style={parentStyle}>
            <div>
                <VideoPlayer style={videoPlayerStyle} {...enhancedVideoPlayerProps} onTimeUpdate={onTimeUpdate}/>
            </div>
            <div style={overlayContainerStyle}>
                <div style={overlayStyle}>
                    <SkeletonView width={600} heigh={800} skeleton={skeleton} scale={{x: 1, y: 1}} visible={true}
                                  lineColor="white" invertY={false}/>
                </div>
            </div>
        </div>
    );
}


const videoPlayerStyle = {
    position: 'relative',
    width: "100%",
    height: "100%",
    border: '1px solid #ccc', // Example border
};

const parentStyle = {
    position: 'relative',
    width: "100%",
    height: "800",
    border: '1px solid #ccc', // Example border
};

const overlayContainerStyle = {
    //backgroundColor: 'green',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none'
};

const overlayStyle = {
    //backgroundColor: 'green',
    //borderRadius: '5px',
    zIndex: 11,
    //width: '200',
    height: '800',
    pointerEvents: 'none'
};




export default withTranslation()(VideoPlayerWithTrackingOverlay);
