import RSVP from "rsvp";
import moment from "moment";
import RequestHelper from "../RequestHelper";
import InternationalisationService from "../InternationalisationService";
import StaffService from "../StaffService";
import LocalDataService from "./LocalDataService";
import store from "../redux/store";
import { resetState } from "../redux/notificationsSlice";
import NotificationService from "./NotificationService";
import TrialService from "../TrialService";
import SUBJECT_AUTHENTICATION_STRATEGY from "../constants/SUBJECT_AUTHENTICATION_STRATEGY";
import SubjectService from "../SubjectService";
import KeyboardHotkeyMappings from "../utility/KeyboardHotkeyMappings";
export default class AuthService {
  static AUTH_TOKEN = localStorage.getItem("AUTH_TOKEN");
  static REFRESH_TOKEN = localStorage.getItem("REFRESH_TOKEN");
  static TOKEN_EXPIRY = moment(localStorage.getItem("TOKEN_EXPIRY"));
  static ACCOUNT_TYPE = LocalDataService.getAccountType();
  // https://www.regular-expressions.info/email.html
  static EMAIL_REGEX =
    /[A-Za-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/;

  // General Auth Routes

  static getAuthToken() {
    return AuthService.AUTH_TOKEN;
  }

  static getTokenExpiry() {
    return AuthService.TOKEN_EXPIRY;
  }

  static getAccountType() {
    return AuthService.ACCOUNT_TYPE || "subject";
  }

  static isSubject() {
    return LocalDataService.getAccountType() === "subject";
  }
  static isStaff() {
    return LocalDataService.getAccountType() === "staff";
  }

  static setAccountType(accountType) {
    AuthService.ACCOUNT_TYPE = accountType;
    if (accountType) {
      LocalDataService.setAccountType(AuthService.ACCOUNT_TYPE);
    } else {
      LocalDataService.clearAccountType();
    }
  }

  static tokenSuccessCallback(token, resolve) {
    const expiryMoment = moment().add(token.expires_in, "seconds");
    AuthService.AUTH_TOKEN = token.access_token;
    AuthService.REFRESH_TOKEN = token.refresh_token;
    AuthService.TOKEN_EXPIRY = expiryMoment;
    localStorage.setItem("AUTH_TOKEN", AuthService.AUTH_TOKEN);
    localStorage.setItem("REFRESH_TOKEN", AuthService.REFRESH_TOKEN);
    localStorage.setItem("TOKEN_EXPIRY", AuthService.TOKEN_EXPIRY.toString());
    resolve();
  }

  static isLoggedIn() {
    return !!this.AUTH_TOKEN;
  }

  static async logout() {
    await AuthService.clearAuth();
    LocalDataService.setAccountType(null);
    KeyboardHotkeyMappings.unAssignKeys();
    window.location.href = window.location.origin;
  }

  static async logoutWithoutRedirect() {
    await AuthService.clearAuth();
    LocalDataService.setAccountType(null);
  }

  static async switchToSubjectAccount(switchAccountSubjectId) {
    //add the logged-in user id to the safe list (AccountSwitchValidator bean) for 5 seconds
    return RequestHelper.send(
        process.env.REACT_APP_SERVER_ADDRESS + `/subjects/switchAccount`,
        {},
        "POST",
        null,
        {}
    ).then(async () => {
      try {
        //try switching accounts
        let profile = await AuthService.getMyProfile();

        await AuthService.clearAuth();
        await AuthService.login(`id/${switchAccountSubjectId}/${profile.id}`, "", "switchToSubject");
        LocalDataService.setAccountType('subject');
        await SubjectService.autoSelectSubjectLanguage();
        const eventDefinitions = await SubjectService.getQuestionnaireDefinitions("EVENT");

        //reset the modal state so it shows
        LocalDataService.setHasSeenMandatoryTrainingModal(false);

        let showScreeningQuestionnaires = false;
        if (eventDefinitions) {
          const screeningQuestionnaires = SubjectService.getScreeningQuestionnaires(eventDefinitions, Window.configuration.ui.selfOnboarding);
          if (screeningQuestionnaires && screeningQuestionnaires.length > 0) {
            showScreeningQuestionnaires = await SubjectService.doesSubjectNeedToCompleteScreeningQuestionnaires();
          }
        }

        if (showScreeningQuestionnaires) {
          AuthService.redirectAfterLogin(showScreeningQuestionnaires);
        } else {
          //redirect to consent, if consent has passed this will show default page
          window.location.href = "/app/subject/study-consent";
        }
      } catch (e) {
        console.log('[AuthService]Error switching accounts, subject account password expired/locked out? Error:' + e);
        window.location.href = "/";
      }
    });
  }

  static async switchToStaffAccount(switchAccountStaffId) {
    //add the logged-in user id to the safe list (AccountSwitchValidator bean) for 5 seconds
    return RequestHelper.send(
        process.env.REACT_APP_SERVER_ADDRESS + `/subjects/switchAccount`,
        {},
        "POST",
        null,
        {}
    ).then(async () => {
      try {
        //try switching accounts
        let profile = await AuthService.getMyProfile();
        await AuthService.clearAuth();
        LocalDataService.setAccountType('staff');
        await AuthService.login(`id/${switchAccountStaffId}/${profile.Id}`, "", "switchToStaff");
        window.location.href = window.location.origin;
      } catch (e) {
        console.log('[AuthService]Error switching accounts, staff account password expired/locked out? Error:' + e);
        window.location.href = "/";
      }
    });
  }

  static async clearAuth() {
    try {
      NotificationService.disableBrowserNotifications(store.dispatch);
    } catch (error) {
      console.error("AuthService Error disabling notifications");
    }
    try {
      store.dispatch(resetState());
    } catch (error) {
      console.error("AuthService Error resetting notification state");
    }

    AuthService.AUTH_TOKEN = null;
    AuthService.REFRESH_TOKEN = null;
    localStorage.removeItem("AUTH_TOKEN");
    localStorage.removeItem("REFRESH_TOKEN");
  }

  // Account dependant Auth Routes

  static getRoutePrefix(accountType) {
    let prefix = "/staff";
    if (accountType === "subject") {
      prefix = "/selfonboarding/subjects";
    }
    return prefix;
  }

  static signup(subjectId, token, email, accountType) {
    const locale = InternationalisationService.getLanguage();
    const prefix = this.getRoutePrefix(accountType);
    const requestBody = { token, email, locale };
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS + `${prefix}/signup/` + subjectId,
      { "Accept-Language": locale },
      "POST",
      null,
      requestBody
    );
  }

  static login(username, password, accountType) {
    let prefix = "staff";
    if (accountType === "subject") {
      prefix = "subject";
    } else if (accountType === "switchToSubject") {
      prefix = "switchToSubject";
    } else if (accountType === "switchToStaff") {
      prefix = "switchToStaff";
    }

    const headers = {
      Authorization:
        process.env.REACT_APP_SERVER_BASIC_AUTHORIZATION_HEADER_VALUE,
      "Content-Type": "application/x-www-form-urlencoded",
    };

    // In order to use form data in the body, we need to make sure the automatic double-quotes
    // don't break the first key and last value, so we need to pad each end.
    const body =
      "source=atom5&" +
      "password=" +
      encodeURIComponent(password) +
      "&" +
      "grant_type=password&" +
      "client_id=" +
      process.env.REACT_APP_SERVER_CLIENT_ID +
      "&" +
      "username=" +
      prefix +
      "://" +
      encodeURIComponent(username) +
      "&" +
      "formdata=true";

    return new RSVP.Promise((resolve, reject) => {
      RequestHelper.send(
        process.env.REACT_APP_SERVER_ADDRESS + "/oauth/token",
        headers,
        "POST",
        null,
        body
      )
        .then((token) => AuthService.tokenSuccessCallback(token, resolve))
        .catch((response) => {
          if (response.error_description === "Account locked") {
            reject("account locked");
          } else if (response.error_description === "Bad credentials") {
            reject("bad credentials");
          } else if (response.error_description === "Password expired") {
            reject("password expired");
          } else {
            reject("unknown");
          }
        });
    });
  }

  static verifyActivationToken(token, accountType) {
    const prefix = this.getRoutePrefix(accountType);
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS +
        `${prefix}/activation/verify-activation-token`,
      {},
      "POST",
      undefined,
        { token: token }
    );
  }

  static activateAccount(token, password, accountType) {
    const prefix = this.getRoutePrefix(accountType);
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS + `${prefix}/activation`,
      {},
      "POST",
        undefined,
      {
        token: token,
        password: password,
      }
    );
  }

  static verifyForgotPasswordToken(token, accountType) {
    const prefix = this.getRoutePrefix(accountType);
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS +
        `${prefix}/reset-password/verify-token`,
      {},
      "POST",
        undefined,
      { token: token }
    );
  }

  static sendForgotPassword(email, accountType) {
    const prefix = this.getRoutePrefix(accountType);
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS + `${prefix}/reset-password/forgot`,
      { "Accept-Language": InternationalisationService.getLanguage() },
      "POST",
      {
        email: email,
      }
    );
  }

  static refreshAccessToken() {
    if (!AuthService.REFRESH_TOKEN) {
      return new RSVP.Promise((resolve, reject) => resolve());
    }
    const headers = {
      Authorization:
        process.env.REACT_APP_SERVER_BASIC_AUTHORIZATION_HEADER_VALUE,
      "Content-Type": "application/x-www-form-urlencoded",
    };

    const body =
        "grant_type=refresh_token&" +
        "client_id="+process.env.REACT_APP_SERVER_CLIENT_ID+"&" +
        "refresh_token="+AuthService.REFRESH_TOKEN+"&" +
        "formdata=true";
    const params = {
      refresh_flow: "true",
    };

    return new RSVP.Promise((resolve, reject) => {
      RequestHelper.send(
        process.env.REACT_APP_SERVER_ADDRESS + "/oauth/token",
        headers,
        "POST",
        params,
         body
      )
        .then((token) => {
          return AuthService.tokenSuccessCallback(token, resolve)
        })
        .catch((e) => {
          // race condition - check if any other request already refreshed token, and ignore if so
          if(AuthService.getTokenExpiry().isBefore(moment())){
            console.error("Error while fetching token", e)
            AuthService.clearAuth();
            // No point continuing with execution flow, need to go back to login page
            const currentPath = window.location.href
                .toString()
                .split(window.location.host)[1];
            const pathname = window.location.pathname || "";
            if (!pathname.startsWith("/login")) {
              window.location.href =
                  window.location.origin +
                  "/login?referrer=" +
                  encodeURI(currentPath);
            }
          }
        });
    });
  }

  static async getMyProfile() {
      const redirectToLogin = async ()=>{
        // No point continuing with execution flow, need to go back to login page
        console.log('redirecting to login...')
        const currentPath = window.location.href
            .toString()
            .split(window.location.host)[1];
        const pathname = window.location.pathname || "";
        console.log('login path:', pathname, currentPath)
        if (!pathname.startsWith("/login")) {
          window.location.href =
              window.location.origin +
              "/login?referrer=" +
              encodeURI(currentPath);
        }
      }
      if (AuthService.getAccountType() === "subject") {
        return await RequestHelper.send(
            process.env.REACT_APP_SERVER_ADDRESS + "/subject"
        ).catch(e=>{
          redirectToLogin();
        })
      } else {
        //already redirecting to login in the service
        return await StaffService.getMyProfile()
      }

  }

  static async getIsSuperAdmin() {
    if (AuthService.getAccountType() === "subject") {
      return false;
    } else {
      return (await StaffService.getMyProfile()).superAdmin;
    }
  }

  static updateMyProfile(email, firstName, lastName) {
    if (AuthService.getAccountType() === "subject") {
      return RequestHelper.send(
        process.env.REACT_APP_SERVER_ADDRESS + `/subject`,
        {},
        "PUT",
        { email }
      );
    } else {
      return StaffService.updateMyProfile(email, firstName, lastName);
    }
  }

  static changePassword(currentPassword, newPassword) {
    if (AuthService.getAccountType() === "subject") {
      return RequestHelper.send(
        process.env.REACT_APP_SERVER_ADDRESS + `/subject/password`,
        {},
        "PUT",
          undefined,
        { currentPassword:currentPassword, newPassword:newPassword }
      );
    } else {
      return StaffService.changePassword(currentPassword, newPassword);
    }
  }

  static async validateUsernameAsEmailAddress() {
    const subjectAuthenticationStrategy =
      await TrialService.getSubjectAuthenticationStrategy();
    return (
      AuthService.getAccountType() === "staff" ||
      (AuthService.getAccountType() === "subject" &&
        subjectAuthenticationStrategy ===
          SUBJECT_AUTHENTICATION_STRATEGY.EMAIL_PWD)
    );
  }

  static async redirectAfterLogin(showScreeningQuestionnaires) {
    if (showScreeningQuestionnaires) {
      window.location.href = "/app/subject/screening";
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const referrer = urlParams.get("referrer") || "";
      window.location.href = window.location.origin + referrer;
    }
  }
}
