import React, {useContext, useState, useEffect, useCallback} from "react";
import UnauthorisedMedia from "./UnauthorisedMedia";
import AttachmentDialog from "./AttachmentDialog";
import { Form } from "semantic-ui-react";
import AttachmentInlineWithAnalysisVtug from "./AttachmentInlineWithAnalysisVtug";
import AttachmentInline from "./AttachmentInline";
import AttachmentService from "../../services/AttachmentService";
import ConfigContext from "../../context/ConfigContext";
import AparitoSwitch from "../../components/questionnaire/AparitoSwitch";
import { withTranslation } from "react-i18next";
import GroupPermission from "../../GroupPermission";
import withContainer from "../question-renderers/hocs/withContainer";
import AttachmentInlineWithAnalysisVhot from "./AttachmentInlineWithAnalysisVhot";
import NullAnswerMedia from "./NullAnswerMedia";

const DisplayMediaQuestion = ({
  t,
  question,
  answer,
  subjectId = false,
  permissions = [],
  showLabel = true,
  containerType = null,
  printable = false,
  allowInlneDialogSwitching = true,
  iconSize,
}) => {
  const icon = AttachmentService.getIcon(question);
  const [permittedToViewAttachment, setPermittedToViewAttachment] = useState(false);
  const config = useContext(ConfigContext);

  const useMediaDialogByDefault =
    config.ui?.questionnaires?.useMediaDialogEverywhere === "true";

  const showDialogDefault =
    !printable && (containerType === "table" || useMediaDialogByDefault);

  const [showDialog, setShowDialog] = useState(showDialogDefault);

  const attachmentDialogStyle = allowInlneDialogSwitching
    ? { marginTop: 10 }
    : {};

  const fullAccessToSubjectMedia = permissions.includes(GroupPermission.VIEW_SUBJECT_MEDIA);
  const accessToBlurredSubjectMedia = permissions.includes(GroupPermission.VIEW_BLURRED_MEDIA);
  const blurredOnly = !fullAccessToSubjectMedia && accessToBlurredSubjectMedia;
  const hasAllowSubjectMediaAudioPermission = permissions.includes(GroupPermission.ALLOW_SUBJECT_MEDIA_AUDIO);

  const checkPermissionToView = useCallback(() => {
    AttachmentService.isPermittedToViewAttachment(subjectId, answer)
      .then((r) => {
        setPermittedToViewAttachment(true);
      }, (e) => {
        setPermittedToViewAttachment(false);
      }
    );
  }, [answer, subjectId]);

  const getAttachmentInlineComponent= () => {


    const trackingConfig = question.config.trackingConfig;

    if(trackingConfig?.analysis?.includes("vhot")){
      return (<AttachmentInlineWithAnalysisVhot
          question={question}
          reference={answer}
          subjectId={subjectId}
          printable={printable}
          blurredOnly={blurredOnly}
          hasAllowSubjectMediaAudioPermission={hasAllowSubjectMediaAudioPermission}
      />)
    }

    if(question.config.trackingConfig?.enabled || question.config.trackingConfig?.analysis?.includes("vtug")){
      return (<AttachmentInlineWithAnalysisVtug
          question={question}
          reference={answer}
          subjectId={subjectId}
          printable={printable}
          blurredOnly={blurredOnly}
          hasAllowSubjectMediaAudioPermission={hasAllowSubjectMediaAudioPermission}
      />)
    }

    return (<AttachmentInline
        question={question}
        reference={answer}
        subjectId={subjectId}
        printable={printable}
        blurredOnly={blurredOnly}
        hasAllowSubjectMediaAudioPermission={hasAllowSubjectMediaAudioPermission}
    />)
  }

  useEffect(() => {
    checkPermissionToView();
  }, [checkPermissionToView]);


  if ((permissions.includes(GroupPermission.VIEW_SUBJECT_MEDIA) || permissions.includes(GroupPermission.VIEW_BLURRED_MEDIA)) && permittedToViewAttachment) {
    return (
      <Form.Field onClick={e=>e.stopPropagation()}>
        {showLabel && <label>{question.label}</label>}
        {allowInlneDialogSwitching && useMediaDialogByDefault && !printable && (
          <AparitoSwitch
            checked={!showDialog}
            onChange={() => setShowDialog(!showDialog)}
            label={t("DISPLAY_MEDIA_SHOW_ON_PAGE")}
          />
        )}
        {answer != null && showDialog ? (
          <>
            <div style={attachmentDialogStyle} />
            <AttachmentDialog
              question={question}
              icon={icon}
              reference={answer}
              subjectId={subjectId}
              blurredOnly={blurredOnly}
              hasAllowSubjectMediaAudioPermission={hasAllowSubjectMediaAudioPermission}
              iconSize={iconSize}
            />
          </>
        ) : (answer != null && getAttachmentInlineComponent())}
      </Form.Field>
    );
  } else {
    if (answer != null){
      return <UnauthorisedMedia />;
    } else {
      return <NullAnswerMedia />;
    }

  }
};

export default withTranslation()(withContainer(DisplayMediaQuestion));
