import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import SubjectService from "../../SubjectService";
import ReadOnlyQuestionnaire from "../../questionnaires/ReadOnlyQuestionnaire";
import {
  Button,
  Divider,
  Form,
  Grid,
  Label,
  Message,
  Modal,
  Placeholder,
  Segment,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import GroupPermission from "../../GroupPermission";
import DateTimeService from "../../services/DateTimeService";
import ConfigService from "../../services/ConfigService";
import TrialService from "../../TrialService";
import SUBJECT_AUTHENTICATION_STRATEGY from "../../constants/SUBJECT_AUTHENTICATION_STRATEGY";
import ConfirmButton from "../../components/dashboard/ConfirmButton";
import PermissionsService from "../../services/PermissionsService";
import ConfirmButtonWithFeedback from "../../components/dashboard/ConfirmButtonWithFeedback";
import auditDisplayHelper from "../../helpers/auditDisplayHelper";
import ConfigContext from "../../context/ConfigContext";
import ConfirmButtonWithQuestionnaireFeedback from "../../components/dashboard/ConfirmButtonWithQuestionnaireFeedback";

class SubjectRecordTab extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      subjectData: {
        subjectCode: "",
        groups: [],
        subjectRecords: [],
        language: null,
      },
      loading: true,
      canManageSubjectGroups: false,
      canUpdateSubjectEmail: false,
      canUpdateSubjectLanguage: false,
      canUpdateSubjectCode: false,
      canUpdateSubjectRecords: false,
      canRandomiseSubject: false,
      canEditSubjectQuestionnaireAnswers: false,
      subjectAuthenticationStrategy:
        TrialService.getDefaultSubjectAuthenticationStrategy(),
      newPassword: null,
      randomiseError: false,
      emailActionMessage: ""
    };

    this.reload(false);
  }

  componentDidMount = () => {
    this.fetchTrialAuthenticationSettings();
  };

  fetchTrialAuthenticationSettings = async () => {
    const subjectAuthenticationStrategy =
      await TrialService.getSubjectAuthenticationStrategy();
    this.setState({
      subjectAuthenticationStrategy: subjectAuthenticationStrategy,
    });
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.match.params.subjectId !== this.props.match.params.subjectId ||
      prevProps.match.params.tabName !== this.props.match.params.tabName
    ) {
      this.reload();
    }
  };

  reload = async (pageInitialised = true) => {
    if (pageInitialised) {
      this.setState({ loading: true });
      this.props.loadingState(true);
    }

    try {
      const subjectData = await SubjectService.getSubjectData(
        this.props.match.params.subjectId
      );
      const canManageSubjectGroups =
        await PermissionsService.hasPermissionForSubject(
          subjectData.groups,
          GroupPermission.MANAGE_SUBJECT_GROUPS
        );
      const canUpdateSubjectActive =
        await PermissionsService.hasPermissionForSubject(
          subjectData.groups,
          GroupPermission.UPDATE_SUBJECT_ACTIVE
        );
      const canUpdateSubjectEmail =
        await PermissionsService.hasPermissionForSubject(
          subjectData.groups,
          GroupPermission.UPDATE_SUBJECT_EMAIL
        );
      const canUpdateSubjectLanguage =
        await PermissionsService.hasPermissionForSubject(
          subjectData.groups,
          GroupPermission.UPDATE_SUBJECT_LANGUAGE
        );
      const canUpdateSubjectCode =
        await PermissionsService.hasPermissionForSubject(
          subjectData.groups,
          GroupPermission.UPDATE_SUBJECT_CODE
        );
      const canUpdateSubjectRecords =
        await PermissionsService.hasPermissionForSubject(
          subjectData.groups,
          GroupPermission.UPDATE_SUBJECT_RECORDS
        );
      const canRandomiseSubject =
        await PermissionsService.hasPermissionForSubject(
          subjectData.groups,
          GroupPermission.RANDOMISE_SUBJECT
        );
      const canEditSubjectQuestionnaires = await PermissionsService.hasPermissionForSubject(
        subjectData.groups,
        GroupPermission.EDIT_SUBJECT_QUESTIONNAIRES
      );

      this.setState({
        subjectData,
        canManageSubjectGroups,
        canUpdateSubjectActive,
        canUpdateSubjectEmail,
        canUpdateSubjectLanguage,
        canUpdateSubjectCode,
        canUpdateSubjectRecords,
        canRandomiseSubject,
        canEditSubjectQuestionnaires,
        loading: false,
        emailActionMessage: ""
      });
      this.props.loadingState(false);
    } catch (e) {
      console.log(e);
    }
  };

  onPasswordResetConfirm = async () => {
    const response = await SubjectService.generateNewSubjectPassword(
      this.props.match.params.subjectId
    );
    this.setState({ newPassword: response.newPassword });
  };

  onPasswordResetDisplayClose = async () => {
    this.setState({ newPassword: null });
  };

  sendStaffInvokedActivationEmail = async () => {
    const { t } = this.props
    try {
      await SubjectService.sendActivationEmail(this.state.subjectData.id)
      this.setState({emailActionMessage: t("SUBJECT_VIEW_EDIT_DETAILS_EMAIL_SENT_MSG","Email Sent.")})
    }catch(error){
      this.setState({emailActionMessage: "Error!: " + t("SUBJECT_VIEW_EDIT_DETAILS_EMAIL_SENT_ERRMSG","Sorry, there was a problem sending the email.")})
    }
    //Clear the email sent message
    setTimeout(() => {
      this.setState({ emailActionMessage: "" })
    }, 2000)

  }

  onSubmit = (feedback) => {
    this.setState({ loading: true }, () => {
      if (this.state.subjectData.endDate) {
        SubjectService.updateSubjectActive(
          this.props.match.params.subjectId,
          true
        ).then(() => {
          this.reload();
        });
      } else {
        SubjectService.updateSubjectActive(
          this.props.match.params.subjectId,
          false,
          feedback
        ).then(() => {
          this.reload();
        });
      }
    });
  };

  render() {
    const { t } = this.props;

    let createdDate = "";
    if (this.state.subjectData.createdDate) {
      createdDate = DateTimeService.build.asDisplayDateTime(
        this.state.subjectData.createdDate
      );
    }

    let consentDate = "";
    if (this.state.subjectData.consentDate) {
      consentDate = DateTimeService.build.asDisplayDateTime(
        this.state.subjectData.consentDate
      );
    }

    let endDate = "";
    if (this.state.subjectData.endDate) {
      endDate = DateTimeService.build.asDisplayDateTime(
        this.state.subjectData.endDate
      );
    }

    let auditLastUpdated = "";
    if (this.state.subjectData.audit_lastupdated) {
      auditLastUpdated = DateTimeService.build.asDisplayDateTime(
        this.state.subjectData.audit_lastupdated
      );
    }

    let auditLastUpdatedBy = auditDisplayHelper.getAuditDisplayForSubjectData(
      this.state.subjectData,
      this.context
    );

    const languageText = (() => {
      const language = this.state.subjectData.language;
      if (!language) {
        return t("STAFF_FORM_LANGUAGE_NOT_SELECTED");
      }
      const languageKey = `SUBJECT_LANGUAGE_${language.code.toUpperCase()}`;
      const languageString = t(languageKey);
      if (languageString === languageKey) {
        return language.name;
      }
      return languageString;
    })();

    const questionnaires = this.state.subjectData.subjectRecords
      .filter(
        (questionnaire) =>
          (questionnaire.definition.config.tab &&
            questionnaire.definition.config.tab ===
              this.props.match.params.tabName) ||
          (!questionnaire.definition.config.tab &&
            this.props.match.params.tabName === "details")
      )
      .map((questionnaire) => {
        return (
          <Fragment key={questionnaire.id}>
            <ReadOnlyQuestionnaire
              definition={questionnaire.definition}
              questionnaire={questionnaire}
              subjectId={this.props.match.params.subjectId}
              isStaff={true}
            />
            <Divider />
            {this.state.canUpdateSubjectRecords && (!questionnaire.definition.config?.prohibitSubjectRecordEdit || !questionnaire.complete) && (
              <Grid>
                <Grid.Column floated="left" width={5}>
                    <Button
                      primary
                      basic
                      as={Link}
                      to={
                          "/app/subject/" +
                          this.props.match.params.subjectId +
                          "/edit/" +
                          this.props.match.params.tabName
                      }
                  >
                    {t("SUBJECT_VIEW_EDIT_DETAILS")}
                  </Button>

                </Grid.Column>
              </Grid>
            )}
          </Fragment>
        );
      });

    let StartStopButton = ConfirmButton;
    let color = "red";
    let actionKey = "STOP";
    let definitionCode = null;
    if (this.state.subjectData.endDate) {
      color = "green";
      actionKey = "START";
      // when questionnaire to start study questionniare supplied
      if (
        this.context.ui?.captureFeedbackOnSubjectChange &&
        this.context.audit.startSubjectDataQuestionnaire
      ) {
        StartStopButton = ConfirmButtonWithQuestionnaireFeedback;
        definitionCode = this.context.audit.startSubjectDataQuestionnaire;
      }
    } else {
      if (this.context.ui?.captureFeedbackOnSubjectChange) {
        // when questionnaire to stop study questionniare supplied
        if (this.context.audit.stopSubjectDataQuestionnaire) {
          StartStopButton = ConfirmButtonWithQuestionnaireFeedback;
          definitionCode = this.context.audit.stopSubjectDataQuestionnaire;
        } else {
          StartStopButton = ConfirmButtonWithFeedback;
        }
      }
    }

    const isDetailsTab = this.props.match.params.tabName === "details";

    const isRandomised = this.state.subjectData.randomisationNumber != null;

    const doRandomise = async () => {
      try {
        const newRandomisationNumber = await SubjectService.randomise(this.props.match.params.subjectId);
        this.setState({randomiseError: false, subjectData: {...this.state.subjectData, randomisationNumber: newRandomisationNumber}});
      } catch (e) {
        this.setState({randomiseError: true});
      }
    };

    const randomiseSection = (
      <>
        <h4>{t("SUBJECT_VIEW_RANDOMISE_HEADER")}</h4>
        {isRandomised ? (
          <p>{t("SUBJECT_VIEW_RANDOMISED_INFO")}</p>
        ) : (
          <>
            <p>{t("SUBJECT_VIEW_NOT_RANDOMISED_INFO")}</p>
            <Button
              primary
              basic
              onClick={doRandomise}
            >
              {t("SUBJECT_VIEW_RANDOMISE")}
            </Button>
            {this.state.randomiseError && (
              <Message error textAlign="left">
                <Message.Content>{t('SUBJECT_VIEW_RANDOMISE_ERROR')}</Message.Content>
              </Message>
            )}
          </>
        )}
        <Divider />
      </>
    );

    const startStopButton = (
      <StartStopButton
        buttonText={t("SUBJECT_" + actionKey + "_STUDY")}
        headerText={t("SUBJECT_" + actionKey + "_STUDY")}
        contentText={t("SUBJECT_" + actionKey + "_STUDY_DETAILED")}
        confirmButtonText={t("GLOBAL_BUTTON_CONFIRM")}
        cancelButtonText={t("GLOBAL_BUTTON_CANCEL")}
        onConfirm={this.onSubmit}
        placeholderText={t("SUBJECT_" + actionKey + "_STUDY_PLACEHOLDER_TEXT")}
        mandatoryValidationText={t(
          "SUBJECT_" + actionKey + "_STUDY_VALIDATION_TEXT"
        )}
        definitionCode={definitionCode}
        color={color}
      />
    );

    return this.state.loading ? (
      <Placeholder>
        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
      </Placeholder>
    ) : (
      <Fragment>
        {isDetailsTab && (
          <>
            {this.state.canRandomiseSubject && randomiseSection}

            <Form noValidate>
              <Form.Input
                label={t("SUBJECT_LIST_TABLE_HEADER_SUBJECT_CODE")}
                name="subjectCode"
                id="subjectCode"
                value={this.state.subjectData.subjectCode}
                disabled
              />
              {this.state.canUpdateSubjectCode && (
                <Button
                  primary
                  basic
                  className="field"
                  as={Link}
                  to={
                    "/app/subject/" +
                    this.props.match.params.subjectId +
                    "/subjectCode"
                  }
                >
                  {t("SUBJECT_VIEW_EDIT_SUBJECTCODE", "Edit Subject Code")}
                </Button>
              )} 

              <Form.Input
                label={t("SUBJECT_VIEW_CREATED_DATE")}
                name="createdDate"
                id="createdDate"
                value={createdDate}
                disabled
              />

              <Form.Input
                label={t("SUBJECT_VIEW_CONSENT_DATE")}
                name="consentDate"
                id="consentDate"
                value={consentDate}
                disabled
              />

              <Form.Input
                label={t("SUBJECT_VIEW_END_DATE")}
                name="endDate"
                id="endDate"
                value={endDate}
                disabled
              />

              {ConfigService.canStaffViewAndEditSubjectEmail() && (
                <>
                  <Form.Input
                    label={t("STAFF_FORM_EMAIL_LABEL")}
                    name="email"
                    id="email"
                    value={(this.state.subjectData.email ? this.state.subjectData.email : "")}
                    disabled
                  />
                  {this.state.canUpdateSubjectEmail && (
                        <Button
                            style={{ margin: "0em 0.25em 0em 0em" }}
                            primary
                            basic
                            className="field"
                            as={Link}
                            to={
                              "/app/subject/" +
                              this.props.match.params.subjectId +
                              "/email"
                            }
                        >
                          {t("SUBJECT_VIEW_EDIT_EMAIL")}
                        </Button>
                  )}
                  {this.state.canUpdateSubjectActive && (
                      <>
                        <Button
                            basic
                            color={!this.state.subjectData.email || this.state.subjectData.endDate ? "grey" : "orange"}
                            disabled={!this.state.subjectData.email || this.state.subjectData.endDate}
                            onClick={this.sendStaffInvokedActivationEmail}
                        >
                          {t("SUBJECT_VIEW_SEND_WELCOME_EMAIL","Send Welcome email")}
                        </Button>

                        <Message size='mini' hidden={!this.state.emailActionMessage}
                                 positive={!this.state.emailActionMessage.includes("Error")}
                                 negative={this.state.emailActionMessage.includes("Error")}
                                 compact>
                          {this.state.emailActionMessage}
                        </Message>
                      </>
                  )}
                </>
              )}

              {this.state.subjectAuthenticationStrategy ===
                SUBJECT_AUTHENTICATION_STRATEGY.SUBJECTCODE_PWD && (
                <Form.Field>
                  <label>{t("SUBJECT_FORM_PASSWORD_LABEL")}</label>
                  <ConfirmButton
                    buttonText={t("SUBJECT_FORM_RESET_PASSWORD_BUTTON")}
                    headerText={t("SUBJECT_FORM_RESET_PASSWORD_PROMPT_HEADER")}
                    contentText={t("SUBJECT_FORM_RESET_PASSWORD_PROMPT_TEXT")}
                    confirmButtonText={t(
                      "SUBJECT_FORM_RESET_PASSWORD_PROMPT_CONFIRM_TEXT"
                    )}
                    cancelButtonText={t(
                      "SUBJECT_FORM_RESET_PASSWORD_PROMPT_CANCEL_TEXT"
                    )}
                    onConfirm={this.onPasswordResetConfirm}
                  />
                  <Modal
                    open={this.state.newPassword !== null}
                    onClose={this.onPasswordResetDisplayClose}
                    closeIcon={false}
                    closeOnDimmerClick={false}
                    closeOnDocumentClick={false}
                  >
                    <Modal.Header>
                      {t("SUBJECT_FORM_RESET_PASSWORD_COMPLETE_HEADER")}
                    </Modal.Header>
                    <Modal.Content>
                      <p>{t("SUBJECT_FORM_RESET_PASSWORD_COMPLETE_TEXT")}</p>
                      <p style={{ fontSize: "2rem", letterSpacing: "5px" }}>
                        {this.state.newPassword}
                      </p>
                      <Button
                        type="button"
                        primary
                        onClick={this.onPasswordResetDisplayClose}
                      >
                        {t("SUBJECT_FORM_RESET_PASSWORD_COMPLETE_CLOSE")}
                      </Button>
                    </Modal.Content>
                  </Modal>
                </Form.Field>
              )}
              <Form.Input
                label={t("STAFF_FORM_LANGUAGE_LABEL")}
                name="language"
                id="language"
                value={languageText}
                disabled
              />
              {this.state.canUpdateSubjectLanguage && (
                <Button
                  primary
                  basic
                  className="field"
                  as={Link}
                  to={
                    "/app/subject/" +
                    this.props.match.params.subjectId +
                    "/language"
                  }
                >
                  {t("SUBJECT_VIEW_EDIT_LANGUAGE")}
                </Button>
              )}

              <Form.Field>
                <label>{t("SUBJECT_LIST_TABLE_HEADER_GROUPS")}</label>
                <Segment>
                  {this.state.subjectData.groups.map((group) => (
                    <Label key={group.id}>{group.label}</Label>
                  ))}
                </Segment>
              </Form.Field>

              {this.state.canManageSubjectGroups && (
                <Button
                  primary
                  basic
                  as={Link}
                  to={
                    "/app/subject/" +
                    this.props.match.params.subjectId +
                    "/groups"
                  }
                >
                  {t("SUBJECT_VIEW_EDIT_GROUPS")}
                </Button>
              )}
            </Form>

            <Divider />
          </>
        )}

        {questionnaires}

        {this.state.canEditSubjectQuestionnaires && (
          <Grid>
            <Grid.Column floated="left" width={5}>
              <Button
                primary
                basic
                className="field"
                as={Link}
                to={
                  "/app/subject/" +
                  this.props.match.params.subjectId +
                  "/questionnaireAnswerManagement/questionnaires"
                }
              >
                {t("SUBJECT_EDIT_QUESTIONNAIRES", "Edit patient questionnaires")}
              </Button>
            </Grid.Column>
          </Grid>
        )}

        {this.state.canUpdateSubjectActive && isDetailsTab && (
          <Grid>
            <Grid.Column floated="right" width={5} textAlign="right">
              {startStopButton}
            </Grid.Column>
          </Grid>
        )}

        {!this.state.canUpdateSubjectRecords && (
          <Message>{t("SUBJECT_VIEW_MISSING_EDIT_PERMISSION")}</Message>
        )}

        <Divider />

        <Form noValidate>
          <Form.Input
            label={t("AUDIT_LAST_UPDATED_DATE")}
            name="auditLastUpdated"
            id="auditLastUpdated"
            value={auditLastUpdated}
            disabled
          />
          <Form.Input
            label={t("AUDIT_LAST_UPDATED_BY")}
            name="auditLastUpdatedBy"
            id="auditLastUpdatedBy"
            value={auditLastUpdatedBy}
            disabled
          />
        </Form>
      </Fragment>
    );
  }
}

SubjectRecordTab.contextType = ConfigContext;

export default withTranslation()(SubjectRecordTab);
