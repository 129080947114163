import React from 'react';
import {Button, Grid} from "semantic-ui-react";
import {withTranslation} from "react-i18next";


const EntryDisplay = ({t, entry, handleRemove}) => {
  return <Grid.Row columns={12}>
        <Grid.Column width={4}>
          <p>{entry.frame}</p>
        </Grid.Column>
        <Grid.Column >
          <p>{entry.coords.x1}</p>
        </Grid.Column>
        <Grid.Column width={3} >
          <p>{entry.coords.y1}</p>
        </Grid.Column>
        <Grid.Column >
          <p>{entry.coords.x2}</p>
        </Grid.Column>
        <Grid.Column width={3} >
          <p>{entry.coords.y2}</p>
        </Grid.Column>
        <Grid.Column >
          <Button onClick={()=>handleRemove(entry.id)}>{t("REGION_SELECTOR_ENTRY_DELETE", "Delete")}</Button>
        </Grid.Column>
      </Grid.Row>
}

export default withTranslation()(EntryDisplay);
