import React, { useContext, useEffect, useMemo, useState } from 'react';
import Page from '../../../components/page/Page';
import { withTranslation } from 'react-i18next';
import { Button, Form, Grid, Input, Message, Modal, Segment, Table } from 'semantic-ui-react';
import VisitDateService from '../../../services/VisitDateService';
import { useParams } from 'react-router-dom';
import DateTimeService from '../../../services/DateTimeService';
import ConfirmButtonWithFeedback from '../../../components/dashboard/ConfirmButtonWithFeedback';
import DateTimeField from '../../../components/DateTimeField';
import moment from 'moment';
import AparitoSwitch from '../../../components/questionnaire/AparitoSwitch';
import SubjectVisitDateAttendance from './SubjectVisitDateAttendance';
import SubjectVisitDateQuestionnaires from './SubjectVisitDateQuestionnaires';
import SubjectInfoContext from '../../../context/SubjectInfoContext';
import PermissionsService from '../../../services/PermissionsService';
import GroupPermission from '../../../GroupPermission';

const SubjectVisitDates = (props) => {
  const { t, subjectData } = props;
  const { subjectId } = useParams();
  const currentVisitDate = useContext(SubjectInfoContext)?.subjectData.currentVisitDate;

  const [visitDates, setVisitDates] = useState();

  const [selectedObject, setSelectedObject] = useState();

  const [selectedObjectUpdateStatus, setSelectedObjectUpdateStatus] = useState();

  const [hasEditPermission, setHasEditPermission] = useState(false);

  const populatePermissions = async () => {
    setHasEditPermission(await PermissionsService.hasPermissionForSubject(
      subjectData?.groups,
      GroupPermission.MANAGE_SUBJECT_VISITDATES
    ));
  };

  const populateExistingDates = async () => {
    const items = await VisitDateService.getVisitDatesForSubject(subjectId);
    setVisitDates(items || []);
  };

  const refreshData = async () => {
    populatePermissions();
    populateExistingDates();
  };

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = () => {
    setSelectedObject();
    setSelectedObjectUpdateStatus();
  };

  const handleEditClick = (item) => {
    setSelectedObject({ ...item });
    setSelectedObjectUpdateStatus({
      originalStartDate: item.scheduledItem.startDate,
      originalEndDate: item.scheduledItem.endDate,
      startEndDateDaysDiff: moment(item.scheduledItem.endDate).diff(moment(item.scheduledItem.startDate), 'days'),

      updateSubsequentVisitsBySameAmount: true,
      startDateChangeDaysDiff: 0,
      hasDateChanged: false
    });
  };

  const handleEditCancelClick = () => {
    closeModal();
  };

  const handleSubjectVisitDateAttendanceChange = () => {
    refreshData();
  };

  const handleFormSubmit = async (feedbackReason) => {
    const payload = {
      ...selectedObject.scheduledItem,
      hasDateChanged: selectedObjectUpdateStatus?.hasDateChanged,
      updateSubsequentVisitsBySameAmount: !selectedObjectUpdateStatus?.hasDateChanged ? false : selectedObjectUpdateStatus.updateSubsequentVisitsBySameAmount
    };
    try {
      await VisitDateService.updateVisitDateForSubject(subjectId, feedbackReason, payload);
      await refreshData();
      closeModal();
    } catch (error) {
      console.error('[SubjectVisitDates][handleFormSubmit] Error', error);
    }
  };

  const handleInitialiseVisitDatesForSubject = async (feedbackReason) => {
    await VisitDateService.initialiseVisitDatesForSubject(feedbackReason, subjectId)
    populateExistingDates();
  }

  // FUTURE: Maybe use these buttons, unsure yet - buttons commented out below
  // const handlePopulateMissingVisitDatesForSubject = async (feedbackReason) => {
  //   await VisitDateService.populateMissingVisitDatesForSubject(feedbackReason, subjectId)
  //   populateExistingDates();
  // }

  // const handleRepopulateFutureVisitDatesForSubject = async (feedbackReason) => {
  //   await VisitDateService.repopulateFutureVisitDatesForSubject(feedbackReason, subjectId)
  //   populateExistingDates();
  // }

  const buildVisitDateTableDataRow = (item) => {
    const scheduledItem = item.scheduledItem;
    const questionnaires = item.questionnaires;

    const rowStyle = {};
    if (scheduledItem.current) {
      rowStyle.backgroundColor = '#d7ffd7';
    } else if (scheduledItem.past) {
      rowStyle.backgroundColor = '#f0f0f0';
    }

    return (
      <Table.Row key={scheduledItem.id} style={rowStyle}>
        <Table.Cell key={scheduledItem.id + '_attendedState'} style={{ verticalAlign: 'top' }}>
          <SubjectVisitDateAttendance subjectId={subjectId} subjectData={subjectData} scheduledItem={scheduledItem} onChange={handleSubjectVisitDateAttendanceChange} />
        </Table.Cell>
        <Table.Cell key={scheduledItem.id + '_startDate'} verticalAlign='top'>
          {DateTimeService.build.asDisplayDate(scheduledItem.startDate)}
        </Table.Cell>
        <Table.Cell key={scheduledItem.id + '_endDate'} verticalAlign='top'>
          {DateTimeService.build.asDisplayDate(scheduledItem.endDate)}
        </Table.Cell>
        <Table.Cell key={scheduledItem.id + '_name'} verticalAlign='top'>
          {scheduledItem.name}
        </Table.Cell>
        <Table.Cell key={scheduledItem.id + '_questionnaires'} verticalAlign='top'>
          <SubjectVisitDateQuestionnaires questionnaires={questionnaires} />
        </Table.Cell>
        <Table.Cell key={scheduledItem.id + '_control'} verticalAlign='top'>
          {hasEditPermission && (
            <Button onClick={() => handleEditClick(item)}>
              {t('GLOBAL_BUTTON_EDIT', 'Edit')}
            </Button>
          )}
        </Table.Cell>
      </Table.Row>
    );
  };

  const buildVisitDateTable = () => {
    return (
      <>
        <Table selectable columns={12} compact>
          <Table.Header>
            <Table.Row key='header'>
              <Table.HeaderCell width={1} key='header_attendedState'></Table.HeaderCell>
              <Table.HeaderCell width={2} key='header_startDate'>
                {t('SUBJECT_VISITDATES_TABLE_HEADER_STARTDATE', 'Start date')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2} key='header_endDate'>
                {t('SUBJECT_VISITDATES_TABLE_HEADER_ENDDATE', 'End date')}
              </Table.HeaderCell>
              <Table.HeaderCell width={3} key='header_name'>
                {t('SUBJECT_VISITDATES_TABLE_HEADER_NAME', 'Name')}
              </Table.HeaderCell>
              <Table.HeaderCell width={4} key='header_questionnaires'>
                {t('SUBJECT_VISITDATES_TABLE_HEADER_QUESTIONNAIRES', 'Questionnaires')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2} key='header_controls'></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            {visitDates?.map(item =>
              buildVisitDateTableDataRow(item)
            )}
          </Table.Body>
        </Table>
      </>
    );
  };

  const tableOutput = useMemo(buildVisitDateTable, [visitDates]);

  const renderEditModal = () => {
    const scheduledItem = selectedObject?.scheduledItem;
    return (
      <Modal
        open={selectedObject != null}
        closeIcon={false}
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
      >
        <Modal.Header>
          <h3>{t('SUBJECT_VISITDATES_EDIT_TITLE', 'Edit visit date')}</h3>
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field key='formField_name'>
              <label>
                {t(['SUBJECT_VISITDATES_EDIT_NAME', 'SUBJECT_VISITDATES_TABLE_HEADER_NAME'], 'Name')}
              </label>
              <Input
                disabled={true}
                value={scheduledItem?.name || ''}
              />
            </Form.Field>

            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column>
                  <Form.Field key='formField_originalStartDate'>
                    <label>
                      {t('SUBJECT_VISITDATES_EDIT_DATE_ORIGINAL_STARTDATE', 'Original start date')}
                    </label>
                    <Input
                      disabled={true}
                      value={DateTimeService.build.asDisplayDate(selectedObjectUpdateStatus?.originalStartDate)}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field key='formField_originalEndDate'>
                    <label>
                      {t('SUBJECT_VISITDATES_EDIT_DATE_ORIGINAL_ENDDATE', 'Original end date')}
                    </label>
                    <Input
                      disabled={true}
                      value={DateTimeService.build.asDisplayDate(selectedObjectUpdateStatus?.originalEndDate)}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form.Field key='formField_startDate'>
                    <label>
                      {t(['SUBJECT_VISITDATES_EDIT_STARTDATE', 'SUBJECT_VISITDATES_TABLE_HEADER_STARTDATE'], 'Change start date')}
                    </label>
                    <DateTimeField
                      value={moment(scheduledItem?.startDate)}
                      onChange={(newStartDateMoment) => {
                        if (newStartDateMoment.isValid()) {
                          const newStartDate = newStartDateMoment.toISOString();

                          const newEndDateMoment = moment(newStartDateMoment);
                          newEndDateMoment.add(selectedObjectUpdateStatus.startEndDateDaysDiff, 'days');
                          setSelectedObject({
                            ...selectedObject,
                            scheduledItem: {
                              ...scheduledItem,
                              startDate: newStartDate,
                              endDate: newEndDateMoment.toISOString()
                            }
                          });

                          const originalStartDateMoment = moment(selectedObjectUpdateStatus?.originalStartDate);
                          setSelectedObjectUpdateStatus({
                            ...selectedObjectUpdateStatus,
                            startDateChangeDaysDiff: newStartDateMoment.diff(originalStartDateMoment, 'days'),
                            hasDateChanged: !originalStartDateMoment.isSame(newStartDateMoment)
                          });
                        }
                      }}
                      mode={'date'}
                      readOnly={false}
                      nowOnly={false}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field key='formField_endDate'>
                    <label>
                      {t(['SUBJECT_VISITDATES_EDIT_ENDDATE', 'SUBJECT_VISITDATES_TABLE_HEADER_ENDDATE'], 'New end date')}
                    </label>
                    <Input
                      disabled={true}
                      value={DateTimeService.build.asDisplayDate(scheduledItem?.endDate)}
                    />
                    <div>
                      <small>
                        {t('SUBJECT_VISITDATES_EDIT_ENDDATE_NOTES', 'Calculated based on original duration between start date and end date.')}
                      </small>
                    </div>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            {selectedObjectUpdateStatus?.hasDateChanged && (
              <>
                <Form.Field key='formField_startDateChange'>
                  <label>
                    {t('SUBJECT_VISITDATES_EDIT_DATE_CHANGE', 'Update subsequent visits start dates by the same amount?')}
                  </label>
                  <Message warning visible>
                    {t('SUBJECT_VISITDATES_EDIT_DATE_CHANGE_NOTES', 'The new start date has changed by {{startDateChangeDaysDiff}} days. Do you want to offset subsequent visit dates for this participant by the same amount?', {
                      startDateChangeDaysDiff: selectedObjectUpdateStatus?.startDateChangeDaysDiff,
                    })}
                  </Message>
                  <AparitoSwitch
                    checked={selectedObjectUpdateStatus?.updateSubsequentVisitsBySameAmount}
                    onChange={() => {
                      setSelectedObjectUpdateStatus({
                        ...selectedObjectUpdateStatus,
                        updateSubsequentVisitsBySameAmount: !selectedObjectUpdateStatus?.updateSubsequentVisitsBySameAmount
                      });
                    }}
                    label={t(['SUBJECT_VISITDATES_EDIT_DATE_UPDATESUBSEQUENTVISITSBYSAMEAMOUNT', 'SUBJECT_VISITDATES_EDIT_DATE_CHANGE'], 'Update subsequent visits start dates by the same amount?')}
                  />
                </Form.Field>
              </>
            )}

            <ConfirmButtonWithFeedback
              buttonText={t(
                'GLOBAL_BUTTON_SAVE',
                'Save'
              )}
              headerText={t('SUBJECT_VISITDATES_EDIT_SAVE_TEXT', 'Enter a reason for this change')}
              confirmButtonText={t('GLOBAL_BUTTON_CONFIRM', 'Confirm')}
              cancelButtonText={t('GLOBAL_BUTTON_CANCEL', 'Cancel')}
              onConfirm={(feedbackReason) => handleFormSubmit(feedbackReason)}
              mandatoryValidationText={t(
                "SUBJECT_VISITDATES_EDIT_SAVE_REASON_VALIDATION_TEXT",
                "Please supply a reason for the change."
              )}
            />
            <Button
              onClick={handleEditCancelClick}
            >
              {t('GLOBAL_BUTTON_CLOSE', 'Close')}
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  };

  const hasVisitDates = Array.isArray(visitDates) ? visitDates.length > 0 : false

  return (
    <Page
      name='SUBJECT_VISITDATES_PAGE'
      header={t('SUBJECT_VISITDATES_TITLE', "Manage participant's visit dates")}
    >
      <Segment>
        {t("GENERIC_TERM_VISIT_DATE", "Visit date")}:{" "}{currentVisitDate?.name}
      </Segment>
      {tableOutput}
      {renderEditModal()}


      {!hasVisitDates && (
        <ConfirmButtonWithFeedback
          buttonText={t(
            'SUBJECT_VISITDATES_INITIALISE_BUTTON_TEXT',
            'Initialise Visit Dates'
          )}
          headerText={t('SUBJECT_VISITDATES_INITIALISE_SAVE_TEXT', 'Enter a reason for this change')}
          confirmButtonText={t('GLOBAL_BUTTON_CONFIRM', 'Confirm')}
          cancelButtonText={t('GLOBAL_BUTTON_CANCEL', 'Cancel')}
          onConfirm={handleInitialiseVisitDatesForSubject}
          mandatoryValidationText={t(
            "SUBJECT_VISITDATES_INITIALISE_SAVE_REASON_VALIDATION_TEXT",
            "Please supply a reason for the change."
          )}
        />
      )}
      {/* FUTURE: Maybe use these buttons, unsure yet - functions commented out above*/}
      {/* <ConfirmButtonWithFeedback
        buttonText={t(
          'SUBJECT_VISITDATES_POPULATEMISSING_BUTTON_TEXT',
          'Populate Missing Visit Dates'
        )}
        headerText={t('SUBJECT_VISITDATES_POPULATEMISSING_SAVE_TEXT', 'Enter a reason for this change')}
        confirmButtonText={t('GLOBAL_BUTTON_CONFIRM', 'Confirm')}
        cancelButtonText={t('GLOBAL_BUTTON_CANCEL', 'Cancel')}
        onConfirm={handlePopulateMissingVisitDatesForSubject}
        mandatoryValidationText={t(
            "SUBJECT_VISITDATES_POPULATEMISSING_SAVE_REASON_VALIDATION_TEXT",
            "Please supply a reason for the change."
          )}
      />
      <ConfirmButtonWithFeedback
        buttonText={t(
          'SUBJECT_VISITDATES_REPOPULATEFUTURE_BUTTON_TEXT',
          'Repopulate Future Visit Dates'
        )}
        headerText={t('SUBJECT_VISITDATES_REPOPULATEFUTURE_SAVE_TEXT', 'Enter a reason for this change')}
        confirmButtonText={t('GLOBAL_BUTTON_CONFIRM', 'Confirm')}
        cancelButtonText={t('GLOBAL_BUTTON_CANCEL', 'Cancel')}
        onConfirm={handleRepopulateFutureVisitDatesForSubject}
        mandatoryValidationText={t(
          "SUBJECT_VISITDATES_REPOPULATEFUTURE_SAVE_REASON_VALIDATION_TEXT",
          "Please supply a reason for the change."
        )}
      /> */}
    </Page>
  );
};

export default withTranslation()(SubjectVisitDates);
