import React, {Component} from "react";
import {
  Button,
  Icon,
  Image,
  Message,
  Modal,
  Popup,
  Progress,
} from "semantic-ui-react";
import DateTimeService from "../../services/DateTimeService";
import { withTranslation } from "react-i18next";
import AttachmentService, {
  MEDIA_STATE,
} from "../../services/AttachmentService";
import { QUESTION_TYPES } from "atom5-branching-questionnaire";
import AttachmentPreview from "../../components/attachments/AttachmentPreview";
import GeneralHelpers from "../../helpers/GeneralHelpers";
import VideoPlayer from "../../components/media/VideoPlayer/VideoPlayer";
import AuthService from "../../services/AuthService";
import AttachmentDownload from "./AttachmentDownload";
import AttachmentWorkflowStatusOverride from "./AttachmentWorkflowStatusOverride";

class AttachmentDialog extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      open: false,
      loaded: false,
      progress: 0,
      contentUrl: "",
      uploadDate: undefined,
      captureDate: undefined,
      mediaState: undefined,
      attachment: undefined,
      blobData: undefined,
      isSuperAdmin: false,
    };

    AttachmentService.getAttachment(
      this.props.subjectId,
      this.props.reference,
      this.props.blurredOnly
    ).then((response) => {
      this.setState({
        attachment: response,
        mediaState: response.type === 'ORIGINAL' && response.category === 'VIDEO' ?
              AttachmentService.getMediaStateFromString('RECEIVED') //If we have default ORIGINAL (VIDEO\IMAGE) attachment variant, assume processing of Optimised is still active so keep in received state.
            : AttachmentService.getMediaStateFromString(response.state),
        uploadDate: response.attachmentCreatedDate
          ? DateTimeService.build.asDisplayDateTime(
              response.attachmentCreatedDate
            )
          : undefined,
        captureDate: response.mediaCreatedDate
          ? DateTimeService.build.asDisplayDate(response.mediaCreatedDate)
          : undefined,
      });
    }).then(() => {
      AuthService.getIsSuperAdmin().then(value => this.setState({isSuperAdmin: value}));
    });
  }

  loadContent = () => {
    const self = this;

    AttachmentService.getAttachmentInline(
      this.props.subjectId,
      this.props.reference,
      this.props.blurredOnly,
      (progress) => {
        self.setState({ progress: progress.percentage });
      },
      (mediaDataUrl, blob) => {
        this.setState({
          contentUrl: mediaDataUrl,
          loaded: true,
          blobData: blob,
        });
      },
      (err) => {
        this.setState({
          error: true,
        });
      }
    );
  };

  open = (e) => {
    GeneralHelpers.stopEvent(e);
    if (!this.state.loaded) {
      this.setState({ open: true }, this.loadContent);
    } else if (this.state.error) {
      // If there was an error on loading initially, for instance if the video
      // has not been uploaded yet, it will allow the system to attempt to
      // load it again, without having to refresh the page.
      this.setState({
        error: false,
        open: false,
        loaded: false,
        progress: 0,
        contentUrl: "",
        uploadDate: undefined,
        captureDate: undefined,
      });
      this.loadContent();
      this.setState({ open: true });
    } else {
      this.setState({ open: true });
    }
  };

  close = (e) => {
    GeneralHelpers.stopEvent(e);
    this.setState({ open: false });
  };

  onLoadError = (e) => {
    this.setState({ error: true });
  };

  getPopupTriggerComponent = () => {
    if (
      this.state.mediaState !== undefined &&
      this.state.mediaState !== MEDIA_STATE.COMPLETED &&
      this.state.mediaState !== MEDIA_STATE.LEGACY
    ) {
      return (
        <Icon
          name={this.state.mediaState?.displayProperties?.icon}
          size={this.props.iconSize || 'big'}
          data-value={this.props.reference}
          data-question-answer-value={this.getDataQuestionAnswerValue()}
        />
      );
    }
    return (
      <Button
        onClick={this.open}
        icon
        data-value={this.props.reference}
        data-question-answer-value={this.getDataQuestionAnswerValue()}
      >
        <Icon name={this.props.icon} size={this.props.iconSize || 'big'} />
      </Button>
    );
  };

  getDataQuestionAnswerValue = () => {
    const { t } = this.props;
    if (
      this.state.mediaState !== undefined &&
      this.state.mediaState !== MEDIA_STATE.COMPLETED &&
      this.state.mediaState !== MEDIA_STATE.LEGACY
    ) {
      return t(
        this.state.mediaState?.translationKey,
        this.state.mediaState?.fallbackText
      );
    }
    if (
      this.state.mediaState === MEDIA_STATE.COMPLETED ||
      this.state.mediaState === MEDIA_STATE.LEGACY
    ) {
      return t("GENERIC_YES");
    }
    return "";
  };

  getPlayer = () => {
    const config = Window.configuration;
    const frameSkipSizes = config?.ui?.components?.videoPlayer?.skip?.availableStepSizes;
    const defaultSkipSize = config?.ui?.components?.videoPlayer?.skip?.defaultStepSize;
    const enhancedVideoPlayerProps = {
      question: this.props?.question,
      videoData: {
        url: this.state.contentUrl,
      },
      options: {
        allowMuteChange: this.props.hasAllowSubjectMediaAudioPermission
      },
      timeSkipOptions: {
        sizes: frameSkipSizes,
        defaultSkipSize: defaultSkipSize ? parseInt(defaultSkipSize):null
      },
      onError: this.onLoadError
    };
    return <VideoPlayer {...enhancedVideoPlayerProps} />;
  };

  getPlayerToggle = () => {
    if (this.state.isSuperAdmin !== true) {
      return null;
    }
    return (
      <div style={{ textAlign: 'left' }}>
        <Button
          onClick={() => this.setState({useEnhancedPlayer: !this.state.useEnhancedPlayer})}
          size='tiny'
          color='grey'
          content={this.state.useEnhancedPlayer ? 'Use Standard Player' : 'Use Experimental Player'}
        />
      </div>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <Popup trigger={this.getPopupTriggerComponent()}>
          <Popup.Content>
            <>
              {this.state.mediaState !== undefined &&
                this.state.mediaState !== MEDIA_STATE.COMPLETED &&
                this.state.mediaState !== MEDIA_STATE.LEGACY && (
                  // Have state, Not Completed, Not Legacy - show info
                  <>
                    {t(
                      this.state.mediaState?.translationKey,
                      this.state.mediaState?.fallbackText
                    )}
                  </>
                )}
              {(this.state.mediaState === MEDIA_STATE.COMPLETED ||
                this.state.mediaState === MEDIA_STATE.LEGACY) && (
                <>
                  {this.state.uploadDate && (
                    <p>
                      {t("ATTACHMENT_UPLOADED_ON") +
                        " " +
                        this.state.uploadDate}
                    </p>
                  )}
                  {this.state.captureDate && (
                    <p>
                      {t("ATTACHMENT_CAPTURED_ON") +
                        " " +
                        this.state.captureDate}
                    </p>
                  )}
                </>
              )}
            </>
          </Popup.Content>
        </Popup>

        <Modal
          size={"fullscreen"}
          centered={true}
          open={this.state.open}
          onClose={this.close}
          closeIcon={true}
          closeOnDimmerClick={false}
          closeOnDocumentClick={false}
        >
          <Modal.Content>
            {!this.state.loaded && (
              <Progress percent={this.state.progress} indicating />
            )}

            {this.state.loaded && !this.state.error && (
              <>
                {(this.props.question.type === QUESTION_TYPES.IMAGE ||
                  this.props.question.type === QUESTION_TYPES.SIGNATURE) && (
                  <Image size="massive" src={this.state.contentUrl} />
                )}
                {this.props.question.type === QUESTION_TYPES.VIDEO && (
                  <div
                    style={{
                      display: "inline-block",
                      backgroundColor: "#000000",
                    }}
                  >
                    {this.getPlayer()}
                  </div>
                )}
                {this.props.question.type === QUESTION_TYPES.FILE && (
                  <AttachmentPreview
                    question={this.props.question}
                    attachment={this.state.attachment}
                    url={this.state.contentUrl}
                    blob={this.state.blobData}
                    maxHeight={600}
                  />
                )}
              </>
            )}

            <AttachmentDownload 
              subjectId={this.props.subjectId}
              reference={this.props.reference}
              blurredOnly={this.props.blurredOnly}
              mediaState={this.state.mediaState}
            />

            <AttachmentWorkflowStatusOverride
              subjectId={this.props.subjectId}
              attachmentId={this.state.attachment?.attachmentId} // NOTE: this.state.attachment is actually the attachmentVariant - needs changing properly, but this is a quick change to a live system right now.
              reference={this.props.reference}
              workflowStatus={this.state.attachment?.workflowStatus}
            />

            {this.state.error && (
              <Message negative>{t("ERROR_LOADING_VIDEO")}</Message>
            )}
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

export default withTranslation()(AttachmentDialog);
