import React, { useEffect, useState, useCallback } from "react";
import { Button, Container, Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import moment from "moment";


const DateRangeControls = (props) => {
  const { t } = props;
  const start = moment.isMoment(props.start) ? props.start : moment(props.start);
  const end = moment.isMoment(props.end) ? props.end : moment(props.end);
  const [days, setDays] = useState({
    start: start,
    end: end,
    rangeInDays: end.diff(start, "days"),
  });

  const notifyParentOnLoad = props.notifyParentOnLoad !== undefined
    ? props.notifyParentOnLoad
    : true;

  const disableFormSubmit = props.disableFormSubmit || false;

  const notifyParent = useCallback(() => {
    props.onChange(
      days.start.format("YYYY-MM-DD"),
      days.end.format("YYYY-MM-DD")
    );
  }, [days.start, days.end, props])

  useEffect(() => {
    if (notifyParentOnLoad) {
      notifyParent();
    }
  }, [notifyParentOnLoad, notifyParent]);

  const onChange = async (event) => {
    if (!event.target.value) {
      // Don't update when field is cleared
      return;
    }
    const isStart = event.target.name === "start"
    const newValue = moment(event.target.value);
    if (isStart) {
      const isAfterEnd = newValue > days.end
      if (isAfterEnd) {
        await setDays((prevState) => ({
          ...prevState,
          start: prevState.end,
          end: newValue
        }))
      } else {
        await setDays((prevState) => ({
          ...prevState,
          start: newValue,
        }))
      }
    } else {
      const isBeforeEnd = newValue < days.start
      if (isBeforeEnd) {
        await setDays((prevState) => ({
          ...prevState,
          end: prevState.end,
          start: newValue
        }))
      } else {
        await setDays((prevState) => ({
          ...prevState,
          end: newValue,
        }))
      }
    }
    notifyParent();
  };

  const shiftDates = async (amount) => {
    await setDays((prevState) => ({
      ...prevState,
      start: prevState.start.add(amount, "days"),
      end: prevState.end.add(amount, "days"),
    }));
    notifyParent();
  };

  return (
    <Container textAlign="center">
      <Button
        icon
        primary
        size='tiny'
        onClick={(e) => {
          shiftDates(-days.rangeInDays);
          disableFormSubmit && e.preventDefault();
        }}
      >
        <Icon name="arrow left" />
      </Button>
      <input
        type="date"
        name="start"
        value={days.start.format("YYYY-MM-DD")}
        onChange={onChange}
      />
      &nbsp;{t("MEDICATION_DATE_RANGE_TO")}&nbsp;
      <input
        type="date"
        name="end"
        value={days.end.format("YYYY-MM-DD")}
        onChange={onChange}
      />
      &nbsp;
      <Button
        icon
        primary
        size='tiny'
        onClick={(e) => {
          shiftDates(days.rangeInDays);
          disableFormSubmit && e.preventDefault();
        }}
      >
        <Icon name="arrow right" />
      </Button>
    </Container>
  )
};

export default withTranslation()(DateRangeControls);