import React, { useState, useEffect, useContext } from "react";
import { withTranslation } from "react-i18next";
import {Button, Icon, Message, Table} from "semantic-ui-react";
import ExportService from "../../services/ExportService";
import Page from "../../components/page/Page";
import { saveAs } from 'file-saver'
import i18next from "i18next";
import ConfigContext from "../../context/ConfigContext";
import {DateTime} from "luxon";
import * as Formats from "luxon/src/impl/formats";
const ExportListPage = (props) => {
  const { t } = props;

  const [isLoading, setIsLoading] = useState([]);
  const [exports, setExports] = useState([]);
  const [status, setStatus] = useState();
  const [intervalRef, setIntervalRef] = useState();
  const config = useContext(ConfigContext);
  const {fullDownload = true, summaryDownload = false, subjectDownload = false, attachmentDownload = false} = (config.ui?.export || {})

  const refreshStatus = async ()=>{
    await ExportService
        .exportStatus('data').then(res=>setStatus(res))
        .catch(e=>console.log('Error fetching error status', e))
  }
  useEffect(() => {
    refreshStatus();
    reloadExports();

  }, []);

  useEffect(() => {
    if(intervalRef) clearInterval(intervalRef)
    const ref = setInterval(()=>{
      refreshStatus()
    }, 10000);
    setIntervalRef(ref)
    return () => clearInterval(ref);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  const reloadExports = async () => {
    setIsLoading(true);

    ExportService.listExports()
      .then((exports) => {
        setExports(exports);
        setIsLoading(false);
      });
  };

  const downloadFullExport = (exp) => {
    ExportService.downloadExport(exp.fullExportIdentifier)
      .then((blob) => {
        saveAs(blob, "atom5-export-" + exp.dateGenerated.slice(0, 10) + "-full.zip");
      });
  };

  const downloadExcelExport = (exp) => {
    ExportService.downloadExport(exp.summaryIdentifier)
      .then((blob) => {
        saveAs(blob, "atom5-export-" + exp.dateGenerated.slice(0, 10) + "-summary.xlsx");
      });
  };

  const downloadAttachmentReport = (exp) => {
    ExportService.downloadExport(exp.attachmentsIdentifier)
        .then((blob) => {
          saveAs(blob, "atom5-export-" + exp.dateGenerated.slice(0, 10) + "-attachment-report.csv");
        });
  };

  const downloadSubjectReport = (exp) => {
    ExportService.downloadExport(exp.subjectsIdentifier)
        .then((blob) => {
          saveAs(blob, "atom5-export-" + exp.dateGenerated.slice(0, 10) + "-subject-report.csv");
        });
  };


  const triggerExport = () => {
    ExportService.triggerFullExport().then(res=>refreshStatus());
    setStatus(undefined);

  };

  return (
    <Page
      name="EXPORT_LIST"
      header={t("EXPORT_LIST_HEADER")}
      subheader={t("EXPORT_LIST_SUBHEADER")}
      loading={isLoading}
    >
      <Button
          primary={status && status?.running === false}
          icon
          labelPosition="left"
          onClick={() => triggerExport()}
          disabled={!status || status?.running === true}
      >
          {t("MENU_TRIGGER_EXPORT", "Start Export")} <Icon name="download" />
        </Button>

      {!status &&
          <Message
              info
          >
            Checking export status on the server..
          </Message>
      }
      {status?.running===true &&
          <Message
              info
          >
            Export running, started at {DateTime.fromISO(status?.lastStarted).toLocaleString(Formats.DATETIME_SHORT)}
            {status?.message ? `, last checkpoint: '${status?.message}'` : null}
          </Message>
      }
      {status?.running===false && status?.lastFinished &&
          <Message
              info
          >
            Export last completed at {DateTime.fromISO(status?.lastFinished).toLocaleString(Formats.DATETIME_SHORT)}
          </Message>
      }
      <Table columns={2}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {t("EXPORT_LIST_TABLE_HEADER_DATE")}
            </Table.HeaderCell>
            <>{fullDownload && <Table.HeaderCell>
                {t("EXPORT_LIST_TABLE_HEADER_FULL_REPORT", "Full Report")}
              </Table.HeaderCell>}
            </>
            <>{summaryDownload && <Table.HeaderCell>
                {t("EXPORT_LIST_TABLE_HEADER_SUMMARY", "Excel Summary")}
              </Table.HeaderCell>}
            </>
            <>{attachmentDownload && <Table.HeaderCell>
                {t("EXPORT_LIST_TABLE_HEADER_ATTACHMENT", "Attachment Report")}
              </Table.HeaderCell>}
            </>
              <>{subjectDownload && <Table.HeaderCell>
                {t("EXPORT_LIST_TABLE_HEADER_SUBJECT", "Subjects")}
              </Table.HeaderCell>}
            </>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {exports?.map((exp, index) => (
            <Table.Row
              key={index}
            >
              <Table.Cell>
                {exp.dateGenerated.slice(0, 16).replace("T", " ")}
              </Table.Cell>
              <Table.Cell>
                {fullDownload && exp.fullExportIdentifier && <Button
                    primary
                    icon
                    labelPosition="left"
                    onClick={() => downloadFullExport(exp)}
                  >
                    {i18next.exists("EXPORT_LIST_DOWNLOAD_FULL") ? t("EXPORT_LIST_DOWNLOAD_FULL") : t("EXPORT_LIST_DOWNLOAD", "Full Export")} <Icon name="download" />
                  </Button>}
                {fullDownload && !exp.fullExportIdentifier && <div>NA</div>}
              </Table.Cell>

                {summaryDownload && (
                    <Table.Cell>
                      {exp.summaryIdentifier && <Button
                        primary
                        icon
                        labelPosition="left"
                        onClick={() => downloadExcelExport(exp)}
                      >
                        {t("EXPORT_LIST_DOWNLOAD_SUMMARY", "Excel")} <Icon name="download" />
                      </Button>}
                      {!exp.summaryIdentifier && <div>NA</div>}
                    </Table.Cell>
                )}


                {attachmentDownload && (
                    <Table.Cell>
                        {exp.attachmentsIdentifier && <Button
                          primary
                          icon
                          labelPosition="left"
                          onClick={() => downloadAttachmentReport(exp)}
                        >
                          {t("EXPORT_LIST_DOWNLOAD_ATTACHMENT", "Attachments")} <Icon name="download" />
                        </Button>}
                        {!exp.attachmentsIdentifier && <div>NA</div>}
                    </Table.Cell>
                )}


                {subjectDownload && (
                    <Table.Cell>
                      {exp.subjectsIdentifier && <Button
                        primary
                        icon
                        labelPosition="left"
                        onClick={() => downloadSubjectReport(exp)}
                      >
                        {t("EXPORT_LIST_DOWNLOAD_SUBJECT", "Subjects")} <Icon name="download" />
                      </Button>}
                      {!exp.subjectsIdentifier && <div>NA</div>}
                    </Table.Cell>
                )}

            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Page>
  );
};

export default withTranslation()(ExportListPage);
