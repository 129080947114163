import React, {useEffect, useState, useRef} from "react";
import { Message, Progress } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import AttachmentService, {
  MEDIA_STATE,
} from "../../services/AttachmentService";
import { QUESTION_TYPES, typeHelper } from "atom5-branching-questionnaire";
import AttachmentPreview from "../../components/attachments/AttachmentPreview";
import VideoPlayer from "../../components/media/VideoPlayer/VideoPlayer";
import AttachmentDownload from "./AttachmentDownload";
import { getSkeletonAnalysis, MediapipeSkeleton, ChannelList} from "atom5-data-analysis"
import {constructVTugBarGraphDataChartjs,extractAnnotations} from "atom5-data-analysis/ui/web"

import ChannelListScatterChart from "../../data-analysis/ui/graphs/ChannelListScatterChart";
import StackedColumnChart from "../../data-analysis/ui/graphs/StackedColumnChart";
import {VTUGTable} from "../../data-analysis/ui/VTUGTable";
import {SliderInput} from "./SliderInput";
import Switch from "react-switch";
import {EventsTable} from "../../data-analysis/ui/EventsTable";
import VideoPlayerWithTrackingOverlay from "./VideoPlayerWithTrackingOverlay";


const MEDIA_SIZE = {
  SMALL: {
    width: 500,
  },
  NORMAL: {
    width: 750,
  },
  LARGE: {
    width: 1000,
  },
};


const AttachmentInlineWithAnalysisVtug = ({ question, reference, subjectId, t, printable, blurredOnly , hasAllowSubjectMediaAudioPermission}) => {
  const trackingEnabled = typeHelper.parseBool(question.config?.trackingConfig?.enabled)

  const [maxTimeSeconds, setMaxTimeSeconds] = useState(0)
  const [annotations, setAnnotations] = useState([])
  const skeletonAnalysis= useRef(getSkeletonAnalysis("vtug"))


  const [currentTimeSeconds, setCurrentTimeSeconds] = useState(0); // Initial slider value
  const [vtugChartData, setVTugChartData] = useState({
    labels: [],
    datasets: [ ],
  })
  const [vtugResults, setVtugResults] = useState([]);
  const [skeleton, setSkeleton] = useState(new MediapipeSkeleton())
  const [analysisParameters, setAnalysisParameters] = useState(skeletonAnalysis.current.analysisParameters);
  const [events,setEvents] = useState([]);


  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [mediaState, setMediaState] = useState(null);
  const [attachment, setAttachment] = useState(null);
  const [blobData, setBlobData] = useState(undefined);

  const [updateChartsActive, setUpdateChartsActive] = useState(true);
  const [showEventsTable, setShowEventsTable] = useState(false);
  const [useMediapipe, setUseMediapipe] = useState(true);
  const [hasMediapipeSkeleton, setHasMediapipeSkeleton] = useState(false);
  const [hasMLKitSkeleton, setHasMLKitSkeleton] = useState(false);



  const loadContent = async (loadMediapipe) => {
    const attachmentResponse = await AttachmentService.getAttachment(
      subjectId,
      reference,
      blurredOnly
    );

    const attachmentVariants = await AttachmentService.getAttachmentVariants(subjectId,reference,blurredOnly);

    const skeletonAttachmentMediapipe = attachmentVariants.find(map => map["mediaType"]?.includes("skeleton.mediapipe") || false);
    const skeletonAttachmentMLKit = attachmentVariants.find(map => map["mediaType"]?.includes("skeleton.mlkit") || false);

    setHasMediapipeSkeleton(skeletonAttachmentMediapipe !== undefined);
    setHasMLKitSkeleton(skeletonAttachmentMLKit !== undefined);

    let skeletonAttachment = undefined;
    if(loadMediapipe && skeletonAttachmentMediapipe !== undefined){
      skeletonAttachment = skeletonAttachmentMediapipe;
    }else{
      skeletonAttachment = skeletonAttachmentMLKit;
    }


    const vtug_analysis = attachmentVariants.find(map => map["mediaType"]?.includes("vtug/json"));
    if(vtug_analysis && AttachmentService.getMediaStateFromString(vtug_analysis.state) === MEDIA_STATE.COMPLETED){
      AttachmentService.getAttachmentInline(
          subjectId,
          reference,
          blurredOnly,
          (progress) => {
            //console.log(progress.percentage);
          },
          (vtugDataUrl, blob) => {
            // VTUG analysis data contained in blob, currently nothing is done with the data.

          },
          (err) => {
            console.error('Error getAttachmentInline', err)
          },
          vtug_analysis.variantReference,
      )
    }


    if(skeletonAttachment && AttachmentService.getMediaStateFromString(skeletonAttachment.state) === MEDIA_STATE.COMPLETED){
        AttachmentService.getAttachmentInline(
            subjectId,
            reference,
            blurredOnly,
            (progress) => {
              //console.log(progress.percentage);
            },
            (skeletonDataUrl, blob) => {
              skeletonAnalysis.current.loadAttachment(skeletonDataUrl,displayResults)

            },
            (err) => {
              console.error('Error getAttachmentInline', err)
            },
            skeletonAttachment.variantReference,
        )
      }


    setAttachment(attachmentResponse);
    if (attachmentResponse && attachmentResponse.state) {
      const state = attachmentResponse.type === 'ORIGINAL' && attachmentResponse.category === 'VIDEO' ?
          AttachmentService.getMediaStateFromString('RECEIVED') : //If we have default ORIGINAL (VIDEO) attachment variant, assume processing of Optimised is still active so keep in received state.
          AttachmentService.getMediaStateFromString(attachmentResponse.state)
      setMediaState(state);
    }
  };


  useEffect(() => {
    loadContent(useMediapipe);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useMediapipe]);


  const [channelListTop] = useState(new ChannelList(20,100))
  const [channelListBottom ] = useState(new ChannelList(20,100))

  const updateCharts = () => {
    channelListTop.emitDataChangedEvent();
    channelListBottom.emitDataChangedEvent();

    setAnnotations(extractAnnotations(skeletonAnalysis.current.vTugs))
    setVTugChartData(constructVTugBarGraphDataChartjs(skeletonAnalysis.current.vTugs,0.001))

    setVtugResults(skeletonAnalysis.current.vTugs)
    setEvents(skeletonAnalysis.current.events)
    console.log(skeletonAnalysis.current.events)
  };



  const displayResults = () => {
    setAnalysisParameters(skeletonAnalysis.current.analysisParameters)
    setMaxTimeSeconds(skeletonAnalysis.current.sktChannel.getDuration() / 1000)
    updateCharts();

  };


  const filterActiveChanged =  (checked) => {

    const filterSettings = { activeFiltering:checked };
    const newParameters= {...analysisParameters,...filterSettings }
    setAnalysisParameters(newParameters);
    skeletonAnalysis.current.doAnalysis(newParameters);
    updateCharts();
  }


  const useMediapipeChanged = (checked) => {
    setUseMediapipe(checked);
    loadContent(checked);
  }

  useEffect(() => {
    channelListTop.addDataChannel(skeletonAnalysis.current.shouldersYChan,"red",2,"y");
    channelListTop.addDataChannel(skeletonAnalysis.current.shouldersYChangeChan, "brown", 1, "y1");

    channelListTop.addDataChannel(skeletonAnalysis.current.hipsChan,"green",2,"y");
    channelListTop.addDataChannel(skeletonAnalysis.current.visibilityChan,"blue",1,"y1");
    channelListTop.addDataChannel(skeletonAnalysis.current.turnStateChan,"black",2,"y1");


    channelListBottom.addDataChannel(skeletonAnalysis.current.anklesChan,"purple",2,"y");
    channelListBottom.addDataChannel(skeletonAnalysis.current.heightChan,"blue",2,"y");
    channelListBottom.addDataChannel(skeletonAnalysis.current.percentLegStretchChan,"green",2,"y");
    channelListBottom.addDataChannel(skeletonAnalysis.current.stepsChan,"black",2,"y1");
    channelListBottom.addDataChannel(skeletonAnalysis.current.sitStandValleyStateChan,"brown",2,"y");

  }, [channelListBottom, channelListTop]);

  const legStretchThresholdChanged = (value) => {
    const threshold_stretch = { threshold_stretch: value };
    const newParameters = { ...analysisParameters, ...threshold_stretch };
    setAnalysisParameters(newParameters);
    skeletonAnalysis.current.doAnalysis(newParameters);
    updateCharts();
  };

  const turnThresholdChanged = (value) => {
    let newValue = value;

    if( analysisParameters.threshold_turn === newValue)
      return;
    const threshold_turn = { threshold_turn: newValue};
    const newParameters = { ...analysisParameters, ...threshold_turn };
    setAnalysisParameters(newParameters);
    skeletonAnalysis.current.doAnalysis(newParameters);
    updateCharts();
  };


  useEffect(() => {
    if (
      mediaState !== MEDIA_STATE.COMPLETED &&
      mediaState !== MEDIA_STATE.LEGACY
    ) {
      return;
    }
    AttachmentService.getAttachmentInline(
      subjectId,
      reference,
      blurredOnly,
      (progress) => {
        setLoadingPercentage(progress.percentage);
      },
      (mediaDataUrl, blob) => {
        setMediaUrl(mediaDataUrl);
        setBlobData(blob);
        setIsLoaded(true);
      },
      (err) => {
        console.error('Error getAttachmentInline', err)
        setError(err);
        setIsLoaded(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaState]);

  const onMediaLoadError = (err) => {
    console.error('onMediaLoadError', err)

    setError(err);
  };

  const onTimeUpdate = (timeMS) => {
    setSkeleton(skeletonAnalysis.current.sktChannel.getDataTime(timeMS));
    setCurrentTimeSeconds(timeMS / 1000);
  }

  const getPlayer = () => {
    if(trackingEnabled){
      return (<VideoPlayerWithTrackingOverlay
      onTimeUpdate={onTimeUpdate}
      mediaUrl={mediaUrl}
      hasAllowSubjectMediaAudioPermission={hasAllowSubjectMediaAudioPermission}
      onMediaLoadError={onMediaLoadError}
      skeleton={skeleton}
      />);
    }else {
      const playerProps = {
        videoData: {
          url: mediaUrl,
        },
        options: {
          allowMuteChange: hasAllowSubjectMediaAudioPermission
        },
        onError: onMediaLoadError
      };
      return <VideoPlayer {...playerProps} />;
    }
  }

  return (
    <>
      {!isLoaded &&
        !error &&
        (mediaState === MEDIA_STATE.COMPLETED ||
          mediaState === MEDIA_STATE.LEGACY) && (
          <Progress percent={loadingPercentage} indicating />
        )}
      {error &&
        (mediaState === MEDIA_STATE.COMPLETED ||
          mediaState === MEDIA_STATE.LEGACY) && (
          // Error
          <Message negative>{t("ERROR_LOADING_VIDEO")}</Message>
        )}
      {mediaState !== null &&
        mediaState !== MEDIA_STATE.COMPLETED &&
        mediaState !== MEDIA_STATE.LEGACY && (
          // Have state, Not Completed, Not Legacy - show info
          <>
            <Message
              icon={mediaState?.displayProperties?.icon}
              info={mediaState?.displayProperties?.isInfo}
              content={t(mediaState?.translationKey, mediaState?.fallbackText)}
              style={{ width: "600px" }}
            />
          </>
        )}
      {(mediaState === MEDIA_STATE.COMPLETED ||
        mediaState === MEDIA_STATE.LEGACY) && (
          // Completed or Legacy - Display It
          <>
            {(question.type === QUESTION_TYPES.IMAGE ||
              question.type === QUESTION_TYPES.SIGNATURE) && (
                <>
                  {isLoaded && !error && (
                    <>
                      <img
                        width={MEDIA_SIZE.SMALL.width}
                        src={mediaUrl}
                        alt={reference}
                        data-value={reference}
                      />
                    </>
                  )}
                </>
              )}
            {question.type === QUESTION_TYPES.VIDEO && (
              <>
              <div style={containerStyle}>
                <>
                  <div style = {trackingEnabled?columnStretchStyle:{}} >

                  <div>
                  {isLoaded && getPlayer()}
                  </div>
                </div>
                </>

                {trackingEnabled && (
                <div style = {columnStretchStyle} >
                  {analysisParameters && (
                      <div>
                        <SliderInput defaultValue={analysisParameters.threshold_turn} name={"Threshold Turn"} max={1.0} step={0.01} onValueChange={turnThresholdChanged} />
                        <SliderInput defaultValue={analysisParameters.threshold_stretch} name={"Threshold Stretch"} max={1.0} step={0.01} onValueChange={legStretchThresholdChanged} />
                        <div style={{ display: 'flex'}}>
                          <div style={{ marginRight: '10px' }}>Update charts</div>
                          <Switch  checked={updateChartsActive} onChange={(checked) => setUpdateChartsActive(checked)}/>
                          <div style={{ marginRight: '10px', marginLeft: '10px'}}>{analysisParameters.activeFiltering ? 'Filter Active' : 'Filter Inactive'}</div>
                          <Switch  checked={analysisParameters.activeFiltering} onChange={filterActiveChanged}/>
                          <div style={{ marginRight: '10px', marginLeft: '10px'}}>Show Events Table</div>
                          <Switch  checked={showEventsTable} onChange={(checked) => setShowEventsTable(checked)}/>
                          {hasMediapipeSkeleton && hasMLKitSkeleton && (
                              <>
                                <div style={{ marginRight: '10px', marginLeft: '10px'}}>{useMediapipe ? 'Mediapipe' : 'MLKit'}</div>
                                <Switch checked={useMediapipe} onChange={useMediapipeChanged}/>
                              </>
                          )}
                        </div>

                      </div>
                  )}
                  <ChannelListScatterChart channelList = {channelListTop} selectedPosition = {updateChartsActive?currentTimeSeconds:0}  minX = {0} maxX = {Math.round(maxTimeSeconds)} minLeft = {-1}  maxLeft = {+1} minRight = {0}  maxRight = {100} />
                  <ChannelListScatterChart channelList = {channelListBottom} selectedPosition = {updateChartsActive?currentTimeSeconds:0}  minX = {0} maxX = {Math.round(maxTimeSeconds)} minLeft = {-1}  maxLeft = {+1} minRight = {0}  maxRight = {100}  />
                  <ChannelListScatterChart selectedPosition = {updateChartsActive?currentTimeSeconds:0}  minX = {0} maxX = {Math.round(maxTimeSeconds)} minLeft = {0}  maxLeft = {10} externalAnnotations = {annotations}/>

                </div> )}
               </div>
               {trackingEnabled && (
                   <div style = {graphContainer}>
                     { showEventsTable && (<EventsTable events = {events} />) }
                      <StackedColumnChart  title= {"vTUG timings"} data={vtugChartData} />
                     <VTUGTable vtugResults={vtugResults} />

                   </div>
               )}


               </>
            )}
            {question.type === QUESTION_TYPES.FILE && (
              <>
                {isLoaded && !error && (
                  <AttachmentPreview
                    question={question}
                    attachment={attachment}
                    url={mediaUrl}
                    blob={blobData}
                  />
                )}
              </>
            )}

            <AttachmentDownload
              subjectId={subjectId}
              reference={reference}
              blurredOnly={blurredOnly}
              mediaState={mediaState}
            />
          </>
        )}
    </>
  );
};



const graphContainer = {
  display: 'flex',
  height: '100vh',          // Optional: Set a height to center vertically in the viewport
  padding: '50px',
  justifyContent: 'center', // Horizontally center
};

const containerStyle = {
  display: 'flex',
};

const columnStretchStyle = {
  flex: 1,
  margin: '0 10px',
  border: '1px solid #ccc',
  padding: '10px',
};

export default withTranslation()(AttachmentInlineWithAnalysisVtug);
