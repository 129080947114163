import InternationalisationService from '../InternationalisationService';
import RequestHelper, { HTTP_METHOD } from '../RequestHelper';

const serviceBaseUrl = `${process.env.REACT_APP_SERVER_ADDRESS}/visitDate`;
const getSubjectBaseUrl = (subjectId) => `${serviceBaseUrl}/${subjectId}`;

const VisitDateService = {
  getCurrentVisitForSubject: async (subjectId) => {
    const url = `${getSubjectBaseUrl(subjectId)}/current`;
    const response = await RequestHelper.send(
      url,
      { 'Accept-Language': InternationalisationService.getLanguage() }
    );
    return response;
  },
  getCurrentVisitForSubjectWithRelatedData: async (subjectId) => {
    const url = `${getSubjectBaseUrl(subjectId)}/currentWithRelatedData`;
    const response = await RequestHelper.send(
      url,
      { 'Accept-Language': InternationalisationService.getLanguage() }
    );
    return response;
  },
  getVisitDatesForSubject: async (subjectId) => {
    const url = `${getSubjectBaseUrl(subjectId)}`;
    const response = await RequestHelper.send(
      url,
      { 'Accept-Language': InternationalisationService.getLanguage() }
    );
    return response;
  },
  updateVisitDateForSubject: async (subjectId, feedbackReason, payload) => {
    const url = `${getSubjectBaseUrl(subjectId)}/visitDate/${payload.id}`;
    const feedbackHeaderValue = feedbackReason
      ? `Updating visit date of participant ${subjectId}`
      : undefined;
    const { initialRequestBody, initialHeaders: headers } =
      RequestHelper.createInitialRequestObjectsWithFeedback(
        feedbackReason,
        feedbackHeaderValue
      );
    const requestBody = { ...initialRequestBody, ...payload };

    headers['Accept-Language'] = InternationalisationService.getLanguage();

    const response = await RequestHelper.send(
      url,
      headers,
      HTTP_METHOD.PUT,
      null,
      requestBody
    );
    return response;
  },
  updateVisitDateForQuestionnaire: async (subjectId, questionnaireId, feedbackReason, newVisitId) => {
    const url = `${getSubjectBaseUrl(subjectId)}/questionnaire/${questionnaireId}`;
    const feedbackHeaderValue = feedbackReason
      ? `Updating visit of questionnaire ${questionnaireId} to be ${newVisitId}`
      : undefined;
    const { initialRequestBody, initialHeaders: headers } =
      RequestHelper.createInitialRequestObjectsWithFeedback(
        feedbackReason,
        feedbackHeaderValue
      );
    const requestBody = { ...initialRequestBody };
    if (newVisitId != null) {
      requestBody.newVisitId = newVisitId;
    }

    headers['Accept-Language'] = InternationalisationService.getLanguage();

    const response = await RequestHelper.send(
      url,
      headers,
      HTTP_METHOD.PUT,
      null,
      requestBody
    );
    return response;
  },

  initialiseVisitDatesForSubject: async (feedbackReason, subjectId) => {
    const url = `${getSubjectBaseUrl(subjectId)}/initialiseVisitDatesForSubject`;
    const feedbackHeaderValue = feedbackReason
      ? `Initialise visit dates for subject ${subjectId}`
      : undefined;
    const { initialRequestBody, initialHeaders: headers } =
      RequestHelper.createInitialRequestObjectsWithFeedback(
        feedbackReason,
        feedbackHeaderValue
      );
    const requestBody = { ...initialRequestBody };
    headers['Accept-Language'] = InternationalisationService.getLanguage();

    const response = await RequestHelper.send(
      url,
      headers,
      HTTP_METHOD.POST,
      null,
      requestBody
    );
    return response;
  },
  populateMissingVisitDatesForSubject: async (feedbackReason, subjectId) => {
    const url = `${getSubjectBaseUrl(subjectId)}/populateMissingVisitDatesForSubject`;
    const feedbackHeaderValue = feedbackReason
      ? `Populate missing visit dates for subject ${subjectId}`
      : undefined;
    const { initialRequestBody, initialHeaders: headers } =
      RequestHelper.createInitialRequestObjectsWithFeedback(
        feedbackReason,
        feedbackHeaderValue
      );
    const requestBody = { ...initialRequestBody };
    headers['Accept-Language'] = InternationalisationService.getLanguage();

    const response = await RequestHelper.send(
      url,
      headers,
      HTTP_METHOD.POST,
      null,
      requestBody
    );
    return response;
  },
  repopulateFutureVisitDatesForSubject: async (feedbackReason, subjectId) => {
    const url = `${getSubjectBaseUrl(subjectId)}/repopulateFutureVisitDatesForSubject`;
    const feedbackHeaderValue = feedbackReason
      ? `Repopulate future visit dates for subject ${subjectId}`
      : undefined;
    const { initialRequestBody, initialHeaders: headers } =
      RequestHelper.createInitialRequestObjectsWithFeedback(
        feedbackReason,
        feedbackHeaderValue
      );
    const requestBody = { ...initialRequestBody };
    headers['Accept-Language'] = InternationalisationService.getLanguage();

    const response = await RequestHelper.send(
      url,
      headers,
      HTTP_METHOD.POST,
      null,
      requestBody
    );
    return response;
  },
  // ---------------------------------------------------------------------------------

  Configuration: {
    Enabled: {
      getIsEnabledForTrial: async () => {
        const url = `${serviceBaseUrl}/configuration/trial/isEnabled`;
        return VisitDateService.Configuration.Enabled._getIsEnabled(url);
      },
      getIsEnabledForSite: async (groupCode) => {
        const url = `${serviceBaseUrl}/configuration/site/${groupCode}/isEnabled`;
        return VisitDateService.Configuration.Enabled._getIsEnabled(url);
      },
      _getIsEnabled: async (url) => {
        const response = await RequestHelper.send(
          url,
          { 'Accept-Language': InternationalisationService.getLanguage() }
        );
        return response;
      },
      setIsEnabledForTrial: async (feedbackReason, values) => {
        const url = `${serviceBaseUrl}/configuration/trial/isEnabled`;
        const feedbackHeaderDesc = 'Updating Trial level Visit Dates Is Enabled';
        return VisitDateService.Configuration.Enabled._setIsEnabled(url, feedbackReason, feedbackHeaderDesc, values);
      },
      setIsEnabledForSite: async (groupCode, feedbackReason, values) => {
        const url = `${serviceBaseUrl}/configuration/site/${groupCode}/isEnabled`;
        const feedbackHeaderDesc = `Updating Site level Visit Dates Is Enabled for ${groupCode}`;
        return VisitDateService.Configuration.Enabled._setIsEnabled(url, feedbackReason, feedbackHeaderDesc, values);
      },
      _setIsEnabled: async (url, feedbackReason, feedbackHeaderDesc, value) => {
        const feedbackHeaderValue = feedbackReason ? feedbackHeaderDesc : undefined;
        const { initialRequestBody, initialHeaders: headers } =
          RequestHelper.createInitialRequestObjectsWithFeedback(
            feedbackReason,
            feedbackHeaderValue
          );
        const requestBody = { ...initialRequestBody, value: value };
        headers['Accept-Language'] = InternationalisationService.getLanguage();

        const response = await RequestHelper.send(
          url,
          headers,
          HTTP_METHOD.PUT,
          null,
          requestBody
        );
        return response;
      },
    },
    Dates: {
      getConfigurationForTrial: async () => {
        const url = `${serviceBaseUrl}/configuration/trial/dates`;
        return VisitDateService.Configuration.Dates._getConfiguration(url);
      },
      getConfigurationForSite: async (groupCode) => {
        const url = `${serviceBaseUrl}/configuration/site/${groupCode}/dates`;
        return VisitDateService.Configuration.Dates._getConfiguration(url);
      },
      _getConfiguration: async (url) => {
        const response = await RequestHelper.send(
          url,
          { 'Accept-Language': InternationalisationService.getLanguage() }
        );
        return response;
      },
      setConfigurationForTrial: async (feedbackReason, values) => {
        const url = `${serviceBaseUrl}/configuration/trial/dates`;
        const feedbackHeaderDesc = 'Updating Trial level Visit Dates Configuration';
        return VisitDateService.Configuration.Dates._setConfiguration(url, feedbackReason, feedbackHeaderDesc, values);
      },
      setConfigurationForSite: async (groupCode, feedbackReason, values) => {
        const url = `${serviceBaseUrl}/configuration/site/${groupCode}/dates`;
        const feedbackHeaderDesc = `Updating Site level Visit Dates Configuration for ${groupCode}`;
        return VisitDateService.Configuration.Dates._setConfiguration(url, feedbackReason, feedbackHeaderDesc, values);
      },
      _setConfiguration: async (url, feedbackReason, feedbackHeaderDesc, values) => {
        const feedbackHeaderValue = feedbackReason ? feedbackHeaderDesc : undefined;
        const { initialRequestBody, initialHeaders: headers } =
          RequestHelper.createInitialRequestObjectsWithFeedback(
            feedbackReason,
            feedbackHeaderValue
          );
        const requestBody = { ...initialRequestBody, value: values };
        headers['Accept-Language'] = InternationalisationService.getLanguage();

        const response = await RequestHelper.send(
          url,
          headers,
          HTTP_METHOD.PUT,
          null,
          requestBody
        );
        return response;
      },

      cloneDatesFromTrial: async (feedbackReason, groupCode) => {
        const url = `${serviceBaseUrl}/configuration/site/${groupCode}/cloneDatesFromTrial`;
        const feedbackHeaderValue = feedbackReason
          ? `Copy Visit Dates Configuration from Trial to site ${groupCode}`
          : undefined;

        const { initialRequestBody: requestBody, initialHeaders: headers } =
          RequestHelper.createInitialRequestObjectsWithFeedback(
            feedbackReason,
            feedbackHeaderValue
          );
        headers['Accept-Language'] = InternationalisationService.getLanguage();

        await RequestHelper.send(
          url,
          headers,
          HTTP_METHOD.PUT,
          null,
          requestBody
        );
      }
    }
  }
}

export default VisitDateService;
