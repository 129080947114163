import i18next from "i18next";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Container, Form, Message, Progress } from "semantic-ui-react";
import withError from "./hocs/withError";
import withLabel from "./hocs/withLabel";
import withContainer from "./hocs/withContainer";
import { useParams } from "react-router-dom";
import useUserMedia from "../../hooks/useUserMedia";
import { withTranslation } from "react-i18next";
import VideoPlayer from "../../components/media/VideoPlayer/VideoPlayer";
import UserContext from "../../context/UserContext";
import GroupPermission from "../../GroupPermission";
import PermissionsService from "../../services/PermissionsService";
import SubjectService from "../../SubjectService";
import AttachmentDownload from "../display/AttachmentDownload";
import ConfigContext from "../../context/ConfigContext";

const MonitoredVideo = ({ t, question, value, changeValue, changeAnswerMapValue, isReadOnly, isInSideView }) => {
  const user = useContext(UserContext);
  const [hasAllowSubjectMediaAudioPermission, setHasAllowSubjectMediaAudioPermission] = useState();
  const config = useContext(ConfigContext);

  const renderAs =
    question?.config?.renderAs !== undefined
      ? question?.config?.renderAs
      : "url";

  let subjectId = null;
  const params = useParams();
  if(user.accountType === "staff"){
    subjectId = params.subjectId
  } else if (user.accountType === "subject"){
    subjectId = user.profile.Id
  }

  const mediaObject = useUserMedia(
    renderAs === "value" ? value : null,
    subjectId
  );

  const mediaUrl = useMemo(() => mediaObject.userMediaUri ? mediaObject.userMediaUri : question.videoUrl, [mediaObject.userMediaUri, question.videoUrl]);

  useEffect(() => {
    populateHasAllowSubjectMediaAudioPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populateHasAllowSubjectMediaAudioPermission = async () => {
    if (user?.accountType === "subject") {
      setHasAllowSubjectMediaAudioPermission(true);
      return;
    }

    const subjectGroups = await SubjectService.getSubjectGroups(subjectId);
    const hasPerm = await PermissionsService.hasPermissionForSubject(subjectGroups, GroupPermission.ALLOW_SUBJECT_MEDIA_AUDIO);
    setHasAllowSubjectMediaAudioPermission(hasPerm);
  };

  const handlePlayingComplete = () => {
    changeValue(true);
  };

  if (question?.config?.showToSide && !isInSideView) {
    return null;
  }

  const getPlayer = () => {
    const frameSkipSizes = config?.ui?.components?.videoPlayer?.skip?.availableStepSizes;
    const defaultSkipSize = config?.ui?.components?.videoPlayer?.skip?.defaultStepSize;
    const enhancedVideoPlayerProps = {
      question,
      videoData: {
        url: mediaUrl,
        attachmentVariantRef: mediaObject.variantReference,
      },
      options: {
        isMonitoredVideo: true,
        allowMuteChange: hasAllowSubjectMediaAudioPermission
      },
      timeSkipOptions: {
        sizes: frameSkipSizes,
        defaultSkipSize: defaultSkipSize ? parseInt(defaultSkipSize):null
      },
      onPlayingComplete: handlePlayingComplete,
      changeAnswerMapValue,
    };
    return <VideoPlayer {...enhancedVideoPlayerProps} />;
  }

  const shouldRenderPlayer = (mediaObject.userMediaUri && renderAs === "value") || (question.videoUrl && renderAs === "url");

  return (
    <Form.Field>
      {!isReadOnly ? (
        <>
          <Container textAlign="center">
            {mediaObject.isLoading && (
              <Progress percent={mediaObject.downloadProgress} indicating />
            )}
            {mediaObject.isError && (
              <Message negative>{t(mediaObject.errorMsg) + ':' + mediaObject.errorCode}</Message>
            )}
            {shouldRenderPlayer && getPlayer()}

            <AttachmentDownload
              subjectId={subjectId}
              reference={value}
            />
          </Container>
        </>
      ) : (
        <Form.Input
          type={"text"}
          name={question.code}
          id={question.code}
          defaultValue={
            value === "true"
              ? i18next.t("MONITORED_VIDEO_WATCHED")
              : i18next.t("MONITORED_VIDEO_NOT_WATCHED")
          }
          disabled={true}
        />
      )}
    </Form.Field>
  );
};

export default withContainer(withLabel(withError(withTranslation()(MonitoredVideo))));
