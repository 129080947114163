import React, {useContext, useEffect, useMemo, useState} from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import GroupService from "../../services/GroupService";
import GroupPermission from "../../GroupPermission";
import { useLocation } from "react-router-dom";
import MenuItem from "./MenuItem";
import ConfigContext from "../../context/ConfigContext";
import TypeHelper from "../../helpers/TypeHelper";
import { Icon } from "semantic-ui-react";
import DashboardReportService from "../../services/DashboardReportService";

const StaffMenu = (props) => {
  const { user, groups, t } = props;
  const { profile } = user;

  const config = useContext(ConfigContext);
  const location = useLocation();

  let [isGroupsExpanded, setIsGroupsExpanded] = useState(true);
  const toggleIsGroupsExpanded = () => {
    setIsGroupsExpanded((oldValue) => !oldValue);
  };

  let [dashboardReportMenuList, setDashboardReportMenuList] = useState();
  const groupsWithDashboardReportPermission = useMemo(
      () =>
          GroupService.getGroupsWithPermissionFromProfile(
              profile,
              GroupPermission.VIEW_SITE_DASHBOARD_REPORTS
          ),
      [profile]
  );
  const canViewDashboardReports = profile.superAdmin || groupsWithDashboardReportPermission.length > 0;

  const groupsWithManageExportsPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        profile,
        GroupPermission.MANAGE_EXPORTS
      ),
    [profile]
  );
  const canManageExports =
    profile.superAdmin || groupsWithManageExportsPermission.length > 0;

  const groupsWithCreateStaffPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        profile,
        GroupPermission.CREATE_STAFF
      ),
    [profile]
  );
  const groupsWithViewStaffPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        profile,
        GroupPermission.VIEW_STAFF
      ),
    [profile]
  );
  const canViewStaff =
    profile.superAdmin ||
    (groupsWithCreateStaffPermission.length > 0) ||
    (groupsWithViewStaffPermission.length > 0);

  const groupsWithManageRewardsPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        profile,
        GroupPermission.MANAGE_REWARDS
      ),
    [profile]
  );
  const canManageRewards =
    profile.superAdmin || groupsWithManageRewardsPermission.length > 0;

  const groupsWithManageGroupsPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        profile,
        GroupPermission.MANAGE_GROUPS
      ),
    [profile]
  );
  const canManageGroups =
    profile.superAdmin || groupsWithManageGroupsPermission.length > 0;

  const canManageRoles = profile.superAdmin;

  const showHomeMenuItem = config?.ui?.showHomeMenuItem
    ? TypeHelper.parseBool(config?.ui?.showHomeMenuItem)
    : true;

  const groupsWithViewPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        profile,
        GroupPermission.VIEW_SUBJECT
      ),
    [profile]
  );

  const groupsWithViewDataQueryPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        profile,
        GroupPermission.VIEW_DATA_QUERIES
      ),
    [profile]
  );
  const canViewDataQueries =
    profile.superAdmin || groupsWithViewDataQueryPermission.length > 0;

  const groupsWithReceiveAlertsPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        profile,
        GroupPermission.VIEW_NOTIFICATIONS
      ),
    [profile]
  );
  const canViewNotifications =
    profile.superAdmin || groupsWithReceiveAlertsPermission.length > 0;

  const groupsWithManageScreenshotsPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        profile,
        GroupPermission.MANAGE_SCREENSHOTS
      ),
    [profile]
  );
  const canManageScreenshots =
    profile.superAdmin || groupsWithManageScreenshotsPermission.length > 0;

  // Batch Attachments / BATCH_ATTACHMENTS_VIEW
  const groupsWithBatchAttachmentsViewPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        profile,
        GroupPermission.BATCH_ATTACHMENTS_VIEW
      ),
    [profile]
  );
  const canViewBatchUploadAttachments = groupsWithBatchAttachmentsViewPermission.length > 0;
  
  // Visit Dates / MANAGE_TRIAL_VISITDATES
  const groupsWithManageTrialVisitDatesPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        profile,
        GroupPermission.MANAGE_TRIAL_VISITDATES
      ),
    [profile]
  );
  const canManageTrialVisitDates = groupsWithManageTrialVisitDatesPermission.length > 0;

  const viewableGroups = groups
    .filter((group) =>
      groupsWithViewPermission.some(
        (viewableGroup) => group.code === viewableGroup.code
      )
    )
    .sort((a, b) => a?.label?.localeCompare(b.label))
    .map((group) => (
      <MenuItem
        className="indent-1"
        active={location.pathname === "/app/subjects/" + group.code}
        to={"/app/subjects/" + group.code}
        key={group.code}
        text={group.label}
      />
    ));

  // Search
  const searchMenuComponents = [];

  const groupsWithSearchAttachmentsPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        profile,
        GroupPermission.SEARCH_ATTACHMENTS
      ),
    [profile]
  );
  const canSearchAttachments = groupsWithSearchAttachmentsPermission.length > 0;
  if (canSearchAttachments) {
    searchMenuComponents.push(
      <MenuItem
        className="indent-1"
        active={location.pathname === "/app/search/attachments"}
        to={"/app/search/attachments"}
        key={"/app/search/attachments"}
        icon={"file outline"}
        text={t("MENU_SEARCH_ATTACHMENTS", "Search Attachments")}
      />
    );
  }

  const groupsWithSearchAuditPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        profile,
        GroupPermission.SEARCH_AUDIT
      ),
    [profile]
  );
  const canSearchAudit = groupsWithSearchAuditPermission.length > 0;
  if (canSearchAudit) {
    searchMenuComponents.push(
      <MenuItem
        className="indent-1"
        active={location.pathname === "/app/search/audit"}
        to={"/app/search/audit"}
        key={"/app/search/audit"}
        icon={"database"}
        text={t("MENU_SEARCH_AUDIT", "Search Audit")}
      />
    );
  }


  useEffect(()=>{
    const getDashboardMenuItems = async () => {
      const dashboardReports = await DashboardReportService.getDashboardReportList();
      //add all reports without parent to the menu list
      const filtered = dashboardReports.reports.filter(d=>!d.parent);

      //add configured parents to menu list
      dashboardReports.reports.filter(d=>d.parent).map(d=>{
        if(filtered.filter(r=>r.code === d.parent.code).length === 0){
            filtered.push(d.parent);
        }
        return d;
      });
      setDashboardReportMenuList(filtered)
    }
    canViewDashboardReports && getDashboardMenuItems();
  }, [canViewDashboardReports])

  const dashboardReportCode = (dr) => {
    return dr.parent && dr.parent !== null ? dr.parent.code : dr.code
  }

  const dashboardReportCodeLabel = (dr) => {
    return dr.parent && dr.parent !== null ? dr.parent.label : dr.label
  }
  return (
    <>
      {showHomeMenuItem && (
        <MenuItem
          active={location.pathname === "/app/home"}
          to={"/app/home"}
          key={"/app/home"}
          icon={"desktop"}
          text={t("MENU_HOME")}
        />
      )}
      {canViewDashboardReports && dashboardReportMenuList?.length > 0 && (
          dashboardReportMenuList.map((dR)=>
            <MenuItem
                to={"/app/dashboard-report/" + dashboardReportCode(dR)}
                key={"/app/dashboard-report/" + dashboardReportCode(dR)}
                text={<strong>{t(dashboardReportCodeLabel(dR))}</strong>}
            />
          )
      )}
      {viewableGroups.length > 0 && (
        <>
          <div
            onClick={toggleIsGroupsExpanded}
            aria-label={t("MENU_SHOW_HIDE_SUBJECT_GROUPS")}
          >
            <div style={styles.iconRightStyle}>
              <Icon name={isGroupsExpanded ? "minus" : "plus"} />
            </div>
            <MenuItem
              to="#"
              key='menu_subjects_home'
              icon={"address book"}
              text={<strong>{t("MENU_SUBJECTS")}</strong>}
            />
          </div>
          <div
            style={isGroupsExpanded ? styles.groupsOpen : styles.groupsClosed}
          >
            {viewableGroups}
          </div>
        </>
      )}
      {canManageExports && (
        <>
          <MenuItem to="#" key='menu_export_home' text={<strong>{t("MENU_EXPORT")}</strong>} />

          <MenuItem
            className="indent-1"
            active={location.pathname === "/app/exports"}
            to={"/app/exports"}
            key={"/app/exports"}
            icon={"folder"}
            text={t("MENU_EXPORT_LIST", "Export List")}
          />

        </>
      )}

      {searchMenuComponents.length > 0 && (
        <>
          <MenuItem
            to="#"
            key='menu_search_home'
            text={<strong>{t("MENU_SEARCH", "Search")}</strong>}
          />
          {searchMenuComponents}
        </>
      )}

      {(canViewStaff || canManageRewards || profile.drugManager) && (
        <>
          <MenuItem to="#" key='menu_admin_home' text={<strong>{t("MENU_ADMINISTRATION")}</strong>} />
          {canViewStaff && (
            <MenuItem
              className="indent-1"
              active={location.pathname === "/app/staff"}
              to={"/app/staff"}
              tkey={"/app/staff"}
              icon={"user"}
              text={t("MENU_STAFF")}
            />
          )}
          {canManageRewards && (
            <MenuItem
              className="indent-1"
              active={location.pathname === "/app/rewards"}
              to={"/app/rewards"}
              key={"/app/rewards"}
              icon={"gift"}
              text={t("MENU_REWARDS")}
            />
          )}
          {canViewDataQueries && (
            <MenuItem
              className="indent-1"
              active={location.pathname === "/app/queries"}
              to={"/app/queries"}
              key={"/app/queries"}
              icon={"search"}
              text={t("MENU_DATA_QUERIES")}
            />
          )}
          {profile.drugManager && (
            <MenuItem
              className="indent-1"
              active={location.pathname === "/app/drugs"}
              to={"/app/drugs"}
              key={"/app/drugs"}
              icon={"syringe"}
              text={t("MENU_DRUG_MANAGEMENT")}
            />
          )}
          {canManageGroups && (
            <MenuItem
              className="indent-1"
              active={location.pathname === "/app/groups"}
              to={"/app/groups"}
              key={"/app/groups"}
              icon={"address book"}
              text={t("MENU_GROUP_MANAGEMENT")}
            />
          )}
          {canManageRoles && (
            <MenuItem
              className="indent-1"
              active={location.pathname === "/app/roles"}
              to={"/app/roles"}
              key={"/app/roles"}
              icon={"key"}
              text={t("MENU_ROLES_MANAGEMENT", "Manage Roles")}
            />
          )}
          {canViewNotifications && (
            <MenuItem
              className="indent-1"
              active={location.pathname === "/app/notifications"}
              to={"/app/notifications"}
              key={"/app/notifications"}
              icon={"bell"}
              text={t("MENU_VIEW_NOTIFICATIONS","Notifications")}
            />
          )}
          {canManageScreenshots && (
            <MenuItem
              className="indent-1"
              active={location.pathname === "/app/screenshots"}
              to={"/app/screenshots"}
              key={"/app/screenshots"}
              icon={"mobile alternate"}
              text={t("MENU_SCREENSHOT_MANAGEMENT","Manage Screenshots")}
            />
          )}
          {canViewBatchUploadAttachments && (
            <MenuItem
              className="indent-1"
              active={location.pathname === "/app/attachments/batchupload"}
              to={"/app/attachments/batchupload"}
              key={"/app/attachments/batchupload"}
              icon={"upload"}
              text={t("MENU_BATCHUPLOAD_ATTACHMENTS","Batch upload Attachments")}
            />
          )}
          {canManageTrialVisitDates && (
            <MenuItem
              className="indent-1"
              active={location.pathname === "/app/visitDates/manage/trial"}
              to={"/app/visitDates/manage/trial"}
              key={"/app/visitDates/manage/trial"}
              icon={"calendar"}
              text={t("MENU_TRIAL_VISITDATES_MANAGEMENT","Trial level visit dates")}
            />
          )}
        </>
      )}
    </>
  );
};

const styles = {
  iconRightStyle: {
    float: "right",
    marginTop: "14px",
    width: 30,
  },
  groupsOpen: {},
  groupsClosed: {
    height: 0,
    overflow: "hidden",
  },
};

const enhance = compose(withTranslation());

export default enhance(StaffMenu);
