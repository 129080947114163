import React, {useContext, useEffect, useMemo, useState, useCallback} from "react";
import { withTranslation } from "react-i18next";
import SubjectService from "../../SubjectService";
import {Button, Icon, Message, Placeholder, Segment} from "semantic-ui-react";
import { Link } from "react-router-dom";
import DateTimeService from "../../services/DateTimeService";
import DateRangeControls from "../../components/DateRangeControls";
import moment from "moment";
import ConfigContext from "../../context/ConfigContext";
import GroupPermission from "../../GroupPermission";
import { usePapaParse } from "react-papaparse";
import { saveAs } from "file-saver";
import DataFormatService from "../../services/DataFormatService";
import InternationalisationService from "../../InternationalisationService";
import SubjectDataTableCompletedDisplay from "./SubjectDataTableCompletedDisplay";
import SubjectDataTableActiveDisplay from "./SubjectDataTableActiveDisplay";
import SubjectDataTableMissedDisplay from "./SubjectDataTableMissedDisplay";
import buildQuestionnaireModuleFilter from "./utility/buildQuestionnaireModuleFilter";
import {getUiTabFromConfig} from "../../services/helpers/moduleHelper";
import VisitDateService from "../../services/VisitDateService";
import { typeHelper } from "atom5-branching-questionnaire";

const SubjectDataTable = ({t, subjectId, questionnaireDefinitions, questionnaireType, definitionCode, history, match, hideAnswers, componentStillLoading}) => {
  const config = useContext(ConfigContext);
  const definition = useMemo(()=>{
    return questionnaireDefinitions.find(
        (d) => d.code === definitionCode
    );
  }, [questionnaireDefinitions, definitionCode]);
  const doesDefinitionHaveStaffSignOff = definition?.config?.requiresStaffSignOff;

  // handle loading permissions and config
  const [hasLoaded, setHasLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [permissions, setPermissions] = useState(null);
  const getPermissions = useCallback(async () => {
    const permissions = await SubjectService.getSubjectPermission(subjectId);
    if (permissions) {
      // Must be before the call to setPermission
      try {
        const sd = await SubjectService.getSubjectData(subjectId);
        setSubjectData(sd);

        const hasViewSubjectVisitDatesPermission = permissions?.includes(GroupPermission.VIEW_SUBJECT_VISITDATES);
        setHasViewSubjectVisitDatesPermission(hasViewSubjectVisitDatesPermission);
        if (hasViewSubjectVisitDatesPermission) {
          setSubjectVisitDates(await VisitDateService.getVisitDatesForSubject(subjectId));
        }
        const group = SubjectService.getSubjectGroup(sd?.groups);
        setIsVisitDateEnabledForSubject(typeHelper.parseBool(config?.visitDates?.[group?.code]?.isEnabled));  
      } catch (error) {
        console.error('[SubjectDataTable][getPermissions] Getting Subject Data Error', error);
      }
    }
    setPermissions(permissions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectId])
  useEffect(()=>{
    if(hasLoaded) return;
    try {
      getPermissions()
    } catch (error) {
      console.error(error);
      setHasError(true);
    }
  }, [hasLoaded, getPermissions]);


  // handle loading questionnaires in bounds
  const defaultDayLimitConfig = config?.ui?.questionnaires?.defaultDayLimit || 30;
  const defaultStartDate = moment()
      .subtract(defaultDayLimitConfig, "days")
      .locale("en");
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(moment());
  const [completedQuestionnaires, setCompletedQuestionnaires] = useState(null);
  const [completedQuestionnairesWithSignOff, setCompletedQuestionnairesWithSignOff] = useState(null);
  const [activeQuestionnaires, setActiveQuestionnaires] = useState(null);
  const [missedQuestionnaires, setMissedQuestionnaires] = useState(null);
  const moduleCode = match?.params?.moduleCode;
  const uiTabConfig = getUiTabFromConfig(moduleCode);
  const [subjectData, setSubjectData] = useState();
  const [subjectVisitDates, setSubjectVisitDates] = useState();
  const [isVisitDateEnabledForSubject, setIsVisitDateEnabledForSubject] = useState();
  const [hasViewSubjectVisitDatesPermission, setHasViewSubjectVisitDatesPermission] = useState();
  
  const getQuestionnaires = useCallback(async (startDate, endDate) => {
    const requestParams = [subjectId, definitionCode, startDate, endDate];
    const [completed, active, missed] = await Promise.all([
      SubjectService.getSubjectQuestionnairesComplete(...requestParams),
      SubjectService.getSubjectQuestionnairesActive(...requestParams),
      SubjectService.getSubjectQuestionnairesMissed(...requestParams),
    ])

    if(moduleCode){
      const moduleQuestionnaireFilter = buildQuestionnaireModuleFilter(moduleCode);
      completed.result = completed.result.filter(moduleQuestionnaireFilter);
      active.result = active.result.filter(moduleQuestionnaireFilter);
      missed.result = missed.result.filter(moduleQuestionnaireFilter);
    }

    setActiveQuestionnaires(active?.result || []);
    setMissedQuestionnaires(missed?.result || []);

    if(doesDefinitionHaveStaffSignOff){
      const createSignOffFilter = (shouldRetailCompleted) => (questionnaire) => {
        const staffSignOffData = questionnaire[definitionCode+'_staff-sign-off-data']
        const hasData = staffSignOffData !== undefined && staffSignOffData !== null;
        if(shouldRetailCompleted){
          return hasData
        }
        return !hasData
      };
      setCompletedQuestionnairesWithSignOff(completed?.result?.filter(createSignOffFilter(false)) || []);
      setCompletedQuestionnaires(completed?.result?.filter(createSignOffFilter(true)) || []);
      return;
    }
    setCompletedQuestionnaires(completed?.result || []);
    setCompletedQuestionnairesWithSignOff([]);
  }, [subjectId, definitionCode, doesDefinitionHaveStaffSignOff, moduleCode])
  const onDateRangeChange = (start, end) => {
    setStartDate(moment(start));
    setEndDate(moment(end))
  }
  useEffect(()=>{
    try{
      getQuestionnaires(startDate, endDate);
    } catch (error) {
      console.error(error);
      setHasError(true);
    }
  },[hasLoaded, startDate, endDate, getQuestionnaires])


  // hasLoaded check and set
  useEffect(()=>{
    const areAllLoaded = [completedQuestionnaires,
      completedQuestionnairesWithSignOff,
      activeQuestionnaires,
      missedQuestionnaires,
      config,
      permissions
    ].every(v=> v!== null)

    setHasLoaded(areAllLoaded)
    if(componentStillLoading) componentStillLoading(false)
  },[completedQuestionnaires,
    completedQuestionnairesWithSignOff,
    activeQuestionnaires,
    missedQuestionnaires,
    config,
    permissions, componentStillLoading]);

  // handle route change
  const resetState = useCallback(() => {
    setHasLoaded(false);
    setHasError(false);
    setPermissions(null);
    setCompletedQuestionnaires(null);
    setCompletedQuestionnairesWithSignOff(null);
    setActiveQuestionnaires(null);
    setMissedQuestionnaires(null);
  }, []);
  useEffect(()=>{
    resetState();
    componentStillLoading(true)
  }, [subjectId,
    definition.code,
    questionnaireType,
    definitionCode,
    resetState, componentStillLoading
  ]);


  // Table export
  const { jsonToCSV } = usePapaParse();
  const showExportToCsvButton =
      definition?.config?.showExportToCsvButton
      || false;
  const downloadAsCsv = async () => {
    const rawTableData = DataFormatService.getTableData("subjectDataTable");
    const results = jsonToCSV(rawTableData);
    const csvBlobData = DataFormatService.buildCsvBlob(results);
    const filename = `${subjectData.subjectCode}-${
        definitionCode
    }-${DateTimeService.build.asDisplayDate(
        startDate
    )}-${DateTimeService.build.asDisplayDate(endDate)}.csv`;
    saveAs(csvBlobData, filename);
  };
  const downloadAsExcel = async () => {
    const csvBlobData = await SubjectService.getSubjectProsAsExcel(subjectId, definition.id, InternationalisationService.getLanguage())
    const subjectData = await SubjectService.getSubjectData(
        subjectId
    );
    const filename = `${subjectData.subjectCode}-${
        definitionCode
    }-${DateTimeService.build.asDisplayDate(
        startDate
    )}-${DateTimeService.build.asDisplayDate(endDate)}.xls`;
    saveAs(csvBlobData, filename);
  };

  // Graph
  const showToggleGraphButton =
      definition?.config?.showToggleGraphButton || false;
  const [isGraphOpen, setIsGraphOpen] = useState(false);

  const hasExportQuestionnairePdfPermission = 
      permissions?.includes(
        GroupPermission.EXPORT_QUESTIONNAIRE_PDF
      );

  // questionnaire creation
  const hasManualQuestionnaireTriggerPermission =
      permissions?.includes(
          GroupPermission.MANUAL_QUESTIONNAIRE_DELIVERY
      );
  const hasCreateSubjectQuestionnairesPermission =
      permissions?.includes(
          GroupPermission.CREATE_SUBJECT_QUESTIONNAIRES
      );
  // TODO: move to own function
  let defaultAddNew = "SUBJECT_TAB_ADD_NEW_PRO";
  let labels = questionnaireDefinitions.filter(
      (q) => q.code === definitionCode
  );
  let buttonLabel = "";
  //if we found a translated label the use that else determine default
  if (labels.length > 0 && labels[0] !== undefined) {
    buttonLabel = t("GLOBAL_BUTTON_ADD_NEW") + " " + labels[0].label;
  } else {
    if (match.params.moduleCode !== undefined) {
      defaultAddNew = "SUBJECT_TAB_ADD_NEW_MODULE";
    } else if (
        match.params.questionnaireType &&
        match.params.questionnaireType.toUpperCase() === "DATA"
    ) {
      defaultAddNew = "SUBJECT_TAB_ADD_NEW_DATA";
    } else if (
        match.params.questionnaireType &&
        match.params.questionnaireType.toUpperCase() === "EVENT"
    ) {
      defaultAddNew = "SUBJECT_TAB_ADD_NEW_EVENT";
    }

    buttonLabel = t([defaultAddNew, "GLOBAL_BUTTON_ADD_NEW"]);
  }

  if (hasError) return <Message>{t("GLOBAL_GENERIC_ERROR_MESSAGE")}</Message>

  if(!hasLoaded) return (
      <Segment>
        <Placeholder fluid>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      </Segment>
  )

  if(!definition) return  <div>
    {t("DEFINITION_NOT_FOUND", "Question definition not found")}
  </div>

  return <>
    <DateRangeControls
        onChange={onDateRangeChange}
        start={startDate}
        end={endDate}
        notifyParentOnLoad={false}
        align="center"
    />
    {completedQuestionnairesWithSignOff.length > 0 &&
    <Segment style={{overflow: "auto"}}>
      <SubjectDataTableCompletedDisplay
          t={t}
          definition={definition}
          questionnaireType={questionnaireType}
          definitionCode={definitionCode}
          questionnaires={completedQuestionnairesWithSignOff}
          permissions={permissions}
          subjectId={subjectId}
          subjectData={subjectData}
          isVisitDateEnabledForSubject={isVisitDateEnabledForSubject}
          hasViewSubjectVisitDatesPermission={hasViewSubjectVisitDatesPermission}
          subjectVisitDates={subjectVisitDates}
          hideAnswers={hideAnswers}
          config={config}
          history={history}
          questionnairesRequireSignoff
          uiTabConfig={uiTabConfig}
      />
    </Segment>}
    <Segment style={{overflow: "auto"}}>
      <SubjectDataTableCompletedDisplay
          t={t}
          definition={definition}
          questionnaireType={questionnaireType}
          definitionCode={definitionCode}
          questionnaires={completedQuestionnaires}
          permissions={permissions}
          subjectId={subjectId}
          subjectData={subjectData}
          isVisitDateEnabledForSubject={isVisitDateEnabledForSubject}
          hasViewSubjectVisitDatesPermission={hasViewSubjectVisitDatesPermission}
          subjectVisitDates={subjectVisitDates}
          hideAnswers={hideAnswers}
          config={config}
          history={history}
          isGraphOpen={isGraphOpen}
          uiTabConfig={uiTabConfig}
      />
      {showExportToCsvButton && completedQuestionnaires?.length > 0 && (
          <>
            <Button
                primary
                icon
                labelPosition="left"
                onClick={downloadAsCsv}
            >
              {t("EXPORT_TABLE", "Export Table as CSV")}
              <Icon name="table"/>
            </Button>
            <Button
                primary
                icon
                labelPosition="left"
                onClick={downloadAsExcel}
            >
              {t("EXPORT_TABLE_XLS", "Export Table as Excel")}
              <Icon name="table"/>
            </Button>
          </>
      )}
      {showToggleGraphButton && completedQuestionnaires.length > 0 && (
          <Button primary icon labelPosition="left" onClick={()=>setIsGraphOpen(!isGraphOpen)}>
            {t("TOGGLE_GRAPH")} <Icon name="chart bar" />
          </Button>
      )}
    </Segment>
    {questionnaireType === "pro" &&
    questionnaireDefinitions.length > 0 &&
    hasManualQuestionnaireTriggerPermission && (
        <>
        <Button
            primary
            icon
            as={Link}
            labelPosition="left"
            to={
              "/app/subject/" +
              subjectId +
              "/questionnaire-type/" +
              questionnaireType +
              "/" +
              definitionCode +
              "/create"
            }
        >
          {buttonLabel} <Icon name="plus" />
        </Button>
        </>
    )}
    {questionnaireType === "data" &&
    questionnaireDefinitions.length > 0 &&
    hasCreateSubjectQuestionnairesPermission && (
        <Button
            primary
            icon
            as={Link}
            labelPosition="left"
            to={
              "/app/subject/" +
              subjectId +
              "/data/" +
              definitionCode
            }
        >
          {buttonLabel} <Icon name="plus" />
        </Button>
    )}
    {(questionnaireType === "pro" || questionnaireType === "data" || questionnaireType === "event" || questionnaireType === "content") &&
    questionnaireDefinitions.length > 0 &&
    hasExportQuestionnairePdfPermission && (
      <Button
          primary
          icon
          as={Link}
          labelPosition="left"
          to={
            "/app/subject/" +
            subjectId +
            "/questionnaire-type/" +
            questionnaireType +
            "/" +
            definitionCode +
            "/pdfview"
          }
      >
        {t("VIEW_TO_EXPORT_PDF", "View for Export")} <Icon name="download" />
      </Button>
    )}
    {activeQuestionnaires.length > 0 && <Segment>
      <SubjectDataTableActiveDisplay
          t={t}
          definition={definition}
          questionnaireType={questionnaireType}
          definitionCode={definitionCode}
          questionnaires={activeQuestionnaires}
          permissions={permissions}
          subjectId={subjectId}
          subjectData={subjectData}
          isVisitDateEnabledForSubject={isVisitDateEnabledForSubject}
          hasViewSubjectVisitDatesPermission={hasViewSubjectVisitDatesPermission}
          subjectVisitDates={subjectVisitDates}
          config={config}
          refreshQuestionnaires={() => getQuestionnaires(startDate, endDate)} // alternatively switch to loading true
      /> </Segment>
    }
    {missedQuestionnaires.length > 0 && <Segment>
      <SubjectDataTableMissedDisplay
          t={t}
          definition={definition}
          questionnaireType={questionnaireType}
          definitionCode={definitionCode}
          questionnaires={missedQuestionnaires}
          permissions={permissions}
          subjectId={subjectId}
          subjectData={subjectData}
          isVisitDateEnabledForSubject={isVisitDateEnabledForSubject}
          hasViewSubjectVisitDatesPermission={hasViewSubjectVisitDatesPermission}
          subjectVisitDates={subjectVisitDates}
          config={config}
          refreshQuestionnaires={()=>getQuestionnaires(startDate, endDate)} // alternatively switch to loading true
      /> </Segment>
    }
  </>


};

export default withTranslation()(SubjectDataTable);