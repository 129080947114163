import {Button, Dropdown, Form, Grid, GridColumn, GridRow, Icon, Label, Table} from "semantic-ui-react";
import {ACTION_TYPES} from "../../../hooks/useCompleteQuestionnaireDefinitionInformation";
import React, {useEffect, useState} from "react";
import WorkflowService from "../../../services/admin/WorkflowService";
import {useTranslation} from "react-i18next";

function QuestionWorkflowBehaviorEditorComponent ({
      questionDefinition,
      isReadOnly = false,
      dispatch,
      workflowDefinitionId
}){
    const { t } = useTranslation();

    const [workflowBehaviour, setWorkflowBehaviour] = useState({task:"",taskCode:"",behaviour:""});
    const [workflowOptions, setWorkflowOptions] = useState([]);
    const [behaviourTypes, setBehaviourTypes] = useState([]);
    const [behaviourError, setBehaviourError] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect( () => {
        const loadData = () => {
            const fetchAllWorkflowTasks = async () => {
                setLoading(true);
                const workflowTasks = workflowDefinitionId ? await WorkflowService.fetchWorkflowDefinitionTasks(workflowDefinitionId):[];
                setWorkflowOptions(workflowTasks
                    .map( wft => {
                        return {
                            key: wft.code,
                            text: wft.code,
                            value: wft.id,
                        };
                    }).sort((a, b) => a.key.localeCompare(b.key)));

                const behaviours = await WorkflowService.fetchWorkflowBehaviorTypes();
                setBehaviourTypes(behaviours
                    .map( behavior => {
                        return {
                            key: behavior,
                            text: behavior,
                            value: behavior,
                        };
                    }).sort((a, b) => a.key.localeCompare(b.key)));

                setLoading(false);
            }
            fetchAllWorkflowTasks().catch(error => {
                setLoading(false);
                console.log("Error loading workflow behaviour data " + error);
            });
        };
        loadData();
    },[workflowDefinitionId])

    const addWorkflowBehavior = ()=> {
        function checkForBehaviourDuplicates(listToCheck) {
            const taskNamesMap = new Set();
            return listToCheck.some(item => {
                const taskName = item.behaviour.split('://')[0];
                return taskNamesMap.has(taskName) || !taskNamesMap.add(taskName);
            });
        }
        //Check we have values
        if (!workflowBehaviour.taskCode || !workflowBehaviour.behaviour) {
            setBehaviourError(true);
            setTimeout(()=>{setBehaviourError(false);},1000);
            return
        }

        const newWorkflowBehaviour = {behaviour: workflowBehaviour.taskCode + '://' + workflowBehaviour.behaviour};
        //Add our new behaviour to existing list or as a new list
        const wfBehaviours = questionDefinition.workflowBehaviour
            ? [...questionDefinition.workflowBehaviour, newWorkflowBehaviour]
            : [newWorkflowBehaviour];
        //If not a duplicate wf_task add to our state object
        if (!checkForBehaviourDuplicates(wfBehaviours)) {
            updateBehavior(wfBehaviours);
            setBehaviourError(false);
        } else {
            setBehaviourError(true);
            setTimeout(()=>{setBehaviourError(false);},1000);
        }
    }
    const updateBehavior = (behaviours) => {
        dispatch({
            type: ACTION_TYPES.EDIT_QUESTION_DEFINITION,
            payload: {...questionDefinition, workflowBehaviour:behaviours},
        });
    }

    const getBehaviourTableRows = (questionDefinition?.workflowBehaviour || []).map( b => {
        return (
            <Table.Row key={b.behaviour}>
                <Table.Cell>
                    <Label>{b.behaviour}</Label>
                </Table.Cell>
                <Table.Cell>
                    <Icon style={{cursor: "pointer"}} color="orange" onClick={(e) => {
                        e.stopPropagation();
                        const newBehaviorList = questionDefinition.workflowBehaviour.filter(item => item.behaviour !== b.behaviour);
                        updateBehavior(newBehaviorList);
                        console.log(questionDefinition.workflowBehaviour)
                    }} name='delete' />
                </Table.Cell>
            </Table.Row>
        )
    })

    return (
        <Grid columns={3}>
            <GridRow>
                <Grid.Column width={6}>
                    <Form.Field >
                        <label>
                            {t("QUESTIONNAIRE_DEFINITION_WF_TASK", "Workflow Task")}
                        </label>
                        <Dropdown
                            selection
                            fluid
                            error={behaviourError}
                            loading={loading}
                            options={workflowOptions}
                            value={workflowBehaviour.task}
                            name={"workflowTask"}
                            onChange={(_e, v) => {
                                const taskOption = v.options.find((opt)=> opt.value === v.value);
                                setWorkflowBehaviour( previousState => {
                                    return {
                                        task: v.value,
                                        taskCode: taskOption?.text,
                                        behaviour: previousState.behaviour
                                    };
                                })
                            } }
                            disabled={isReadOnly}
                        />
                    </Form.Field>
                </Grid.Column>
                <Grid.Column width={6}>
                    <Form.Field>
                        <label>
                            {t("QUESTIONNAIRE_DEFINITION_WF_BEHAVIOUR", "Behavior")}
                        </label>
                        <Dropdown
                            fluid
                            selection
                            error={behaviourError}
                            loading={loading}
                            options={behaviourTypes}
                            value={workflowBehaviour.behaviour}
                            name={"behaviour"}
                            onChange={(_e, v) => {
                                setWorkflowBehaviour( previousState => {
                                    return {
                                        task: previousState.task,
                                        taskCode: previousState.taskCode,
                                        behaviour: v.value
                                    };
                                })
                            }}
                            disabled={isReadOnly}
                        />
                    </Form.Field>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Form.Field>
                        <label>
                            {t("QUESTIONNAIRE_DEFINITION_WF_ACTION", "Action")}
                        </label>
                        <Button primary onClick={addWorkflowBehavior}>
                            {t("QUESTIONNAIRE_DEFINITION_WF_BEHAVIOUR_ADD", "Add")}
                        </Button>
                    </Form.Field>
                </Grid.Column>
            </GridRow>
            <GridRow>
                <GridColumn width={16}>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{t("QUESTIONNAIRE_DEFINITION_WF_BEHAVIOURS", "Workflow behaviours")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("QUESTIONNAIRE_DEFINITION_WF_BEHAVIOURS_REMOVE", "Remove")}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {getBehaviourTableRows}
                        </Table.Body>
                    </Table>

                </GridColumn>
            </GridRow>
        </Grid>
    )
}

export default QuestionWorkflowBehaviorEditorComponent;