import RequestHelper, { HTTP_METHOD } from "../RequestHelper"
import GeneralHelpers from "../helpers/GeneralHelpers";

export const SCHEDULED_ITEM_TYPE = {
  VIDEOCALL: 'VIDEOCALL',
  VISITDATE: 'VISITDATE'
}

export const ATTENDED_STATE_NOT_SET = 'NOT_SET';
export const ATTENDED_STATE = [ATTENDED_STATE_NOT_SET, 'ATTENDED', 'DID_NOT_ATTEND'];

export default class ScheduledItemService {
  constructor(
    type
  ) {
    this.type = type;

    if (!Object.keys(SCHEDULED_ITEM_TYPE).includes(type)) {
      console.error(`[ScheduledItemService] Invalid type '${type}'`);
      return;
    }
  }

  getServiceBaseUrl() {
    let relativeUrl;
    switch (this.type) {
      case SCHEDULED_ITEM_TYPE.VIDEOCALL:
        relativeUrl = `/${this.type.toLowerCase()}`;
        break;
      case SCHEDULED_ITEM_TYPE.VISITDATE:
        relativeUrl = `/visitDate`;
        break;
      default:
        console.error(`[ScheduledItemService][getServiceBaseUrl] Invalid type '${this.type}'`);
        return undefined;
    }
    return `${process.env.REACT_APP_SERVER_ADDRESS}${relativeUrl}`;
  }

  async listScheduledItems(subjectId) {
    return RequestHelper.send(
      `${this.getServiceBaseUrl()}/${subjectId}`,
      {},
      HTTP_METHOD.GET);
  }

  async deleteScheduledItem(subjectId, itemId) {
    return RequestHelper.send(
      `${this.getServiceBaseUrl()}/${subjectId}/${itemId}`,
      {},
      HTTP_METHOD.DELETE
    );
  }

  async updateAttendedState(subjectId, itemId, attendedState, feedbackReason) {
    const feedbackHeaderValue = feedbackReason
      ? `Updating attended state for subject ${subjectId}`
      : undefined;
    const { initialRequestBody, initialHeaders: headers } =
      RequestHelper.createInitialRequestObjectsWithFeedback(
        feedbackReason,
        feedbackHeaderValue
      );
    const requestBody = { ...initialRequestBody };

    return RequestHelper.send(
      `${this.getServiceBaseUrl()}/${subjectId}/attendedState/${itemId}/${attendedState}`,
      headers,
      HTTP_METHOD.PUT,
      null,
      requestBody
    );
  }

  async createScheduledItem(subjectId, item) {
    item.type = this.type;
    return RequestHelper.send(
      `${this.getServiceBaseUrl()}/${subjectId}`,
      {},
      HTTP_METHOD.POST,
      null,
      item
    );
  }

  getAttendedStateListOptions(t)  {
    return ATTENDED_STATE.map(s => {
      const key = `SCHEDULED_ITEM_ATTENDED_STATE_${s}`;
      return {
        key: s,
        text: t(key, GeneralHelpers.convertTranslationKeyToPascalCase(s)),
        value: s
      };
    });
  }

}
