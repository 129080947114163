import React, { useContext, useEffect, useState, Fragment } from "react";
import { withTranslation } from "react-i18next";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Icon,
  Label,
  Loader,
  Message,
  Table,
} from "semantic-ui-react";
import Page from "../../components/page/Page";
import UserContext from "../../context/UserContext";
import TriggerService from "../../services/admin/TriggerService";
import AdminTriggerViewModal from "../../components/admin/AdminTriggerViewModal";
import TRIGGER_STATE from "../../constants/TRIGGER_STATE";
import TrialService from "../../TrialService";
import { saveAs } from "file-saver";
import DataFormatService from "../../services/DataFormatService";
import { usePapaParse } from "react-papaparse";
import { Link } from "react-router-dom";
import AparitoSwitch from "../../components/questionnaire/AparitoSwitch";

const AdminTriggerListPage = ({ t }) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [triggers, setTriggers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selected, setSelected] = useState(null);
  const [currentTriggerStateFilter, setCurrentTriggerStateFilter] = useState(
    TRIGGER_STATE.ACTIVE.value
  );

  const user = useContext(UserContext);
  const isSuperAdmin = user.profile.superAdmin;

  const [showAllFields, setShowAllFields] = useState(false);
  const toggleShowAllFields = () => setShowAllFields(!showAllFields)

  const { jsonToCSV } = usePapaParse();

  const getTriggerList = async () => {
    try {
      const triggerList = await TriggerService.getTriggers(
        currentTriggerStateFilter,
        searchTerm
      );
      if (triggerList) {
        setTriggers(triggerList);
        setLoading(false);
        setError(false);
      } else {
        setError(true);
        setLoading(false);
      }
    } catch (e) {
      console.error("Error fetching triggers: ", e);
      setError(true);
    }
  };

  const onDownloadAsJsonClick = async () => {
    const response = await TriggerService.getTriggersAsJson();
    const blobData = new Blob([response.json], {
      type: "text/csv;charset=utf-8;",
    });

    const trial = await TrialService.getCurrentTrial();
    const filename = `${trial.name}-triggers.json`;

    saveAs(blobData, filename);
  };

  const onDownloadAsCsvClick = async () => {
    const rawTableData = DataFormatService.getTableData("triggerList");

    const results = jsonToCSV(rawTableData);
    const blobData = DataFormatService.buildCsvBlob(results);

    const trial = await TrialService.getCurrentTrial();
    const filename = `${trial.name}-triggers.csv`;

    saveAs(blobData, filename);
  };

  useEffect(() => {
    getTriggerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, currentTriggerStateFilter]);

  const createTable = (triggers) => {
    if (!triggers) {
      return;
    }

    const headers = [
      {
        title: ["ADMIN_TRIGGER_TABLE_HEADER_STATE", "State"],
      },
      {
        title: ["ADMIN_TRIGGER_TABLE_HEADER_NAME", "Name"],
      },
      {
        title: ["ADMIN_TRIGGER_TABLE_HEADER_TRIGGER", "Trigger"],
        config: { width: 3 },
      },
      {
        title: ["ADMIN_TRIGGER_TABLE_HEADER_ACTION", "Action"],
        config: { width: 3 },
      },
      {
        title: ["ADMIN_TRIGGER_TABLE_HEADER_CONDITION", "Condition"],
        config: { width: 3 },
      },
      {
        title: [
          "ADMIN_TRIGGER_TABLE_HEADER_ACTIVATIONWINDOW",
          "Activation Window",
        ],
      },
      {
        title: ["ADMIN_TRIGGER_TABLE_HEADER_OFFSET", "Offset"],
      },
    ];

    if (showAllFields) {
      headers.push(
        {
          title: ["ADMIN_TRIGGER_TABLE_HEADER_FREQUENCY", "Frequency"],
        },
        {
          title: ["ADMIN_TRIGGER_TABLE_HEADER_MAXOCCURRENCES", "Max occurrences"],
        },
        {
          title: ["ADMIN_TRIGGER_TABLE_HEADER_SHOULDNOTIFY", "Should notify?"],
        },
        {
          title: ["ADMIN_TRIGGER_TABLE_HEADER_SHOULDBREAKPARENTCHAIN", "Should break parent chain?"],
        },
        {
          title: ["ADMIN_TRIGGER_TABLE_HEADER_MODULESPECIFICATION", "Module Specification"],
        },
        {
          title: ["ADMIN_TRIGGER_TABLE_HEADER_ID", "ID"],
        }
      );
    }

    const cellStyle = { textOverflow: "ellipses", whiteSpace: "nowrap" };

    const headerRow = (
      <Table.Header>
        <Table.Row>
          {headers.map((header) => (
            <Fragment key={header.title}>
              <Table.HeaderCell {...header?.config}>
                {t(header.title)}
              </Table.HeaderCell>
            </Fragment>
          ))}
        </Table.Row>
      </Table.Header>
    );

    const triggerRows = (
      <Table.Body>
        {triggers.map((trigger, index) => (
          <Fragment key={trigger.name}>
          <Table.Row
            active={trigger.id === selected}
            onClick={() => setSelected(trigger.id)}
            negative={!trigger.isActive}
          >
            <Table.Cell>
              {trigger.isActive && (
                <Label color="green" horizontal>
                  {t(TRIGGER_STATE.ACTIVE.label)}
                </Label>
              )}
              {!trigger.isActive && (
                <Label color="red" horizontal>
                  {t(TRIGGER_STATE.INACTIVE.label)}
                </Label>
              )}
            </Table.Cell>
            <Table.Cell>{trigger.name}</Table.Cell>
            <Table.Cell style={cellStyle}>{trigger.trigger}</Table.Cell>
            <Table.Cell style={cellStyle}>{trigger.action}</Table.Cell>
            <Table.Cell style={cellStyle}>{trigger.condition}</Table.Cell>
            <Table.Cell>{trigger.activationWindow}</Table.Cell>
            <Table.Cell>{trigger.offset}</Table.Cell>
            {showAllFields && (
              <>
                <Table.Cell>{trigger.frequency}</Table.Cell>
                <Table.Cell>{trigger.maxOccurrences}</Table.Cell>
                <Table.Cell>{JSON.stringify(trigger.shouldNotify)}</Table.Cell>
                <Table.Cell>{JSON.stringify(trigger.shouldBreakParentChain)}</Table.Cell>
                <Table.Cell style={cellStyle}>{trigger.moduleSpecification}</Table.Cell>
                <Table.Cell>{trigger.id}</Table.Cell>
              </>
            )}
          </Table.Row>
          </Fragment>
        ))}
      </Table.Body>
    );
    return (
      <>
        {headerRow}
        {triggerRows}
      </>
    );
  };

  const getTriggerStateFilterOptions = () => {
    return Object.values(TRIGGER_STATE).map((triggerState) => {
      return {
        key: triggerState.value,
        text: t(triggerState.label),
        value: triggerState.value,
      };
    });
  };

  const onTriggerStateFilterChange = (e, data) => {
    const value = data?.value;
    setCurrentTriggerStateFilter(value);
  };

  return (
    <Page
      name="Admin Trigger Utilities"
      header={t("ADMIN_TRIGGER_HEADER", "Admin Triggers")}
    >
      {error && (
        <Message
          error
          header={t("GLOBAL_ERROR_TITLE", "Error")}
          content={"Error, if it persist contact support:" + error.message}
        />
      )}
      {loading && <Loader active={loading} />}
      {!isSuperAdmin && (
        <Message
          error
          header={t("GLOBAL_ERROR_TITLE", "Error")}
          content={t("ADMIN_UTILS_NOT_LOGGED_IN_AS_ADMIN", "NOT AUTHORIZED")}
        />
      )}
      {!loading && isSuperAdmin && Array.isArray(triggers) && (
        <>
          <Form>
            <Form.Field>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={12}>
                    <Dropdown
                      fluid
                      selection
                      options={getTriggerStateFilterOptions()}
                      value={currentTriggerStateFilter}
                      onChange={onTriggerStateFilterChange}
                    />
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Button
                      as={Link}
                      to="/app/utils/triggers/new"
                      fluid
                      primary
                    >
                      {t("ADMIN_TRIGGERS_NEW", "Create New Trigger")}
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form.Field>
            <Form.Field>
              <Form.Input
                icon={<Icon name="search" />}
                iconPosition={"left"}
                value={searchTerm}
                onChange={(e, v) => {
                  setSearchTerm(v.value);
                }}
              />
            </Form.Field>
          </Form>
          <AparitoSwitch
            checked={showAllFields}
            onChange={toggleShowAllFields}
            label={t("ADMIN_TRIGGER_SHOWALLCOLUMNS", "Show all columns?")}
          />
          <Table fixed id={"triggerList"}>
            {createTable(triggers)}
          </Table>
          <AdminTriggerViewModal
            triggerId={selected}
            isOpen={selected !== null}
            setIsOpen={() => setSelected(null)}
          />
        </>
      )}
      <Button onClick={onDownloadAsJsonClick}>
        {t("ADMIN_TRIGGER_DOWNLOADASJSON", "Download all as JSON")}
      </Button>
      <Button onClick={onDownloadAsCsvClick}>
        {t("ADMIN_TRIGGER_DOWNLOADASCSV", "Download above as CSV")}
      </Button>
    </Page>
  );
};

export default withTranslation()(AdminTriggerListPage);
