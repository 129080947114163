import RequestHelper, {CONTENT_TYPE} from "../../RequestHelper";


export const  recalculateAnswersForAllSubjects = async (questionnaireDefinitionCode, questionCode, missingOnly, completeOnly, ignoreExpressionErrors, from, to) => {
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS +
        "/admin/batch/recalculate-answers",
      {
          Accept: CONTENT_TYPE.APPLICATION_JSON,
          "Keep-Alive": "Keep-Alive: timeout=600, max=1000"
      },
      "POST",
        {
            questionnaireDefinitionCode,
            questionCode,
            missingOnly,
            completeOnly,
            ignoreExpressionErrors,
            from,
            to
        },
        {}
    );
  }

export const  recalculateAnswersForSingleSubject = async (questionnaireDefinitionCode, questionCode, missingOnly, completeOnly, ignoreExpressionErrors, from, to, subjectId) => {
    return RequestHelper.send(
        process.env.REACT_APP_SERVER_ADDRESS +
        `/admin/batch/subject/${subjectId}/recalculate-answers`,
        {
            Accept: CONTENT_TYPE.APPLICATION_JSON,
            "Keep-Alive": "Keep-Alive: timeout=600, max=1000"
        },
        "POST",
        {
            questionnaireDefinitionCode,
            questionCode,
            missingOnly,
            completeOnly,
            ignoreExpressionErrors,
            from,
            to
        },
        {}
    );
}