import moment from 'moment';
import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, ButtonGroup, Dropdown, Popup, PopupContent, Table } from 'semantic-ui-react';
import DATA_QUERY_STATUS from '../../constants/dataquery/DATA_QUERY_STATUS';
import DATA_QUERY_TABLE_ACTIONS, { DATA_QUERY_TABLE_ACTION_TRANSLATIONS } from '../../constants/dataquery/DATA_QUERY_TABLE_ACTIONS';
import DATA_QUERY_TABLE_COLUMNS from '../../constants/dataquery/DATA_QUERY_TABLE_COLUMNS';
import DateTimeService from '../../services/DateTimeService';

const DataQueryTable = ({ t, queries = [], actionsToShow = [], columns = [], on, }) => {

  const headerRow = (
    <Table.Row key='headerRow'>
      {actionsToShow.length > 0 && <Table.HeaderCell key='rowActions'>{t("DATA_QUERY_TABLE_ACTIONS")}</Table.HeaderCell>}
      {columns.map((column) => {
        return (
          <Fragment key={column}>
            <Table.HeaderCell>{t("DATA_QUERY_TABLE_" + column)}</Table.HeaderCell>
          </Fragment>
        )
      })}
    </Table.Row>
  );

  const getDateCell = (value) => {
    const momentValue = moment(value);
    return (
      <Popup
        mouseEnterDelay={1000}
        mouseLeaveDelay={500}
        on="hover"
        flowing
        trigger={<Table.Cell>{DateTimeService.build.asDisplayDate(momentValue)}</Table.Cell>}
      >
        <PopupContent>{DateTimeService.build.asDisplayDateTime(momentValue)}</PopupContent>
      </Popup>
    );
  };

  const getCell = (index, query, column) => {
    const key = `queryRowActions_${index}_${column}`;
    let value = query[DATA_QUERY_TABLE_COLUMNS.asPropertyString(column)];
    if (value == null) {
      return (
        <Table.Cell key={key}>--</Table.Cell>
      );
    }

    if (column === DATA_QUERY_TABLE_COLUMNS.STATUS) {
      value = t("DATA_QUERY_TABLE_STATUS_" + value.toUpperCase());
    }

    const formatAsDate = DATA_QUERY_TABLE_COLUMNS.isDateTimeColumn(column);
    if (formatAsDate) {
      return getDateCell(value);
    }

    return (
      <Table.Cell key={key}>{value}</Table.Cell>
    );
  };

  const sortedQueries = queries.sort((q1, q2) => {
    return DATA_QUERY_STATUS[q1.status] - DATA_QUERY_STATUS[q2.status];
  });

  const handleActionDropdownChange = (action, query) => {
    if (!action) {
      return;
    }
    on[DATA_QUERY_TABLE_ACTIONS[action]](query);
  };

  const getActionTranslation = (action) => {
    const actionInfo = DATA_QUERY_TABLE_ACTION_TRANSLATIONS[action];
    return t(actionInfo.translationKey, actionInfo.fallbackText);
  }

  const generateActionControls = (query) => {
    const status = DATA_QUERY_STATUS[query.status];

    const actionsToRender = [];
    if (actionsToShow.indexOf(DATA_QUERY_TABLE_ACTIONS.ANSWER) >= 0 && status === DATA_QUERY_STATUS.OPEN) {
      actionsToRender.push(DATA_QUERY_TABLE_ACTIONS.ANSWER);
    }
    if (actionsToShow.indexOf(DATA_QUERY_TABLE_ACTIONS.CLOSE) >= 0 && status === DATA_QUERY_STATUS.ANSWERED) {
      actionsToRender.push(DATA_QUERY_TABLE_ACTIONS.CLOSE);
    }
    if (actionsToShow.indexOf(DATA_QUERY_TABLE_ACTIONS.LINK_TO_QUESTIONNAIRE) >= 0) {
      actionsToRender.push(DATA_QUERY_TABLE_ACTIONS.LINK_TO_QUESTIONNAIRE);
    }
    if (actionsToShow.indexOf(DATA_QUERY_TABLE_ACTIONS.LINK_TO_SUBJECT) >= 0) {
      actionsToRender.push(DATA_QUERY_TABLE_ACTIONS.LINK_TO_SUBJECT);
    }

    if (actionsToRender.length === 0) {
      return;
    }

    const controlButtonAction = actionsToRender.shift();
    const button = <Button onClick={() => on[DATA_QUERY_TABLE_ACTIONS[controlButtonAction]](query)}>{getActionTranslation(controlButtonAction)}</Button>;

    const dropdownOptions = actionsToRender
      .map((action) => {
        return {
          key: action,
          text: getActionTranslation(action),
          value: action,
        };
      });

    if (dropdownOptions.length > 0) {
      // If this isn't present, then the first drop down item will fire even if not clicked
      dropdownOptions.unshift({key: '', text: '', value: ''});
    }

    return <ButtonGroup>
      {button}
      {dropdownOptions.length > 0 && (
        <Dropdown
          className='button icon'
          options={dropdownOptions}
          onChange={(_e, data) => handleActionDropdownChange(data.value, query)}
          trigger={<></>}
        />
      )}
    </ButtonGroup>;
  };

  const queryRows = sortedQueries.map((query) => {
    const actionControls = generateActionControls(query);
    return (
      <Table.Row key={`queryRow_${query.id}`}>
        {actionsToShow.length > 0 && (
          <Table.Cell key='queryRowActions'>
            {actionControls}
          </Table.Cell>
        )}
        {columns.map((column, index) => getCell(index, query, column))}
      </Table.Row>
    );
  });

  return (
    <>
      <Table>
        <Table.Header>
          {headerRow}
        </Table.Header>
        <Table.Body>
          {queryRows}
        </Table.Body>
      </Table>
    </>
  );
};

export default withTranslation()(DataQueryTable);