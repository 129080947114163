import React, { useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Page from "../../components/page/Page";
import RoleEditComponent from "../../components/roles/RoleEditComponent";
import TrialContext from "../../context/TrialContext";
import RoleService from "../../services/RoleService";

function RolesEditPage({ t }) {

  const params = useParams();
  const [editRole, setEditRole] = useState({ code: '', label: '', override: '', creatableRolesOverride: [], permissions: [], mandatoryTraining: [] });
  const trial = useContext(TrialContext);

  const history = useHistory();

  useEffect(() => {
    RoleService.getRole(params.id).then(role => {
      if (role?.mandatoryTraining != null) {
        role.mandatoryTraining = role?.mandatoryTraining.split(",").map(id => parseInt(id));
      }
      setEditRole(role);
    });
  }, [params.id]);

  async function handleSubmit(updatedRole, feedback) {
    await RoleService.updateRole(updatedRole, feedback);
    history.push("/app/roles");
  }

  return (<Page
    name="ROLES_EDIT"
    header={t("ROLES_EDIT_HEADER", "Edit Role")}
    subheader={t("ROLES_EDIT_SUBHEADER", "Editing a Role")}
    loading={false}
  >
    <RoleEditComponent
      onSubmit={handleSubmit}
      trial={trial}
      roleToEdit={editRole}
    />
  </Page>);
}

export default withTranslation()(RolesEditPage);
