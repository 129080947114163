import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {createPortal} from "react-dom";
import RegionSelectorControls from "./RegionSelectorControls";
import useCalculatedFPS from "./hooks/useCalculatedFPS";
import DateTimeService from "../../../services/DateTimeService";
import {saveAs} from "file-saver";
import useKey from '../../../hooks/useKey';
import {clamp} from 'lodash';
import KeyboardHotkeyMappings from "../../../utility/KeyboardHotkeyMappings";
import AttachmentService from "../../../services/AttachmentService"
import {withTranslation} from "react-i18next";


const RegionSelector = ({t, video, portalTarget, videoSizeState, question,changeAnswerMapValue, videoData}) => {
  const canvas = useRef(null);
  const box = useRef(null);
  const handle = useRef(null);
  const videoNativeSize = [video?.videoWidth, video?.videoHeight];
  const videoRect = video?.getBoundingClientRect() || new DOMRect();
  const editorMode = 'BLUR_CORRECTION_DATA';  //
  const [boxRect, setBoxRect] = useState({
    x: 0,
    y: 0,
    width: 100,
    height: 100,
  });
  const questionCode = question?.code;
  const attachmentsConfig = question?.config?.regionSelectorAttachmentsConfig;

  const [entries, setEntries] = useState([])
  const [fps, setFPS] = useState(null);
  const [regionError,setRegionError] = useState(null);

  // This is a bit of a hack
  // cycle1 - isExpandedView set to true
  // cycle2 - this component and video rerender, this value changes
  // cycle3 - this component rerenders getting new video size
  const [localVideoSizeState, setLocalVideoSizeState] = useState(videoSizeState);
  useEffect(()=>{
    if(videoSizeState !== localVideoSizeState) {
      setLocalVideoSizeState(videoSizeState);
    }
  }, [localVideoSizeState, videoSizeState])

  useEffect(()=>{
    KeyboardHotkeyMappings.unAssignKeys();
    return () => {
      KeyboardHotkeyMappings.assignKeys();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // calculate video padding
  // assumption is made here that if horizontalLeadScaling is true there will be vertical padding
  // and the opposite if horizontalLeadScaling is false
  const isHorizontalLeadScaling = videoNativeSize[0] > videoNativeSize[1];
  const scaleFactor = isHorizontalLeadScaling ?
      videoRect.width / videoNativeSize[0] :
      videoRect.height / videoNativeSize[1];
  const halfXDeadzone = isHorizontalLeadScaling ?
      0 :
      (videoRect.width - (videoNativeSize[0] * scaleFactor)) / 2 ;
  const halfYDeadzone = isHorizontalLeadScaling ?
      (videoRect.height - (videoNativeSize[1] * scaleFactor)) / 2 :
      0 ;

  // Calculate video coords based on scale and margin.
  // Video native size could be used as max clamp, but this does assure visible clamp consistency as
  // boxCoords are derived from coords on release.
  const coords = useMemo(() => {
    const x1 = Math.round(
        clamp(
            (boxRect.x - halfXDeadzone) / scaleFactor,
            0,
            (videoRect.width  - boxRect.width - halfXDeadzone * 2) / scaleFactor
        )
    );
    const y1 = Math.round(
        clamp(
            (boxRect.y - halfYDeadzone) / scaleFactor,
            0,
            (videoRect.height - boxRect.height - halfYDeadzone * 2) / scaleFactor
        )
    );

    const x2 = Math.round(
        clamp(
            x1 + boxRect.width / scaleFactor,
            0,
            (videoRect.width - halfXDeadzone * 2 ) / scaleFactor
        )
    );
    const y2 = Math.round(
        clamp(
            y1 + boxRect.height / scaleFactor,
            0,
            (videoRect.height - halfYDeadzone * 2) / scaleFactor
        )
    );

    return { x1, y1, x2, y2};
  }, [boxRect.height, boxRect.width, boxRect.x, boxRect.y, halfXDeadzone, halfYDeadzone, scaleFactor, videoRect.height, videoRect.width]);

  // Handle Box Interaction
  const [offset, setOffset] = useState([0,0]);
  const [initial, setInitial] = useState([0,0]);
  let [isDragging, setIsDragging] = useState(false);
  let [isResizing, setIsResizing] = useState(false);
  const initiateBoxDrag = (e) => {
    if (e.target === handle.current) {
      setIsResizing(true)
      setInitial([e.clientX, e.clientY])
    } else {
      setIsDragging(true)
      const offsetX = e.clientX - box.current.getBoundingClientRect().left;
      const offsetY = e.clientY - box.current.getBoundingClientRect().top;
      setOffset([offsetX,offsetY]);
    }
  }
  const handleBoxInteraction = (e) => {
    if (isDragging) {
      const x = e.clientX - offset[0] - videoRect.x;
      const y = e.clientY - offset[1] - videoRect.y;
      setBoxRect(prev=>({
        x: x,
        y: y,
        width: prev.width,
        height: prev.height,
      }))
    }
    if (isResizing) {
      const width = box.current.offsetWidth + (e.clientX - initial[0]);
      const height = box.current.offsetHeight + (e.clientY - initial[1]);
      setInitial([e.clientX,e.clientY])
      setBoxRect(prev=>({
        x: prev.x,
        y: prev.y,
        width: width,
        height: height,
      }))
    }
  }
  const stopBoxDrag = useCallback(() => {
    setIsDragging(false)
    setIsResizing(false)
    setBoxRect(prev=>({
      x: coords.x1 * scaleFactor + halfXDeadzone,
      y: coords.y1 * scaleFactor + halfYDeadzone,
      height: (coords.y2 - coords.y1) * scaleFactor ,
      width: (coords.x2 - coords.x1) * scaleFactor,
    }))
  }, [coords.x1, coords.x2, coords.y1, coords.y2, halfXDeadzone, halfYDeadzone, scaleFactor]);

  // handle temporal information
  const calculatedFPSData = useCalculatedFPS(video);

  const currentFrame = useMemo(()=>{
    if(
        typeof calculatedFPSData.currentTime !== "number" ||
        typeof fps !== "number"
    ) {
      return null;
    }
    return Math.round(calculatedFPSData.currentTime * fps);
  }, [calculatedFPSData, fps]);

  // handle drawin on the canvas
  useEffect(()=>{
    const ctx = canvas.current.getContext("2d");
    ctx.clearRect(0,0, 10000, 10000)
    ctx.lineWidth = 1
    ctx.fillStyle = "#fff2"
    ctx.strokeStyle = "#0004"
    entries.forEach((entry)=>{
      if(entry.frame !== currentFrame) return;
      ctx.fillRect(
          entry.coords.x1 * scaleFactor + halfXDeadzone,
          entry.coords.y1 * scaleFactor + halfYDeadzone,
          (entry.coords.x2 - entry.coords.x1) * scaleFactor,
          (entry.coords.y2 - entry.coords.y1) * scaleFactor,
      );
      ctx.strokeRect(
          entry.coords.x1 * scaleFactor + halfXDeadzone,
          entry.coords.y1 * scaleFactor + halfYDeadzone,
          (entry.coords.x2 - entry.coords.x1) * scaleFactor,
          (entry.coords.y2 - entry.coords.y1) * scaleFactor,
      );
    });
  }, [currentFrame, entries, halfXDeadzone, halfYDeadzone, scaleFactor])

  useEffect(()=>{
    if (fps == null || fps === 0){
      setFPS(calculatedFPSData.fps);
    }
  },[fps,calculatedFPSData])
  // Controls
  async function nextFrame() {
    if (fps === null) return;
    const calculatedTime = calculatedFPSData.currentTime + (1 / fps)
    //calculation time sometimes falls short to select the next frame
    video.currentTime = Math.ceil(calculatedTime * 100000) / 100000; //so, round up the number to 5 decimal places.
    await video.pause();
  }
  async function previousFrame() {
    if (fps === null) return;
    const calculatedTime = calculatedFPSData.currentTime - (1 / fps)
    video.currentTime = Math.ceil(calculatedTime * 1000000) / 1000000;//round up number to 6 decimal places
    await video.pause();
  }
  async function log() {
    await setEntries((prev) => [
      ...prev,
        { frame: currentFrame, coords, id: crypto.randomUUID() },
    ]);
    prepareDataForFileUpload();
  }
  function buildBlobData(){
    const titles = "id,frame,x1,y1,x2,y2\n"
    const csvEntries = entries.map(e=>`${e.id},${e.frame},${e.coords.x1},${e.coords.y1},${e.coords.x2},${e.coords.y2}\n`)
    return new Blob([titles, ...csvEntries], {
      type: "text/csv;charset=utf-8;",
    })
  }

  function prepareDataForFileUpload () {
    const csvBlobData = buildBlobData();

    if (attachmentsConfig) {
      if (changeAnswerMapValue) {
        const configForEditorMode = attachmentsConfig.filter(conf => conf?.attachmentType === editorMode);
        if (configForEditorMode.length === 1) {
          try {
            AttachmentService.injectAttachmentForUpload(t, changeAnswerMapValue, configForEditorMode[0]?.fileUploader, csvBlobData, configForEditorMode[0]?.mimeType, videoData.attachmentVariantRef)
          }catch(error){
            setRegionError(error)
          }
        } else {
          console.error("Attachment configuration of: " + questionCode + " is expecting one element of regionSelectorAttachmentsConfig.attachmentType: " + editorMode + " but got " + configForEditorMode.size);
        }
      } else {
        console.error("changeAnswerMapValue function is not defined, is the region selection tool being used from readonly questionnaire view? No data will be prepared for upload.");
      }
    } else {
      console.warn("No attachment configuration defined for Region Selector, will not attempt to upload generated data from Region Selector tool on submission");
    }
  }
  async function download(){
    const csvBlobData = buildBlobData();
    const dateTimeWithMillis = DateTimeService.now.asString();
    const filename = `video-region-selector-${questionCode}-${dateTimeWithMillis}.csv`;
    saveAs(csvBlobData, filename);
  }
  async function remove(entryId) {
    setEntries(prev => {
      const index = prev.findIndex(e=>e.id === entryId)
      let newEntries = [...prev];
      newEntries.splice(index, 1)
      return newEntries;
    })
    prepareDataForFileUpload();
  }
  function playPause() {
    if (video?.paused) {
      video.play();
    } else {
      video.pause();
    }
  }
  function nudgeBox(dx, dy) {
    setBoxRect((prev) => ({
      ...prev,
      x: prev.x + dx,
      y: prev.y + dy,
    }));
  }
  function resizeBox(dx, dy) {
    setBoxRect((prev) => ({
      ...prev,
      height: prev.height + dy,
      width: prev.width + dx,
    }));
  }
  // Note that the instructions should be shown in the translations in RegionSelectorControls
  useKey("KeyQ", () => previousFrame());
  useKey("KeyW", () => nextFrame());
  useKey("KeyE", () => log());
  useKey("Space", () => playPause());
  useKey("KeyI", () => nudgeBox(0, -1));
  useKey("KeyK", () => nudgeBox(0, 1));
  useKey("KeyJ", () => nudgeBox(-1, 0));
  useKey("KeyL", () => nudgeBox(1, 0));
  useKey("KeyU", ()=>resizeBox(-1,-1))
  useKey("KeyO", ()=>resizeBox(1,1))


  // This is portaled into the parent video component
  const portaledInputs = <RegionSelectorControls
      video={video}
      coords={coords}
      entries={entries}
      setEntries={setEntries}
      fps={fps}
      setFPS={setFPS}
      calculatedFPSData={calculatedFPSData}
      currentFrame={currentFrame}
      handlePrevFrame={previousFrame}
      handleNextFrame={nextFrame}
      handleLog={log}
      handleDownload={download}
      handleRemove={remove}
      error={regionError}
  />

  return (
    <div
        className={"frame-area-selector"}
        onMouseMove={handleBoxInteraction}
        style={{
          position:"absolute",
          left: 0,
          top: 0,
          width: videoRect.width,
          height: videoRect.height,
          zIndex: 999,
        }}
    >
      {portalTarget.current && createPortal(portaledInputs, portalTarget.current)}
      <canvas
          ref={canvas}
          width={`${videoRect.width}px`}
          height={`${videoRect.height}px`}
          style={{position: 'absolute', left: 0, top: 0, zIndex:999}} />
      <div
          className={"box"}
          ref={box}
          onMouseDown={initiateBoxDrag}
          onMouseUp={stopBoxDrag}
          style={{
            position:"absolute",
            left: boxRect.x,
            top: boxRect.y,
            width: boxRect.width,
            height: boxRect.height,
            resize:"both",
            borderStyle: "solid",
            borderWidth: 2,
            borderColor: "#f8991d",
            backgroundColor:"#f8991d22",
            zIndex: 1000
          }}
      >
        <div ref={handle} className="handle" style={{
          width: '10px',
          height: '10px',
          backgroundColor: "#f8991d",
          position: 'absolute',
          bottom: 0,
          right: 0,
          cursor: 'se-resize',
        }}></div>
      </div>
    </div>
  )
}

export default withTranslation()(RegionSelector);