
// Update in PERMISSION_DEFAULT_TRANSLATION below too when adding to list
const GroupPermission = {
  VIEW_SUBJECT_QUESTIONNAIRES: "VIEW_SUBJECT_QUESTIONNAIRES",
  VIEW_SUBJECT: "VIEW_SUBJECT",
  VIEW_SUBJECT_MEDIA: "VIEW_SUBJECT_MEDIA",
  VIEW_SUBJECT_DATA: "VIEW_SUBJECT_DATA",
  UPDATE_SUBJECT_EMAIL: "UPDATE_SUBJECT_EMAIL",
  UPDATE_SUBJECT_LANGUAGE: "UPDATE_SUBJECT_LANGUAGE",
  UPDATE_SUBJECT_CODE: "UPDATE_SUBJECT_CODE",
  RESET_SUBJECT_PASSWORD: "RESET_SUBJECT_PASSWORD",
  UPDATE_SUBJECT_RECORDS: "UPDATE_SUBJECT_RECORDS",
  UPDATE_SUBJECT_ACTIVE: "UPDATE_SUBJECT_ACTIVE",
  MANAGE_SUBJECT_ALLERGIES: "MANAGE_SUBJECT_ALLERGIES",
  MANAGE_SUBJECT_DEVICES: "MANAGE_SUBJECT_DEVICES",
  MANAGE_SUBJECT_DEVICES_STATE: "MANAGE_SUBJECT_DEVICES_STATE",
  MANAGE_SUBJECT_MEDICATION: "MANAGE_SUBJECT_MEDICATION",
  MANAGE_SUBJECT_VIDEOCALLS: "MANAGE_SUBJECT_VIDEOCALLS",
  CREATE_SUBJECT: "CREATE_SUBJECT",
  // UPDATE_SUBJECT: "UPDATE_SUBJECT", // This permission exists, but it's just a group of the above
  MODIFY_ACTIVATION_WINDOW: "MODIFY_ACTIVATION_WINDOW",
  MANUAL_QUESTIONNAIRE_DELIVERY: "MANUAL_QUESTIONNAIRE_DELIVERY",
  CREATE_SUBJECT_QUESTIONNAIRES: "CREATE_SUBJECT_QUESTIONNAIRES",
  SUBMIT_SUBJECT_QUESTIONNAIRES: "SUBMIT_SUBJECT_QUESTIONNAIRES",
  SUBMIT_BLINDED_QUESTIONNAIRES: "SUBMIT_BLINDED_QUESTIONNAIRES",
  BLIND_SUBJECT_INFORMATION: "BLIND_SUBJECT_INFORMATION",
  MANAGE_SUBJECT_GROUPS: "MANAGE_SUBJECT_GROUPS",
  RECEIVE_ALERTS: "RECEIVE_ALERTS",
  MANAGE_SITE_EXPORTS: "MANAGE_SITE_EXPORTS",
  MANAGE_EXPORTS: "MANAGE_EXPORTS",
  MANAGE_REWARDS: "MANAGE_REWARDS",
  CREATE_STAFF: "CREATE_STAFF",
  CREATE_STAFF_CREATORS: "CREATE_STAFF_CREATORS",
  VIEW_STAFF: "VIEW_STAFF",
  VIEW_DATA_QUERIES: "VIEW_DATA_QUERIES",
  RAISE_DATA_QUERIES: "RAISE_DATA_QUERIES",
  ANSWER_DATA_QUERIES: "ANSWER_DATA_QUERIES",
  CLOSE_DATA_QUERIES: "CLOSE_DATA_QUERIES",
  MANAGE_GROUPS: "MANAGE_GROUPS",
  UPDATE_GROUP_METADATA: "UPDATE_GROUP_METADATA",
  RANDOMISE_SUBJECT: "RANDOMISE_SUBJECT",
  SEARCH_ATTACHMENTS: "SEARCH_ATTACHMENTS",
  VIEW_NOTIFICATIONS: "VIEW_NOTIFICATIONS",
  VIEW_SITE_DASHBOARD_REPORTS: "VIEW_SITE_DASHBOARD_REPORTS",
  UPDATE_ATTACHMENT_WORKFLOW: "UPDATE_ATTACHMENT_WORKFLOW",
  SEARCH_AUDIT: "SEARCH_AUDIT",
  MANAGE_SCREENSHOTS: "MANAGE_SCREENSHOTS",
  VIEW_BLURRED_MEDIA: "VIEW_BLURRED_MEDIA",
  SUBMIT_BLINDED_QUESTIONNAIRE: "SUBMIT_BLINDED_QUESTIONNAIRE",
  EXPORT_QUESTIONNAIRE_PDF: "EXPORT_QUESTIONNAIRE_PDF",
  BATCH_ATTACHMENTS_VIEW: "BATCH_ATTACHMENTS_VIEW",
	BATCH_ATTACHMENTS_UPLOAD_FILES: "BATCH_ATTACHMENTS_UPLOAD_FILES",
	BATCH_ATTACHMENTS_PROCESS_DATA: "BATCH_ATTACHMENTS_PROCESS_DATA",
  BATCH_ATTACHMENTS_DELETE_FILES: "BATCH_ATTACHMENTS_DELETE_FILES",
  DOWNLOAD_SUBJECT_MEDIA: "DOWNLOAD_SUBJECT_MEDIA",
  ALLOW_SUBJECT_MEDIA_AUDIO: "ALLOW_SUBJECT_MEDIA_AUDIO",
  EDIT_SUBJECT_QUESTIONNAIRES: "EDIT_SUBJECT_QUESTIONNAIRES",
  MANUAL_OVERRIDE_ATTACHMENT_WORKFLOW_STATUS: "MANUAL_OVERRIDE_ATTACHMENT_WORKFLOW_STATUS",
  MANAGE_SUBJECT_VISITDATES: "MANAGE_SUBJECT_VISITDATES",
  UPDATE_VISITDATE_ATTENDED_STATE: "UPDATE_VISITDATE_ATTENDED_STATE",
  MANAGE_TRIAL_VISITDATES: "MANAGE_TRIAL_VISITDATES",
  MANAGE_SITE_VISITDATES: "MANAGE_SITE_VISITDATES",
  VIEW_SUBJECT_VISITDATES: "VIEW_SUBJECT_VISITDATES"
};


export const PERMISSION_DEFAULT_TRANSLATION = {
  [GroupPermission.VIEW_SUBJECT]: {
    labelTranslation: {
      translationKey: 'VIEW_SUBJECT_DEFAULT_LABEL',
      label: "View Participant"
    },
    hoverDescription: {
      translationKey: 'VIEW_SUBJECT_DEFAULT_DESCRIPTION_LABEL',
      hover: 'View Participant - Permission to allow user to View a participant'
    }
  },
  [GroupPermission.VIEW_SUBJECT_MEDIA]: {
    labelTranslation: {
      translationKey: 'VIEW_SUBJECT_MEDIA_DEFAULT_LABEL',
      label: "View Participant Media"
    },
    hoverDescription: {
      translationKey: 'VIEW_SUBJECT_MEDIA_DEFAULT_DESCRIPTION_LABEL',
      hover: 'View Participant Media - View uploaded participant media, e.g Videos, Images, FIles...'
    }
  },
  [GroupPermission.VIEW_SUBJECT_DATA]: {
    labelTranslation: {
      translationKey: 'VIEW_SUBJECT_DATA_DEFAULT_LABEL',
      label: "View Participant Data"
    },
    hoverDescription: {
      translationKey: 'VIEW_SUBJECT_DATA_DEFAULT_DESCRIPTION_LABEL',
      hover: 'View Participant Data - View information about the participant and questionnaire responses'
    }
  },
  [GroupPermission.VIEW_SUBJECT_QUESTIONNAIRES]: {
    labelTranslation: {
      translationKey: 'VIEW_SUBJECT_QUESTIONNAIRES_DEFAULT_LABEL',
      label: "View Participant Questionnaires"
    },
    hoverDescription: {
      translationKey: 'VIEW_SUBJECT_QUESTIONNAIRES_DEFAULT_DESCRIPTION_LABEL',
      hover: 'View Participant Questionnaires - Permission to allow user to View participant questionnaires'
    }
  },
  [GroupPermission.VIEW_STAFF]: {
    labelTranslation: {
      translationKey: 'VIEW_STAFF_DEFAULT_LABEL',
      label: "View staff"
    },
    hoverDescription: {
      translationKey: 'VIEW_STAFF_DEFAULT_DESCRIPTION_LABEL',
      hover: 'View staff - Allows the viewing of currently registered staff'
    }
  },
  [GroupPermission.CREATE_STAFF]: {
    labelTranslation: {
      translationKey: 'CREATE_STAFF_DEFAULT_LABEL',
      label: "Create staff"
    },
    hoverDescription: {
      translationKey: 'CREATE_STAFF_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Create staff - Allows the creation of other staff members'
    }
  },
  [GroupPermission.CREATE_STAFF_CREATORS]: {
    labelTranslation: {
      translationKey: 'CREATE_STAFF_CREATORS_DEFAULT_LABEL',
      label: "Create Staff Creators"
    },
    hoverDescription: {
      translationKey: 'CREATE_STAFF_CREATORS_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Create Staff Creators - Allows the creation of staff that can create other staff'
    }
  },
  [GroupPermission.UPDATE_SUBJECT_EMAIL]: {
    labelTranslation: {
      translationKey: 'UPDATE_SUBJECT_EMAIL_DEFAULT_LABEL',
      label: "Update Participant Email"
    },
    hoverDescription: {
      translationKey: 'UPDATE_SUBJECT_EMAIL_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Update Participant Email - Allowed to update the participants email'
    }
  },
  [GroupPermission.UPDATE_SUBJECT_LANGUAGE]: {
    labelTranslation: {
      translationKey: 'UPDATE_SUBJECT_LANGUAGE_DEFAULT_LABEL',
      label: "Update Participant Language"
    },
    hoverDescription: {
      translationKey: 'UPDATE_SUBJECT_LANGUAGE_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Update Participant Language - Allowed to update the participants language'
    }
  },
  [GroupPermission.UPDATE_SUBJECT_CODE]: {
    labelTranslation: {
      translationKey: 'UPDATE_SUBJECT_CODE_DEFAULT_LABEL',
      label: "Update Participant Code"
    },
    hoverDescription: {
      translationKey: 'UPDATE_SUBJECT_CODE_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Update Participant Code - Allows updating/editing participant code'
    }
  },
  [GroupPermission.UPDATE_SUBJECT_RECORDS]: {
    labelTranslation: {
      translationKey: 'UPDATE_SUBJECT_RECORDS_DEFAULT_LABEL',
      label: "Update Participant Records"
    },
    hoverDescription: {
      translationKey: 'UPDATE_SUBJECT_RECORDS_DESCRIPTION_LABEL',
      hover: 'Update Participant Records - Allows the updating of information tied to the participant'
    }
  },
  [GroupPermission.UPDATE_SUBJECT_ACTIVE]: {
    labelTranslation: {
      translationKey: 'UPDATE_SUBJECT_ACTIVE_DEFAULT_LABEL',
      label: "Update Participant Active"
    },
    hoverDescription: {
      translationKey: 'UPDATE_SUBJECT_ACTIVE_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Update Participant Active - Allows the deactivation and reactivation of the participant'
    }
  },
  [GroupPermission.RESET_SUBJECT_PASSWORD]: {
    labelTranslation: {
      translationKey: 'RESET_SUBJECT_PASSWORD_DEFAULT_LABEL',
      label: "Reset Participant Password"
    },
    hoverDescription: {
      translationKey: 'RESET_SUBJECT_PASSWORD_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Reset Participant Password - Allows the resetting of the participants password'
    }
  },
  [GroupPermission.MANAGE_SUBJECT_ALLERGIES]: {
    labelTranslation: {
      translationKey: 'MANAGE_SUBJECT_ALLERGIES_DEFAULT_LABEL',
      label: "Manage Participant Allergies"
    },
    hoverDescription: {
      translationKey: 'MANAGE_SUBJECT_ALLERGIES_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Manage Participant Allergies - Allows the management of participant allergies'
    }
  },
  [GroupPermission.MANAGE_SUBJECT_DEVICES]: {
    labelTranslation: {
      translationKey: 'MANAGE_SUBJECT_DEVICES_DEFAULT_LABEL',
      label: "Manage Participant Devices"
    },
    hoverDescription: {
      translationKey: 'MANAGE_SUBJECT_DEVICES_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Manage Participant Devices - Allows QR code generation and device managementt'
    }
  },
  [GroupPermission.MANAGE_SUBJECT_DEVICES_STATE]: {
    labelTranslation: {
      translationKey: 'MANAGE_SUBJECT_DEVICES_STATE_DEFAULT_LABEL',
      label: "Manage Participant Device State"
    },
    hoverDescription: {
      translationKey: 'MANAGE_SUBJECT_DEVICES_STATE_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Manage Participant Device State'
    }
  },
  [GroupPermission.MANAGE_SUBJECT_MEDICATION]: {
    labelTranslation: {
      translationKey: 'MANAGE_SUBJECT_MEDICATION_DEFAULT_LABEL',
      label: "Manage Participant Medication"
    },
    hoverDescription: {
      translationKey: 'MANAGE_SUBJECT_MEDICATION_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Manage Participant Medication - Allows management of participant medication'
    }
  },
  [GroupPermission.MANAGE_SUBJECT_VIDEOCALLS]: {
    labelTranslation: {
      translationKey: 'MANAGE_SUBJECT_VIDEOCALLS_DEFAULT_LABEL',
      label: "Manage Participant Videocalls"
    },
    hoverDescription: {
      translationKey: 'MANAGE_SUBJECT_VIDEOCALLS_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Manage Participant Videocalls - Allows management of Scheduled Video Calls with the participant'
    }
  },
  [GroupPermission.CREATE_SUBJECT]: {
    labelTranslation: {
      translationKey: 'CREATE_SUBJECT_DEFAULT_LABEL',
      label: "Create Participant"
    },
    hoverDescription: {
      translationKey: 'CREATE_SUBJECT_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Create Participant - Allows the creations of participants within sites'
    }
  },
  [GroupPermission.MODIFY_ACTIVATION_WINDOW]: {
    labelTranslation: {
      translationKey: 'CMODIFY_ACTIVATION_WINDOW_DEFAULT_LABEL',
      label: "Modify Activation Window"
    },
    hoverDescription: {
      translationKey: 'MODIFY_ACTIVATION_WINDOWT_DESCRIPTION_LABEL',
      hover: 'Modify Activation Window - Allows the edit of participant questionnaire activation windows (missed PROs etc..)'
    }
  },
  [GroupPermission.MANUAL_QUESTIONNAIRE_DELIVERY]: {
    labelTranslation: {
      translationKey: 'MANUAL_QUESTIONNAIRE_DELIVERY_DEFAULT_LABEL',
      label: "Manual Questionnaire Delivery"
    },
    hoverDescription: {
      translationKey: 'MANUAL_QUESTIONNAIRE_DELIVERY_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Manual Questionnaire Delivery - Allows staff member to trigger a questionnaire on behalf of a participant'
    }
  },
  [GroupPermission.CREATE_SUBJECT_QUESTIONNAIRES]: {
    labelTranslation: {
      translationKey: 'CREATE_SUBJECT_QUESTIONNAIRES_DEFAULT_LABEL',
      label: "Create Participant Questionnaires"
    },
    hoverDescription: {
      translationKey: 'CREATE_SUBJECT_QUESTIONNAIRES_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Create Participant Questionnaires - Allows staff member to create participant details'
    }
  },
  [GroupPermission.SUBMIT_SUBJECT_QUESTIONNAIRES]: {
    labelTranslation: {
      translationKey: 'SUBMIT_SUBJECT_QUESTIONNAIRES_DEFAULT_LABEL',
      label: "Submit Participant Questionnaires"
    },
    hoverDescription: {
      translationKey: 'SUBMIT_SUBJECT_QUESTIONNAIRES_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Submit Participant Questionnaires - Allows staff member to submit a questionnaire on behalf of a participant'
    }
  },
  [GroupPermission.SUBMIT_BLINDED_QUESTIONNAIRES]: {
    labelTranslation: {
      translationKey: 'SUBMIT_BLINDED_QUESTIONNAIRES_DEFAULT_LABEL',
      label: "Submit Blinded Questionnaires"
    },
    hoverDescription: {
      translationKey: 'SUBMIT_BLINDED_QUESTIONNAIRES_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Submit Blinded Questionnaires - Allows staff member to submit blinded questionnaire on behalf of a participant'
    }
  },
  [GroupPermission.BLIND_SUBJECT_INFORMATION]: {
    labelTranslation: {
      translationKey: 'BLIND_SUBJECT_INFORMATION_DEFAULT_LABEL',
      label: "Blind Participant Information"
    },
    hoverDescription: {
      translationKey: 'BLIND_SUBJECT_INFORMATION_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Blind Participant Information'
    }
  },
  [GroupPermission.MANAGE_SUBJECT_GROUPS]: {
    labelTranslation: {
      translationKey: 'MANAGE_SUBJECT_GROUPS_DEFAULT_LABEL',
      label: "Manage Participant Sites"
    },
    hoverDescription: {
      translationKey: 'MANAGE_SUBJECT_GROUPS_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Manage Participant Sites - Manage the sites that a participant is in for sites with this permission'
    }
  },
  [GroupPermission.RECEIVE_ALERTS]: {
    labelTranslation: {
      translationKey: 'RECEIVE_ALERTS_DEFAULT_LABEL',
      label: "Receive Alerts"
    },
    hoverDescription: {
      translationKey: 'RECEIVE_ALERTS_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Receive Alerts - Receive configured alert emails'
    }
  },
  [GroupPermission.MANAGE_EXPORTS]: {
    labelTranslation: {
      translationKey: 'MANAGE_EXPORTS_DEFAULT_LABEL',
      label: "Manage Exports"
    },
    hoverDescription: {
      translationKey: 'MANAGE_EXPORTS_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Manage Exports - Allow viewing and downloading/creating exports'
    }
  },
  [GroupPermission.MANAGE_SITE_EXPORTS]: {
    labelTranslation: {
      translationKey: 'MANAGE_SITE_EXPORTS_DEFAULT_LABEL',
      label: "Manage Site Exports"
    },
    hoverDescription: {
      translationKey: 'MANAGE_SITE_EXPORTS_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Manage Site Exports - Allow viewing and downloading/creating exports by site'
    }
  },
  [GroupPermission.MANAGE_REWARDS]: {
    labelTranslation: {
      translationKey: 'MANAGE_MANAGE_REWARDS_DEFAULT_LABEL',
      label: "Manage Rewards"
    },
    hoverDescription: {
      translationKey: 'MANAGE_MANAGE_REWARDS_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Manage Rewards - Allow user to manage rewards (Amazon vouchers etc..)'
    }
  },
  [GroupPermission.VIEW_DATA_QUERIES]: {
    labelTranslation: {
      translationKey: 'CVIEW_DATA_QUERIES_DEFAULT_LABEL',
      label: "View Data Queries"
    },
    hoverDescription: {
      translationKey: 'VIEW_DATA_QUERIES_DEFAULT_DESCRIPTION_LABEL',
      hover: 'View Data Queries - Allows the user to view data queries'
    }
  },
  [GroupPermission.RAISE_DATA_QUERIES]: {
    labelTranslation: {
      translationKey: 'RAISE_DATA_QUERIES_DEFAULT_LABEL',
      label: "Raise Data Queries"
    },
    hoverDescription: {
      translationKey: 'RAISE_DATA_QUERIES_DEFAULT_DESCRIPTION_LABEL',
      hover: "Raise Data Queries - Allows the user to raise data queries, and grants 'View Data Queries'"
    }
  },
  [GroupPermission.ANSWER_DATA_QUERIES]: {
    labelTranslation: {
      translationKey: 'ANSWER_DATA_QUERIES_DEFAULT_LABEL',
      label: "Answer Data Queries"
    },
    hoverDescription: {
      translationKey: 'ANSWER_DATA_QUERIES_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Answer Data Queries - Allows the user to answer data queries, and grants "View Data Queries"'
    }
  },
  [GroupPermission.CLOSE_DATA_QUERIES]: {
    labelTranslation: {
      translationKey: 'CLOSE_DATA_QUERIES_DEFAULT_LABEL',
      label: "Close Data Queries"
    },
    hoverDescription: {
      translationKey: 'CLOSE_DATA_QUERIES_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Close Data Queries - Allows the user to close data queries, and grants "View Data Queries"'
    }
  },
  [GroupPermission.MANAGE_GROUPS]: {
    labelTranslation: {
      translationKey: 'MANAGE_GROUPS_DEFAULT_LABEL',
      label: "Manage Sites"
    },
    hoverDescription: {
      translationKey: 'MANAGE_GROUPS_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Manage Sites - Allows the editing of site'
    }
  },
  [GroupPermission.UPDATE_GROUP_METADATA]: {
    labelTranslation: {
      translationKey: 'UPDATE_GROUP_METADATA_DEFAULT_LABEL',
      label: "Update Site metadata"
    },
    hoverDescription: {
      translationKey: 'UPDATE_GROUP_METADATA_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Update Site metadata - Allows the user to update site metadata'
    }
  },
  [GroupPermission.RANDOMISE_SUBJECT]: {
    labelTranslation: {
      translationKey: 'RANDOMISE_SUBJECT_DEFAULT_LABEL',
      label: "Randomise Participant"
    },
    hoverDescription: {
      translationKey: 'RANDOMISE_SUBJECT_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Randomise Participant - Allows the user to randomise participants'
    }
  },
  [GroupPermission.SEARCH_ATTACHMENTS]: {
    labelTranslation: {
      translationKey: 'SEARCH_ATTACHMENTS_DEFAULT_LABEL',
      label: "Search Attachments"
    },
    hoverDescription: {
      translationKey: 'RSEARCH_ATTACHMENTS_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Search Attachments - Allows uploaded attachments to be viewed/searched'
    }
  },
  [GroupPermission.VIEW_NOTIFICATIONS]: {
    labelTranslation: {
      translationKey: 'VIEW_NOTIFICATIONS_DEFAULT_LABEL',
      label: "View Notifications"
    },
    hoverDescription: {
      translationKey: 'VIEW_NOTIFICATIONS_DEFAULT_DESCRIPTION_LABEL',
      hover: 'View Notifications - View notifications that have been sent'
    }
  },
  [GroupPermission.VIEW_SITE_DASHBOARD_REPORTS]: {
    labelTranslation: {
      translationKey: 'VIEW_SITE_DASHBOARD_REPORTS_DEFAULT_LABEL',
      label: "View Site Dashboard Reports"
    },
    hoverDescription: {
      translationKey: 'VIEW_SITE_DASHBOARD_REPORTS_DEFAULT_DESCRIPTION_LABEL',
      hover: 'View Site Dashboard Reports - View configured dashboard reports'
    }
  },
  [GroupPermission.UPDATE_ATTACHMENT_WORKFLOW]: {
    labelTranslation: {
      translationKey: 'UPDATE_ATTACHMENT_WORKFLOW_DEFAULT_LABEL',
      label: "Update Attachment Workflow"
    },
    hoverDescription: {
      translationKey: 'UPDATE_ATTACHMENT_WORKFLOW_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Update Attachment Workflow - Allows user to update workflow allowed attachment statuses'
    }
  },
  [GroupPermission.SEARCH_AUDIT]: {
    labelTranslation: {
      translationKey: 'SEARCH_AUDIT_DEFAULT_LABEL',
      label: "Search Audit"
    },
    hoverDescription: {
      translationKey: 'SEARCH_AUDIT_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Search Audit - Allows user to search audit'
    }
  },
  [GroupPermission.MANAGE_SCREENSHOTS]: {
    labelTranslation: {
      translationKey: 'MANAGE_SCREENSHOTS_DEFAULT_LABEL',
      label: "Manage Screenshots"
    },
    hoverDescription: {
      translationKey: 'MANAGE_SCREENSHOTST_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Manage Screenshots - Allows user to use screeshot admin tool'
    }
  },
  [GroupPermission.VIEW_BLURRED_MEDIA]: {
    labelTranslation: {
      translationKey: 'VIEW_BLURRED_MEDIA_DEFAULT_LABEL',
      label: "View Blurred Media"
    },
    hoverDescription: {
      translationKey: 'VIEW_BLURRED_MEDIA_DEFAULT_DESCRIPTION_LABEL',
      hover: 'View Blurred Media - Allows viewing of just attachments that have blurred flag set to TRUE'
    }
  },
  UPDATE_SUBJECT: {
    labelTranslation: {
      translationKey: 'UPDATE_SUBJECT_DEFAULT_LABEL',
      label: "Update Participant"
    },
    hoverDescription: {
      translationKey: 'UPDATE_SUBJECT_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Update Participant - Grants the permissions: View Participant, View Participant Media, View Participant Data, Update Participant Email, Update Participant Language, Reset Participant Password, Update Participant Records, Update Participant Active, Manage Participant Allergies, Manage Participant Devices, Manage Participant Medication, Manage Participant Scheduled items, Create Participant, Create Participant Questionnaires'
    }
  },
  [GroupPermission.EXPORT_QUESTIONNAIRE_PDF]: {
    labelTranslation: {
      translationKey: 'EXPORT_QUESTIONNAIRE_PDF_DEFAULT_LABEL',
      label: "Export Questionnaire to PDF"
    },
    hoverDescription: {
      translationKey: 'EXPORT_QUESTIONNAIRE_PDF_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Export Questionnaire to PDF - Allows user to export participant questionnaires to PDF on dashboard'
    }
  },
  [GroupPermission.BATCH_ATTACHMENTS_VIEW]: {
    labelTranslation: {
      translationKey: 'BATCH_ATTACHMENTS_VIEW_DEFAULT_LABEL',
      label: "Batch Attachment Upload - View"
    },
    hoverDescription: {
      translationKey: 'BATCH_ATTACHMENTS_VIEW_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Batch Attachment Upload - View'
    }
  },
  [GroupPermission.BATCH_ATTACHMENTS_UPLOAD_FILES]: {
    labelTranslation: {
      translationKey: 'BATCH_ATTACHMENTS_UPLOAD_FILES_DEFAULT_LABEL',
      label: "Batch Attachment Upload - Upload Files"
    },
    hoverDescription: {
      translationKey: 'BATCH_ATTACHMENTS_UPLOAD_FILES_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Batch Attachment Upload - Upload Files'
    }
  },
  [GroupPermission.BATCH_ATTACHMENTS_PROCESS_DATA]: {
    labelTranslation: {
      translationKey: 'BATCH_ATTACHMENTS_PROCESS_DATA_DEFAULT_LABEL',
      label: "Batch Attachment Upload - Process Data"
    },
    hoverDescription: {
      translationKey: 'BATCH_ATTACHMENTS_PROCESS_DATA_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Batch Attachment Upload - Process Data'
    }
  },
  [GroupPermission.BATCH_ATTACHMENTS_DELETE_FILES]: {
    labelTranslation: {
      translationKey: 'BATCH_ATTACHMENTS_DELETE_FILES_DEFAULT_LABEL',
      label: "Batch Attachment Upload - Delete Files"
    },
    hoverDescription: {
      translationKey: 'BATCH_ATTACHMENTS_DELETE_FILES_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Batch Attachment Upload - Delete Files'
    }
  },
  [GroupPermission.DOWNLOAD_SUBJECT_MEDIA]: {
    labelTranslation: {
      translationKey: 'DOWNLOAD_SUBJECT_MEDIA_DEFAULT_LABEL',
      label: "Dwnload patient media"
    },
    hoverDescription: {
      translationKey: 'DOWNLOAD_SUBJECT_MEDIA_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Download patient media - Allows user to download patient medie, e.g. videos..'
    }
  },
  [GroupPermission.ALLOW_SUBJECT_MEDIA_AUDIO]: {
    labelTranslation: {
      translationKey: 'ALLOW_SUBJECT_MEDIA_AUDIO_DEFAULT_LABEL',
      label: "Play audio for patient media"
    },
    hoverDescription: {
      translationKey: 'ALLOW_SUBJECT_MEDIA_AUDIO_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Play audio for patient media - Allows user to hear audio on participant videos (audio is turned off by default)'
    }
  },
  [GroupPermission.EDIT_SUBJECT_QUESTIONNAIRES]: {
    labelTranslation: {
      translationKey: 'EDIT_SUBJECT_QUESTIONNAIRES_DEFAULT_LABEL',
      label: "Edit patient questionnaires"
    },
    hoverDescription: {
      translationKey: 'EDIT_SUBJECT_QUESTIONNAIRES_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Edit patient questionnaires - Allows the edit of questionnaire answers on dashboard'
    }
  },
  [GroupPermission.MANUAL_OVERRIDE_ATTACHMENT_WORKFLOW_STATUS]: {
    labelTranslation: {
      translationKey: 'MANUAL_OVERRIDE_ATTACHMENT_WORKFLOW_STATUS_DEFAULT_LABEL',
      label: "Override Attachment Workflow Status"
    },
    hoverDescription: {
      translationKey: 'MANUAL_OVERRIDE_ATTACHMENT_WORKFLOW_STATUSS_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Override Attachment Workflow Status - Allows user to edit all attachment workflow statuses'
    }
  },
  [GroupPermission.MANAGE_SUBJECT_VISITDATES]: {
    labelTranslation: {
      translationKey: 'MANAGE_SUBJECT_VISITDATES_DEFAULT_LABEL',
      label: "Manage participant visit dates"
    },
    hoverDescription: {
      translationKey: 'MANAGE_SUBJECT_VISITDATES_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Manage participant visit dates - Allows user to manage participant visit dates'
    }
  },
  [GroupPermission.UPDATE_VISITDATE_ATTENDED_STATE]: {
    labelTranslation: {
      translationKey: 'UPDATE_VISITDATE_ATTENDED_STATE_DEFAULT_LABEL',
      label: "Change participant visit date attended state"
    },
    hoverDescription: {
      translationKey: 'UPDATE_VISITDATE_ATTENDED_STATE_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Change participant visit date attended state - Allows user to change participant visit date attended state'
    }
  },
  [GroupPermission.MANAGE_TRIAL_VISITDATES]: {
    labelTranslation: {
      translationKey: 'MANAGE_TRIAL_VISITDATES_DEFAULT_LABEL',
      label: "Manage trial level visit dates"
    },
    hoverDescription: {
      translationKey: 'MANAGE_TRIAL_VISITDATES_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Manage trial level visit dates - Allows the user to manage trial level visit dates'
    }
  },
  [GroupPermission.MANAGE_SITE_VISITDATES]: {
    labelTranslation: {
      translationKey: 'MANAGE_SITE_VISITDATES_DEFAULT_LABEL',
      label: "Manage site level visit dates"
    },
    hoverDescription: {
      translationKey: 'MANAGE_SITE_VISITDATES_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Manage site level visit dates - Allows user to manage site level visit dates'
    }
  },
  [GroupPermission.VIEW_SUBJECT_VISITDATES]: {
    labelTranslation: {
      translationKey: 'VIEW_SUBJECT_VISITDATES_DEFAULT_LABEL',
      label: "View participant visit dates"
    },
    hoverDescription: {
      translationKey: 'VIEW_SUBJECT_VISITDATES_DEFAULT_DESCRIPTION_LABEL',
      hover: 'View participant visit dates - Allows user to view participant visit dates'
    }
  },
};

export default GroupPermission;
