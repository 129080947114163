import React, { useState, useContext, useEffect } from "react";
import { withTranslation } from "react-i18next";
import AuthService from "../../services/AuthService";
import { Dropdown } from "semantic-ui-react";
import { Link } from "react-router-dom";
import PasswordChangeModal from "../../pages/profile/PasswordChangeModal";
import UserContext from "../../context/UserContext";
import SubjectService from "../../SubjectService";
import StaffService from "../../StaffService";
import {typeHelper} from "atom5-branching-questionnaire";


const DropDownMenu = (props) => {
    const { t, outstandingMandatoryQuestionnaires, switchAccountSubjectId, switchAccountStaffId } = props;

    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [canChangePassword, setCanChangePassword] = useState(false);

    const userCon = useContext(UserContext);
    const isSuperAdmin = userCon?.profile?.superAdmin;

    const accountType = AuthService.getAccountType();
    const isStaff = accountType === "staff";

    const showPassword = () => {
        setShowPasswordModal(true);
    };

    const closePassword = () => {
        setShowPasswordModal(false);
    };

    const checkIfAllowedToChangePassword = () => {
        SubjectService.canChangePassword()
            .then((data) => {
                setCanChangePassword(data)
            });
    };

    const [staffToSubjectLinkingEnabled, setStaffToSubjectLinkingEnabled] = useState(true);

    const initialise = async () => {
        const staffToSubjectLinking = await StaffService.isStaffToSubjectLinkingEnabled();
        setStaffToSubjectLinkingEnabled(typeHelper.parseBool(staffToSubjectLinking.enabled));
    }

    useEffect(() => {
        checkIfAllowedToChangePassword();
        initialise().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSwitchAccounts = async () => {
        try {
            if (switchAccountSubjectId !== undefined) {
                await AuthService.switchToSubjectAccount(switchAccountSubjectId);
            } else if (switchAccountStaffId !== undefined) {
                await AuthService.switchToStaffAccount(switchAccountStaffId);
            }
        } catch(err) {
            //if there is an error then log to the console and redirect to the homepage
            console.log("Error: " + err);
            window.location.href = window.location.origin;
        }
    }
    return (
        <>
            <Dropdown item text={t(
                'MENU_DROP_LABEL',
                'My Account'
            )}>
                <Dropdown.Menu className='left'>
                    <Dropdown.Header content='Account' />
                    <Dropdown.Item
                        className="atom5-video-controlstop"
                        as={Link}
                        to="/app/profile"
                    >
                        {t("MENU_PROFILE")}
                    </Dropdown.Item>
                    {isStaff && (
                        <Dropdown.Item as={Link} to={"/app/permissions"} fitted={"vertically"}>
                            {t(
                                'MENU_DROP_MYPER',
                                'My Roles & Permissions'
                            )}
                        </Dropdown.Item>
                    )}
                    {canChangePassword && (
                        <Dropdown.Item onClick={showPassword}>
                            {t(
                                'MENU_DROP_PASSWORD',
                                'Change Password'
                            )}
                        </Dropdown.Item>
                    )}
                    {isSuperAdmin && (
                        <>
                            <Dropdown.Divider />
                            <Dropdown.Header content={t(
                                'MENU_DROP_ADMIN_TITLE',
                                'Admin'
                            )} />
                            <Dropdown.Item as={Link} to={"/app/utils"} fitted={"vertically"}>
                                {t(
                                    'MENU_DROP_ADMIN',
                                    'Admin Utilities'
                                )}
                            </Dropdown.Item>
                            <Dropdown scrolling item text={t(
                                'MENU_DROP_MORE_TITLE',
                                'More'
                            )}>
                                <Dropdown.Menu style={{ maxHeight: "38rem" }}>
                                    <Dropdown.Header content={t(
                                        'MENU_DROP_ADMIN_UTILS_TITLE',
                                        'Admin Utils'
                                    )} />
                                    <Dropdown.Item as={Link} to={"/app/utils/translations"} fitted={"vertically"}>
                                        {t(
                                            'MENU_DROP_TRANSLATIONS',
                                            'Admin Translations'
                                        )}
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to={"/app/utils/attachment-blurring"} fitted={"vertically"}>
                                        {t(
                                            'MENU_DROP_BLURRING',
                                            'Attachment Blurring'
                                        )}
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to={"/app/utils/attachment-monitoring"} fitted={"vertically"}>
                                        {t(
                                            'MENU_DROP_ATTACHMENTS',
                                            'Attachment Monitoring'
                                        )}
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to={"/app/utils/attachment-randomisation"} fitted={"vertically"}>
                                        {t(
                                            'MENU_DROP_RANDOMISATION',
                                            'Attachment Randomisation'
                                        )}
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to={"/app/utils/cache-management"} fitted={"vertically"}>
                                        {t(
                                            'MENU_DROP_CACHE',
                                            'Cache Management'
                                        )}
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to={"/app/utils/config-tool"} fitted={"vertically"}>
                                        {t(
                                            'MENU_DROP_CONFIG',
                                            'Config Tool'
                                        )}
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to={"/app/utils/digest-alerts"} fitted={"vertically"}>
                                        {t(
                                            'MENU_DROP_DIGEST_ALERTS',
                                            'Digest Email Alerts'
                                        )}
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to={"/app/utils/column-overrides"} fitted={"vertically"}>
                                        {t(
                                            'MENU_DROP_COLUMN',
                                            'Export Configuration'
                                        )}
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to={"/app/utils/lookup"} fitted={"vertically"}>
                                        {t(
                                            'MENU_DROP_LOOKUP',
                                            'Lookup Tool'
                                        )}
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to={"/app/utils/modules"} fitted={"vertically"}>
                                        {t(
                                            'MENU_DROP_MODULES',
                                            'Modules'
                                        )}
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to={"/app/utils/notification-auditactivity"} fitted={"vertically"}>
                                        {t(
                                            'MENU_DROP_NOTIFICATION',
                                            'Notification Audit Activity'
                                        )}
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to={"/app/utils/questionnaire-definitions"} fitted={"vertically"}>
                                        {t(
                                            'MENU_DROP_QUESTIONNAIREDEFS',
                                            'Questionnaire Definitions'
                                        )}
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to={"/app/utils/questionnaire-workflow"} fitted={"vertically"}>
                                        {t(
                                            'MENU_DROP_QUESTIONNAIRE_WORKFLOW',
                                            'Questionnaire Workflow Admin'
                                        )}
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to={"/app/utils/recalculate"} fitted={"vertically"}>
                                        {t(
                                            'MENU_DROP_RECALCULATE_ANSWERS',
                                            'Recalculate Answers'
                                        )}
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to={"/app/utils/remotejob-processing-queues"} fitted={"vertically"}>
                                        {t(
                                            'MENU_DROP_REMOTE_PROCESSING_QUEUES',
                                            'Remote Job Processing Queues'
                                        )}
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to={"/app/utils/tech-support"} fitted={"vertically"}>
                                        {t(
                                            'MENU_DROP_SUPPORT',
                                            'Tech Support'
                                        )}
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to={"/app/utils/triggers"} fitted={"vertically"}>
                                        {t(
                                            'MENU_DROP_TRIGGERS',
                                            'Triggers'
                                        )}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </>
                    )}
                    <Dropdown.Divider />
                    {(switchAccountSubjectId != null || switchAccountStaffId != null)
                        && outstandingMandatoryQuestionnaires.length === 0
                        && staffToSubjectLinkingEnabled && (
                            <Dropdown.Item
                                onClick={handleSwitchAccounts}
                            >
                                {t("STAFF_TO_SUBJECT_SWITCH", "Switch user")}
                            </Dropdown.Item>)
                    }
                    <Dropdown.Item
                        className="atom5-video-controlstop"
                        onClick={() => {
                            AuthService.logout();
                        }}
                    >
                        {t("GLOBAL_BUTTON_SIGN_OUT")}
                    </Dropdown.Item>

                </Dropdown.Menu>
            </Dropdown>

            <PasswordChangeModal show={showPasswordModal}
                                 onClose={closePassword}
                                 closeIcon />
        </>
    );
};

export default withTranslation()(DropDownMenu);
