import React, {
  useCallback, useEffect,
  useMemo,
  useState,
} from "react";
import {useHistory} from 'react-router-dom';
import Page from "../../components/page/Page";
import {useTranslation} from "react-i18next";
import QuestionnaireDefinitionService from "../../services/admin/QuestionnaireDefinitionService";
import "./AdminQuestionnaireDefinitionCreatePage.css";
import {
  Card,
  Grid,
  Loader, Modal,
} from "semantic-ui-react";
import AccordianReveal from "../../components/generic/AccordianReveal";
import QuestionnaireEditorCardComponent from "../../components/admin/questionnaireEditor/QuestionnaireEditorCardComponent";
import LanguageSelectionGeneric from "../../components/languageselection/LanguageSelectionGeneric";
import QuestionnaireTranslationCardComponent from "../../components/admin/questionnaireEditor/QuestionnaireTranslationCardComponent";
import QuestionEditorCardComponent from "../../components/admin/questionnaireEditor/QuestionEditorCardComponent";
import SecondaryButton from "../../components/generic/SecondaryButton";
import NewQuestionCardComponent from "../../components/admin/questionnaireEditor/NewQuestionCardComponent";
import InternationalisationService from "../../InternationalisationService";
import ConfirmButtonWithFeedback from "../../components/dashboard/ConfirmButtonWithFeedback";
import useCompleteQuestionnaireDefinitionInformation from "../../hooks/useCompleteQuestionnaireDefinitionInformation";
import ResponseErrorPanel from "../../components/errors/ResponseErrorPanel";


function AdminQuestionnaireDefinitionFullEditPage() {
  const {t} = useTranslation();
  const history = useHistory()

  const [hasLoaded, state, dispatch] = useCompleteQuestionnaireDefinitionInformation();

  const [isNewQuestionModalOpen, setIsNewQuestionModalOpen] = useState(false);
  const toggleModal = () => {
    setIsNewQuestionModalOpen(!isNewQuestionModalOpen)
  }

  // response error checking and validation for redirect on success
  const [errorObject, setErrorObject] = useState(null);
  const doesErrorObjectHaveErrors = useMemo(()=>{
    if(errorObject === null) return null;
    return [
        ...Object.keys(errorObject?.errors || {}),
    ].length > 0;
  }, [errorObject]);
  useEffect(()=>{
    if(doesErrorObjectHaveErrors === null) return;
    if(doesErrorObjectHaveErrors === true) return;
    history.goBack();
  },[doesErrorObjectHaveErrors, errorObject, history])

  // submit edited information
  const handleConfirm = useCallback(async (reason) => {
    await setErrorObject(null);

    // Only send back changed translations
    const questionnaireAndTranslationObject = {
      questionnaireDefinition: state.questionnaireDefinition,
      translationArray: state.changedTranslationArray
    }
    try {
      setErrorObject(await QuestionnaireDefinitionService.submitQuestionnaireDefinitionAndTranslationChanges(questionnaireAndTranslationObject, reason));
    } catch (e) {
      setErrorObject(e)
    }
  }, [state.changedTranslationArray, state.questionnaireDefinition]);

  // language selection
  const [selectedLanguage, setSelectedLanguage] = useState(InternationalisationService.getLanguage());
  const setSelectedLanguageCallback = useCallback((value) => {
    setSelectedLanguage(value);
  }, []);

  // display values should update based on changes to translations
  const questionnaireTitleObject = useMemo(
    () =>
      state?.translationArray?.find(
        (sT) =>
          sT.code ===
          `questionnaire_${state?.questionnaireDefinition?.code}_label`
      ),
    [state.questionnaireDefinition, state.translationArray]
  );

  const {
    questionnaireDefinition,
    translationArray,
    changedTranslationArray,
    focusedQuestions
  } = state;

  if (!hasLoaded) return <Loader active={true} />;

  return (
    <Page
      name="Edit Full QuestionnaireDefinition"
      header={t(
        "ADMIN_QUESTIONNAIREDEFINITION_FULLEDIT_HEADER",
        "Questionnaire Definition Full Editor"
      )}
    >
      <Grid>
        <Grid.Column width={8}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <h4>{`${questionnaireDefinition.type} - ${questionnaireDefinition.code}`}</h4>
            <ConfirmButtonWithFeedback
                buttonText={t("GLOBAL_SAVE_BUTTON", "Save")}
                headerText={t("ADMIN_QUESTIONNAIRE_DEFINITION_EDIT_REASON_PLACEHOLDER_TEXT", "Submit Questionnaire and Translation Changes")}
                contentText={t(
                    "ADMIN_QUESTIONNAIRE_DEFINITION_EDIT_REASON_PROMPT",
                    "Please give a reason why this is being changed and confirm."
                )}
                confirmButtonText={t("GLOBAL_BUTTON_CONFIRM", "Confirm")}
                cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
                onConfirm={handleConfirm}
                placeholderText={t(
                    "ADMIN_QUESTIONNAIRE_DEFINITION_EDIT_REASON_PLACEHOLDER_TEXT",
                    "Reason"
                )}
                mandatoryValidationText={t(
                    "ADMIN_QUESTIONNAIRE_DEFINITION_EDIT_REASON_VALIDATION_TEXT",
                    "Please supply a reason for the change."
                )}
                color={"primary"}
            />
          </div>
          <ResponseErrorPanel
              errorResponse={errorObject}
              showErrors={doesErrorObjectHaveErrors}
              title={t("ADMIN_QUESTIONNAIREDEFINITION_VALIDATIONFAILED", "Validation failed, please expand to show errors")}
          />
          <h2>{questionnaireTitleObject.translation}</h2>
          <Card fluid>
            <AccordianReveal displayText={t("QUESTIONNAIRE_EDIT_SHOW", "Show Questionnaire Information")} >
              <QuestionnaireEditorCardComponent
                errorResponse={errorObject}
                dispatch={dispatch}
                questionnaireDefinition={questionnaireDefinition}
              />
            </AccordianReveal>
          </Card>
          <div style={{display: "flex", margin: "2rem 0 0 0", alignItems: "baseline", justifyContent: "space-between"}}>
            <h3>{t("ADMIN_QUESTIONNAIREDEFINITION_Questions", "Questions")}</h3>
            <SecondaryButton onClick={toggleModal}>{t("QUESTIONNAIRE_EDIT_NEW_QUESTION", "Add New Question")}</SecondaryButton>
          </div>
          {questionnaireDefinition.questions.map((q, i)=><QuestionEditorCardComponent
              key={`questionEditor-${q.code}`}
              dispatch={dispatch}
              questionDefinition={q}
              index={i}
              translations={translationArray}
              changedTranslations={changedTranslationArray}
              questionnaireDefinitionCode={questionnaireDefinition.code}
              workflowDefinitionId={questionnaireDefinition?.questionnaireWorkflowDefinitionId}
              isFocused={state?.focusedQuestions?.includes(q.code)}
          />)}
        </Grid.Column>
        <Grid.Column width={8} >
          <div style={{ display: "flex" }}>
            <LanguageSelectionGeneric
              inline
              language={selectedLanguage}
              callback={setSelectedLanguageCallback}
            />
          </div>
            <QuestionnaireTranslationCardComponent
              selectedLanguage={selectedLanguage}
              changedTranslationArray={changedTranslationArray}
              questionnaireDefinition={questionnaireDefinition}
              focusedQuestions={focusedQuestions}
              dispatch={dispatch}
              setSelectedLanguageCallback={setSelectedLanguageCallback}
            />
        </Grid.Column>
      </Grid>
      <Modal open={isNewQuestionModalOpen} onClose={toggleModal}>
        <NewQuestionCardComponent
            dispatch={dispatch}
            questionDefinition={null}
            translations={translationArray}
            questionnaireDefinition={questionnaireDefinition}
            isFocused={false}
            toggleOpen={toggleModal}
        />
      </Modal>
    </Page>
  );
}

export default AdminQuestionnaireDefinitionFullEditPage;
