import React from 'react';
import {Button, Card, Form, Grid, Message, MessageHeader} from "semantic-ui-react";
import {withTranslation} from "react-i18next";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import AccordianReveal from "../../generic/AccordianReveal";
import EntryDisplay from "./EntryDisplay";


const RegionSelectorControls = ({t, entries, fps, setFPS, handleDownload, handleNextFrame, handleLog, handlePrevFrame, handleRemove, calculatedFPSData, currentFrame, error}) => {

  return <div>
    {/*The default video controls are moved down, so spacer is needed*/}
    <div className={"spacer"} style={{height: '75px'}} />
    <Card fluid>
      <Card.Content>
        <Form>
          {error != null && <Message negative>
            <MessageHeader>{t("GLOBAL_ERROR_TITLE","Error!")}</MessageHeader>
            <p>{error.message}</p>
          </Message>}
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Input
                  disabled
                  style={{opacity: 0.6}}
                  size={"small"}
                  label={t("REGION_SELECTOR_CALCULATED_FPS", "Calculated FPS")}
                  placeholder={t("REGION_SELECTOR_CALCULATED_FPD_PLACEHOLDER", "Play video to calculate")}
                  value={calculatedFPSData.fps}
              />
            </Grid.Column>
            <Grid.Column>
              <Input
                  disabled
                  style={{opacity: 0.6}}
                  size={"small"}
                  label={t("REGION_SELECTOR_CURRENT_TIME", "Current time")}
                  placeholder={t("REGION_SELECTOR_CURRENT_TIME_PLACEHOLDER", "Play video to find")}
                  value={calculatedFPSData.currentTime}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Input
                  primary
                  size={"small"}
                  label={t("REGION_SELECTOR_FPS","FPS")}
                  type={'numeric'}
                  placeholder={t("REGION_SELECTOR_FPS_PLACEHOLDER", "Select FPS")}
                  onChange={e=> {
                    setFPS(parseInt(e.target.value))
                  }}
                  value={fps}
              />
            </Grid.Column>
            <Grid.Column>
              <Input
                  disabled
                  style={{opacity: 0.6}}
                  size={"small"}
                  label={t("REGION_SELECTOR_CURRENT_FRAME","Current Frame")}
                  placeholder={t("REGION_SELECTOR_CURRENT_FRAME_PLACEHOLDER", "Set FPS to calculate")}
                  value={currentFrame}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={4}>
            <Grid.Column>
              <Button fluid onClick={handlePrevFrame} primary={fps} disabled={!fps}>
                <p>{t("REGION_SELECTOR_PREV", "Previous (Q)")}</p>
              </Button>
            </Grid.Column>
            <Grid.Column>
              <Button fluid onClick={handleNextFrame} primary={fps} disabled={!fps}>
                <p>{t("REGION_SELECTOR_NEXT", "Next (W)")}</p>
              </Button>
            </Grid.Column>
            <Grid.Column>
              <Button fluid onClick={handleLog}  primary={fps} disabled={!fps}>
                <p>{t("REGION_SELECTOR_LOG", "Log (E)")}</p>
              </Button>
            </Grid.Column>
            <Grid.Column>
              <Button fluid onClick={handleDownload}  primary={fps} disabled={!fps}>
                <p>{t("REGION_SELECTOR_DOWNLOAD", "Download")}</p>
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <AccordianReveal displayText={"More"}>
              <Grid padded >
                <Grid.Row><p>{t("REGION_SELECTOR_INSTRUCTIONS", "Box Region can be moved with I, J, K, and L, and resized with U and O.")}</p></Grid.Row>
                <Grid.Row columns={12}>
                  <Grid.Column width={4}>
                    <p><strong>{t("REGION_SELECTOR_ENTRY_FRAME", "Frame")}</strong></p>
                  </Grid.Column>
                  <Grid.Column >
                    <p><strong>{t("REGION_SELECTOR_ENTRY_FRAME", "x")}</strong></p>

                  </Grid.Column>
                  <Grid.Column width={3} >
                    <p><strong>{t("REGION_SELECTOR_ENTRY_FRAME", "y")}</strong></p>

                  </Grid.Column>
                  <Grid.Column >
                    <p><strong>{t("REGION_SELECTOR_ENTRY_FRAME", "x'")}</strong></p>

                  </Grid.Column>
                  <Grid.Column  width={3} >
                    <p><strong>{t("REGION_SELECTOR_ENTRY_FRAME", "y'")}</strong></p>

                  </Grid.Column>
                  <Grid.Column >
                  </Grid.Column>
                </Grid.Row>
                {entries.map(e=><EntryDisplay key={'entry-display-'+e.id} entry={e} handleRemove={handleRemove} />)}
              </Grid>
            </AccordianReveal>
          </Grid.Row>
        </Grid>
        </Form>
      </Card.Content>
    </Card>
  </div>
}

export default withTranslation()(RegionSelectorControls);
